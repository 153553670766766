import { useEffect, useContext, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Panels, Panel } from '../../components/panel/panel';
import {
  doc,
  collection,
  getDocs,
  setDoc,
  where,
  query,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from '../../firebase-config';
import { UserInfoContext, EquipmentContext } from '../../context';
import PageTop from '../../components/page-top/page-top';
import Button from '../../components/button/button';
import Icon from '../../components/icon/icon';
import { useForm } from '../../hooks/useForm';
import Modal from '../../components/modal/modal';
import EmojiPicker from '../../components/emoji-picker/emoji-picker';
import './equipment-type-view.css';
import InputField from '../../components/input-field/input-field';
import List from '../../components/list/list';
import 'react-loading-skeleton/dist/skeleton.css';

export default function EquipmentTypeView() {
  const userInfo = useContext(UserInfoContext);
  const { equipmentTypeId } = useParams();
  const [formFeedback, setFormFeedback] = useState('');
  const { equipmentTypes, equipmentVariants } = useContext(EquipmentContext);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateVariantModalOpen, setIsCreateVariantModalOpen] =
    useState(false);
  const [isEditVariantModalOpen, setIsEditVariantModalOpen] = useState(false);
  const [selectedVariantForEdit, setSelectedVariantForEdit] = useState('');
  const [editVariantValue, setEditVariantValue] = useState('');
  const [isDeleteVariantModalOpen, setIsDeleteVariantModalOpen] =
    useState(false);
  const [selectedVariantForDeletion, setSelectedVariantForDeletion] =
    useState('');
  const [equipmentVariantsSelectDisable, setEquipmentVariantsSelectDisable] =
    useState(true);
  const [newVariantValue, setNewVariantValue] = useState('');
  const navigate = useNavigate();

  // Handle input for creating a new variant
  const handleCreateVariantValue = (e) => {
    setNewVariantValue(e.target.value);

    if (e.target.value) {
      setEquipmentVariantsSelectDisable(false);
    }
  };

  // Handler to open the edit variant modal
  const handleEditVariantClick = (equipmentVariantId) => {
    setSelectedVariantForEdit(equipmentVariantId);
    setEditVariantValue(
      equipmentVariants.find((v) => v.id === equipmentVariantId)?.data?.name
    );
    setIsEditVariantModalOpen(true);
  };

  // Handler to confirm edit of variant
  const confirmEditVariant = async () => {
    if (selectedVariantForEdit) {
      const equipmentVariantDocRef = doc(
        db,
        'equipmentVariants',
        selectedVariantForEdit
      );
      try {
        // Update the equipment type document in Firestore
        await setDoc(
          equipmentVariantDocRef,
          { name: editVariantValue },
          { merge: true }
        );

        // Reset input after creation
        setEditVariantValue('');

        // Reset selected variant after edit
        setSelectedVariantForEdit(null);

        // Close edit modal
        setIsEditVariantModalOpen(false);
      } catch (error) {
        // Handle any errors
        console.error('Error updating equipment variant:', error);
      }
    }
  };

  // Handler to open the delete variant modal
  const handleDeleteVariantClick = (equipmentVariantId) => {
    setSelectedVariantForDeletion(equipmentVariantId);
    setIsDeleteVariantModalOpen(true);
  };

  // Handler to confirm deletion of variant
  const confirmDeleteVariant = async () => {
    if (selectedVariantForDeletion) {
      await handleDeleteVariant();
      setIsDeleteVariantModalOpen(false);
      setSelectedVariantForDeletion(null); // Reset the selected variant after deletion
    }
  };

  // Handler to delete variant
  const handleDeleteVariant = async () => {
    if (!selectedVariantForDeletion) return;

    const equipmentVariantDocRef = doc(
      db,
      'equipmentVariants',
      selectedVariantForDeletion
    );
    try {
      // First, delete all equipment that is using this variant
      const equipmentUsingVariantRef = collection(db, 'equipment');
      const q = query(
        equipmentUsingVariantRef,
        where('companyId', '==', userInfo.companyId),
        where('equipmentVariant', '==', equipmentVariantDocRef)
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.docs.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });

      // Then, delete the variant
      await deleteDoc(equipmentVariantDocRef);
    } catch (error) {
      console.error('Error deleting equipment variant:', error);
    }
  };

  // Form validation
  const validateEquipmentTypeForm = (values) => {
    let errors = {};

    if (!values.name) errors.name = 'Navn mangler';

    return errors;
  };

  // Handle opening and closing the delete modal
  function toggleDeleteModal() {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }

  // Handle deleting the equipment type
  async function handleDeleteEquipmentType() {
    const equipmentTypeDocRef = doc(db, 'equipmentTypes', equipmentTypeId);
    try {
      // First, delete all equipment that is using this type
      const equipmentUsingTypeRef = collection(db, 'equipment');
      const qEquipment = query(
        equipmentUsingTypeRef,
        where('companyId', '==', userInfo.companyId),
        where('equipmentType', '==', equipmentTypeDocRef)
      );

      const queryEquipmentSnapshot = await getDocs(qEquipment);
      queryEquipmentSnapshot.docs.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });

      // Second, delete all variants that is using this type
      const equipmentVariantsUsingTypeRef = collection(db, 'equipmentVariants');
      const qVariants = query(
        equipmentVariantsUsingTypeRef,
        where('companyId', '==', userInfo.companyId),
        where('equipmentType', '==', equipmentTypeDocRef)
      );

      const queryVariantsSnapshot = await getDocs(qVariants);
      queryVariantsSnapshot.docs.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });

      // Third, delete the equipment type
      await deleteDoc(equipmentTypeDocRef);
      navigate('/udstyr');
    } catch (error) {
      console.error('Error deleting equipment type:', error);
    }
  }

  // Handle variant creation
  const handleCreateOptionForVariant = async () => {
    if (!newVariantValue) return;

    const equipmentVariantsRef = collection(db, 'equipmentVariants');
    const equipmentTypeRef = doc(db, 'equipmentTypes', equipmentTypeId);
    const newVariantData = {
      companyId: userInfo.companyId,
      createdBy: doc(db, 'users', userInfo.id),
      equipmentType: equipmentTypeRef,
      name: newVariantValue,
    };

    // Disable input while creating
    setEquipmentVariantsSelectDisable(true);

    try {
      await addDoc(equipmentVariantsRef, newVariantData);

      // Reset input after creation
      setNewVariantValue('');

      // Enable input after creation
      setEquipmentVariantsSelectDisable(false);

      // Close modal after creation
      setIsCreateVariantModalOpen(false);
    } catch (error) {
      console.error('Error adding equipment variant: ', error);

      // Enable input
      setEquipmentVariantsSelectDisable(false);
    }
  };

  // Handle form submission
  const onSubmit = useCallback(
    async (values) => {
      const equipmentTypeDocRef = doc(db, 'equipmentTypes', equipmentTypeId);
      try {
        // Update the equipment type document in Firestore
        await setDoc(equipmentTypeDocRef, values, { merge: true });
      } catch (error) {
        // Handle any errors
        setFormFeedback('Der skete en fejl. Prøv igen senere.');
        console.error('Error updating equipment type:', error);
      }
    },
    [equipmentTypeId]
  );

  // Custom hook for handling form state
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    {
      icon: '',
      name: '',
    },
    validateEquipmentTypeForm,
    onSubmit
  );

  // Fetch equipment type data from context
  useEffect(() => {
    // If the delete modal is open, return early to prevent fetching data
    if (isDeleteModalOpen) {
      return;
    }

    if (equipmentTypes.length === 0) {
      return;
    }

    const equipmentTypeDataFromContext = equipmentTypes.find(
      (equipmentType) => equipmentType.id === equipmentTypeId
    ).data;

    setValues({
      ...equipmentTypeDataFromContext,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentTypeId, equipmentTypes]);

  return (
    <div className="main-layout">
      <PageTop
        title={
          <div className="equipment-type-title">
            <span className="equipment-type-title__headline">
              <div className="equipment-type-title__icon">{values.icon}</div>
              {values.name}
            </span>
          </div>
        }
      />
      <Panels>
        <Panel header={<h4>Indstillinger</h4>}>
          <div className="equipment-type-settings">
            {values.icon && (
              <EmojiPicker
                value={values.icon}
                onSelect={(value) => {
                  handleChange({
                    target: { name: 'icon', value },
                  });
                }}
              />
            )}

            <InputField
              required
              label="Navn på kategori, f.eks. Computer"
              name="name"
              value={values.name}
              onChange={handleChange}
              placeholder="Navn på kategori, f.eks. Computer"
              autoComplete="off"
              data-1p-ignore // Prevent 1Password from autofilling
              error={errors.name}
            />
          </div>
          <div className="form-actions form-actions--equipment-type-save">
            <Button
              disabled={values.name.trim() === '' || values.icon.trim() === ''}
              onClick={handleSubmit}
              type="submit"
            >
              Gem ændringer
            </Button>
            {formFeedback && (
              <span className="form-actions__feedback">{formFeedback}</span>
            )}
          </div>
        </Panel>
        <Panel noPadding header={<h4>Varianter</h4>}>
          <List
            className="equipment-variants-list"
            maxHeight={360}
            noResultsMessage="Ingen varianter fundet. Har du tilføjet nogen?"
            actions={
              <Button
                className="small"
                buttonStyle="secondary"
                onClick={() => setIsCreateVariantModalOpen(true)}
              >
                Tilføj variant
              </Button>
            }
            items={equipmentVariants
              .filter(
                (variant) => variant.data.equipmentType.id === equipmentTypeId
              )
              .sort((a, b) => a.data.name.localeCompare(b.data.name))
              .map((equipmentVariant) => ({
                id: equipmentVariant.id,
                fields: [
                  {
                    label: 'Variant',
                    value: equipmentVariant?.data?.name,
                    sortBy: equipmentVariant?.data?.name,
                    searchBy: equipmentVariant?.data?.name,
                  },
                  {
                    label: 'Handlinger',
                    value: (
                      <div className="equipment-variants-list-actions">
                        <div
                          className="equipment-variants-list-actions__link"
                          onClick={() =>
                            handleEditVariantClick(equipmentVariant.id)
                          }
                        >
                          <Icon name="edit" />
                          <span>Ret</span>
                        </div>
                        <div
                          className="equipment-variants-list-actions__link"
                          onClick={() =>
                            handleDeleteVariantClick(equipmentVariant.id)
                          }
                        >
                          <Icon name="close" />
                          <span>Slet</span>
                        </div>
                      </div>
                    ),
                  },
                ],
                filterData: {
                  Variant: equipmentVariant?.data?.name,
                },
              }))}
          />
          {isCreateVariantModalOpen && (
            <Modal
              isModalOpen={isCreateVariantModalOpen}
              setIsModalOpen={setIsCreateVariantModalOpen}
              header="Tilføj variant"
              content={
                <div className="create-modal-content">
                  <InputField
                    required
                    label="Navn på variant"
                    name="name"
                    value={newVariantValue}
                    onChange={handleCreateVariantValue}
                    placeholder="Navn på variant"
                    autoComplete="off"
                  />
                </div>
              }
              bottom={
                <>
                  <Button
                    disabled={equipmentVariantsSelectDisable}
                    onClick={handleCreateOptionForVariant}
                  >
                    Tilføj variant
                  </Button>
                </>
              }
            />
          )}
        </Panel>
        <Panel noPadding header={<h4>Administration</h4>}>
          <div className="action-list">
            <div className="action-list-item">
              <div className="action-list-item__description">
                <h5>Slet kategori</h5>
                <p>
                  Når katgorien er slettet, bliver al udstyr som er tilknyttet
                  denne kategori også slettet. Det kan ikke laves om. Vær sikker
                  i din beslutning.
                </p>
              </div>
              <div className="action-list-item__action">
                <Button
                  type="button"
                  buttonStyle="danger"
                  onClick={toggleDeleteModal}
                >
                  Slet kategori
                </Button>
                <Modal
                  isModalOpen={isDeleteModalOpen}
                  setIsModalOpen={setIsDeleteModalOpen}
                  header={`Slet kategori`}
                  bottom={
                    <>
                      <Button
                        buttonStyle="danger"
                        onClick={handleDeleteEquipmentType}
                      >
                        Slet kategori
                      </Button>
                    </>
                  }
                  content={
                    <div className="delete-modal-content">
                      <Icon name="warning" />
                      <p>
                        Hvis du sletter {values.icon}{' '}
                        <strong>{values.name}</strong> sletter du også al udstyr
                        som er tilknyttet denne kategori.
                      </p>
                      <p>Er du sikker på at du vil slette kategorien?</p>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </Panel>
      </Panels>
      {isEditVariantModalOpen && (
        <Modal
          isModalOpen={isEditVariantModalOpen}
          setIsModalOpen={setIsEditVariantModalOpen}
          header="Ret variant"
          content={
            <div className="edit-modal-content">
              <InputField
                required
                label="Navn på variant"
                name="name"
                value={editVariantValue}
                onChange={(e) => setEditVariantValue(e.target.value)}
                placeholder="Navn på variant"
                error={editVariantValue ? '' : 'Navn mangler'}
                autoComplete="off"
              />
            </div>
          }
          bottom={
            <>
              <Button
                disabled={editVariantValue.trim() === ''}
                onClick={confirmEditVariant}
              >
                Ret detaljer
              </Button>
            </>
          }
        />
      )}
      {isDeleteVariantModalOpen && (
        <Modal
          isModalOpen={isDeleteVariantModalOpen}
          setIsModalOpen={setIsDeleteVariantModalOpen}
          header="Slet variant"
          content={
            <div className="delete-modal-content">
              <Icon name="warning" />
              <p>
                Er du sikker på at du vil slette varianten{' '}
                <strong>
                  {
                    equipmentVariants.find(
                      (v) => v.id === selectedVariantForDeletion
                    )?.data?.name
                  }
                </strong>
                ?
              </p>
              <p>
                Sletter du varianten, slettes også alt udstyr som er tilknyttet
                denne variant.
              </p>
            </div>
          }
          bottom={
            <>
              <Button buttonStyle="danger" onClick={confirmDeleteVariant}>
                Slet variant
              </Button>
            </>
          }
        />
      )}
    </div>
  );
}
