import { useEffect, useContext, useState, useCallback, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Panels, Panel } from '../../components/panel/panel';
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  Timestamp,
  arrayUnion,
  arrayRemove,
  collection,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import Avatar from '../../components/avatar/avatar';
import { db, storage } from '../../firebase-config';
import {
  UserInfoContext,
  EmployeesContext,
  EquipmentContext,
  SubscriptionContext,
} from '../../context';
import PageTop from '../../components/page-top/page-top';
import Button from '../../components/button/button';
import Icon from '../../components/icon/icon';
import { useForm } from '../../hooks/useForm';
import Modal from '../../components/modal/modal';
import './equipment-detail-view.css';
import InputField from '../../components/input-field/input-field';
import FileUpload from '../../components/file-upload/file-upload';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const uploadFiles = async (files, companyId, equipmentId, onUploadComplete) => {
  // Check for early return conditions
  if (!files || !companyId || !equipmentId) return false;

  // Normalize files to an array (handles both single file and FileList objects)
  const filesArray = files instanceof File ? [files] : Array.from(files);

  if (filesArray.length === 0) return false;

  const fileDataArray = await Promise.all(
    filesArray.map(async (file) => {
      const filePath = `${companyId}/equipment/${equipmentId}/${file.name}`;
      const fileRef = ref(storage, filePath);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);

      const fileData = {
        date: Timestamp.fromDate(new Date()),
        extension: file.name.split('.').pop(),
        filePath: fileUrl,
        name: file.name,
      };

      const equipmentDoc = doc(db, 'equipment', equipmentId);
      await updateDoc(equipmentDoc, {
        files: arrayUnion(fileData),
      });

      return fileData;
    })
  );

  if (onUploadComplete && typeof onUploadComplete === 'function') {
    onUploadComplete(fileDataArray);
  }
  return true;
};

export default function EquipmentDetailView() {
  const { subscription } = useContext(SubscriptionContext);
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);
  const isInitialLoad = useRef(true);
  const { equipmentId } = useParams();
  const [formFeedback, setFormFeedback] = useState('');
  const [attachments, setAttachments] = useState([]);
  const { equipmentTypes, equipmentVariants } = useContext(EquipmentContext);
  const [equipmentDetailData, setEquipmentDetailData] = useState({});
  const [notesSaveButtonEnabled, setNotesSaveButtonEnabled] = useState(false);
  const [notesSaveFeedback, setNotesSaveFeedback] = useState('');
  const [assignedTo, setAssignedTo] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [equipmentVariantsSelectDisable, setEquipmentVariantsSelectDisable] =
    useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const navigate = useNavigate();

  // Form validation
  const validateEquipmentForm = (values) => {
    let errors = {};

    if (!values.equipmentVariant) errors.equipmentVariant = 'Variant mangler';

    return errors;
  };

  const deleteFile = async (file) => {
    const fileRef = ref(storage, file.filePath);
    await deleteObject(fileRef);

    // Remove the file from the equipment
    const equipmentDoc = doc(db, 'equipment', equipmentId);
    await updateDoc(equipmentDoc, {
      files: arrayRemove(file),
    });

    // Remove the file from the state
    setAttachments((prevAttachments) =>
      prevAttachments.filter(
        (attachment) => attachment.filePath !== file.filePath
      )
    );

    return true;
  };

  const handleFilesUploaded = useCallback((newFiles) => {
    setAttachments((prevAttachments) => [...prevAttachments, ...newFiles]);
  }, []);

  const handleNotesChange = (e) => {
    const { name, value } = e.target;
    setEquipmentDetailData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // If the notes are different, enable the save button
    if (value !== equipmentDetailData.notes) {
      setNotesSaveButtonEnabled(true);
      setNotesSaveFeedback('');
    } else {
      setNotesSaveButtonEnabled(false);
    }
  };

  const handleNotesSave = async () => {
    const equipmentDoc = doc(db, 'equipment', equipmentId);
    await updateDoc(equipmentDoc, {
      notes: equipmentDetailData.notes,
    });

    setNotesSaveFeedback('Noterne er gemt');
    setNotesSaveButtonEnabled(false);
  };

  // Update the inactivity boolean of the equipment
  const toggleEquipmentStatus = async () => {
    // Check if we have equipment data and a document ID
    const equipmentDocRef = doc(db, 'equipment', equipmentId);
    try {
      // Update the document in Firestore
      await setDoc(
        equipmentDocRef,
        {
          inactive: isInactive ? false : true,
        },
        { merge: true }
      );

      setIsInactive(!isInactive);
    } catch (error) {
      // Handle any errors
      setFormFeedback('Der skete en fejl. Prøv igen senere.');
      console.error('Error updating equipment:', error);
    }
  };

  const handleEditModalToggle = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  // Handle opening and closing the delete modal
  function toggleDeleteModal() {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }

  // Handle deleting the equipment
  async function handleDeleteEquipment() {
    const equipmentDocRef = doc(db, 'equipment', equipmentId);
    try {
      await deleteDoc(equipmentDocRef);
      navigate('/udstyr');
    } catch (error) {
      console.error('Error deleting equipment:', error);
    }
  }

  // Handle variant creation
  const handleCreateOptionForVariant = async (inputValue) => {
    const equipmentVariantsRef = collection(db, 'equipmentVariants');
    const equipmentTypeRef = doc(db, 'equipmentTypes', values.equipmentType);
    const newVariantData = {
      companyId: userInfo.companyId,
      createdBy: doc(db, 'users', userInfo.id),
      equipmentType: equipmentTypeRef,
      name: inputValue,
    };

    // Disable input while creating
    setEquipmentVariantsSelectDisable(true);

    try {
      const docRef = await addDoc(equipmentVariantsRef, newVariantData);

      // Set equipmentVariant value to the newly created variant
      setValues({ ...values, equipmentVariant: docRef.id });

      // Enable input after creation
      setEquipmentVariantsSelectDisable(false);
    } catch (error) {
      console.error('Error adding equipment variant: ', error);

      // Enable input
      setEquipmentVariantsSelectDisable(false);
    }
  };

  // Handle form submission
  const onSubmit = useCallback(
    async (values) => {
      // Convert dates to Firestore Timestamps
      const valuesToSave = {
        ...values,
        startDate: values.startDate
          ? Timestamp.fromDate(new Date(values.startDate))
          : null,
        endDate: values.endDate
          ? Timestamp.fromDate(new Date(values.endDate))
          : null,
        assignedTo: values.assignedTo
          ? doc(db, 'users', values.assignedTo)
          : '',
        equipmentType: doc(db, 'equipmentTypes', values.equipmentType),
        equipmentVariant: doc(db, 'equipmentVariants', values.equipmentVariant),
      };

      // Check if we have equipment data and a document ID
      const equipmentDocRef = doc(db, 'equipment', equipmentId);

      try {
        // Update the document in Firestore
        await setDoc(equipmentDocRef, valuesToSave, { merge: true });
        // Handle successful update
        setIsEditModalOpen(false);

        // Update the assignedTo in the state
        const assignedTo = employees.find(
          (emp) => emp.id === values.assignedTo
        );

        if (assignedTo) {
          setAssignedTo({
            id: assignedTo.id,
            data: assignedTo.data,
          });
        } else {
          setAssignedTo({});
        }

        // Update equipmentDetailData in the state
        setEquipmentDetailData((prevData) => ({
          ...prevData,
          ...valuesToSave,
        }));
      } catch (error) {
        // Handle any errors
        setFormFeedback('Der skete en fejl. Prøv igen senere.');
        console.error('Error updating equipment:', error);
      }
    },
    [equipmentId, employees]
  );

  // Custom hook for handling form state
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    {
      assignedTo: '',
      startDate: '',
      endDate: '',
      notes: '',
      serialNumber: '',
      equipmentType: '',
      equipmentVariant: '',
    },
    validateEquipmentForm,
    onSubmit
  );

  const getVariantObjectForValue = (variantId) => {
    const variant = equipmentVariants.find((v) => v.id === variantId);
    return variant ? { value: variant.id, label: variant.data.name } : null;
  };

  // Redirect to equipment list if subcription is not a paid one
  useEffect(() => {
    const paidSubscription =
      subscription?.name === 'premium' || subscription?.name === 'premiumplus';

    if (!paidSubscription) {
      navigate('/udstyr');
    }
  }, [subscription, navigate]);

  // Fetch equipment data
  useEffect(() => {
    const fetchEquipmentData = async () => {
      if (isInitialLoad.current) {
        const equipmentDocRef = doc(db, 'equipment', equipmentId);
        try {
          const docSnap = await getDoc(equipmentDocRef);
          if (docSnap.exists()) {
            let data = docSnap.data();

            // Find the details of the person this is assignedTo from the employees context
            const assignedTo = employees.find(
              (employee) => employee.id === data?.assignedTo?.id
            );
            if (assignedTo) {
              setAssignedTo({
                id: assignedTo.id,
                data: assignedTo.data,
              });
            }
            setEquipmentDetailData(data);

            setValues({
              assignedTo: data.assignedTo ? data.assignedTo.id : '',
              startDate: data.startDate ? data.startDate.toDate() : '',
              endDate: data.endDate ? data.endDate.toDate() : '',
              notes: data.notes || '',
              serialNumber: data.serialNumber,
              equipmentType: data.equipmentType.id,
              equipmentVariant: data.equipmentVariant.id,
            });

            // Set the inactivity status of the equipment
            setIsInactive(data.inactive);

            isInitialLoad.current = false; // Update the ref after setting values
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching company data:', error);

          navigate('/udstyr');
        } finally {
          // Set loading to false
          isInitialLoad.current = false;
        }
      }
    };
    fetchEquipmentData();
  }, [equipmentId, employees, setValues, userInfo.companyId]);

  // Fetch attachments
  useEffect(() => {
    if (equipmentDetailData.files && equipmentDetailData.files.length > 0) {
      setAttachments(equipmentDetailData.files);
    }
  }, [equipmentDetailData.files]);

  return (
    <div className="main-layout">
      <PageTop
        title={
          isInitialLoad.current ? (
            <Skeleton width={700} />
          ) : (
            <div className="equipment-details-title">
              <span className="equipment-details-title__headline">
                <div className="equipment-details-title__icon">
                  {
                    equipmentTypes.find(
                      (type) => type.id === equipmentDetailData.equipmentType.id
                    )?.data.icon
                  }
                </div>
                {
                  equipmentVariants.find(
                    (variant) =>
                      variant.id === equipmentDetailData.equipmentVariant.id
                  )?.data.name
                }
                {isInactive && (
                  <span className="equipment-details-title__status">
                    Inaktiv
                  </span>
                )}
              </span>
              <h4>
                {
                  equipmentTypes.find(
                    (type) => type.id === equipmentDetailData.equipmentType.id
                  )?.data.name
                }
              </h4>
            </div>
          )
        }
      />
      <Panels>
        <Panels columns="65">
          <Panel noPadding>
            <div className="equipment-details">
              {isInitialLoad.current ? (
                <>
                  <Skeleton width={400} count={2} height={23} />
                  <Skeleton width={400} count={1} height={32} />
                  <Skeleton width={400} count={1} height={32} />
                </>
              ) : (
                <>
                  <div className="equipment-details-row">
                    <span className="equipment-details-row__label">
                      Kategori
                    </span>
                    <div className="equipment-details-row__value">
                      {
                        equipmentTypes.find(
                          (type) =>
                            type.id === equipmentDetailData.equipmentType.id
                        )?.data.name
                      }
                    </div>
                  </div>
                  <div className="equipment-details-row">
                    <span className="equipment-details-row__label">
                      Variant
                    </span>
                    <div className="equipment-details-row__value">
                      {
                        equipmentVariants.find(
                          (variant) =>
                            variant.id ===
                            equipmentDetailData.equipmentVariant.id
                        )?.data.name
                      }
                    </div>
                  </div>
                  <div className="equipment-details-row">
                    <span className="equipment-details-row__label">
                      Serienummer
                    </span>
                    <div className="equipment-details-row__value">
                      {values.serialNumber || (
                        <div className="equipment-details-row-status equipment-details-row-status--danger">
                          <Icon name="warning" />
                          Mangler serienummer
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="equipment-details-row">
                    <span className="equipment-details-row__label">
                      Startdato
                    </span>
                    <div className="equipment-details-row__value">
                      {values.startDate
                        ? new Date(values.startDate).toLocaleString('da-DK', {
                            dateStyle: 'short',
                          })
                        : 'Ikke angivet'}
                    </div>
                  </div>
                  <div className="equipment-details-row">
                    <span className="equipment-details-row__label">
                      Slutdato
                    </span>
                    <div className="equipment-details-row__value">
                      {values.endDate ? (
                        !isInactive &&
                        new Date(values.endDate).getTime() <
                          new Date().getTime() ? (
                          <div className="equipment-details-row-status equipment-details-row-status--warning">
                            {new Date(values.endDate).toLocaleDateString(
                              'da-DK',
                              {
                                dateStyle: 'short',
                              }
                            )}
                            <Icon name="warning" />
                            Udløbet
                          </div>
                        ) : !isInactive &&
                          new Date(values.endDate).getTime() <
                            new Date(
                              new Date().getTime() + 30 * 24 * 60 * 60 * 1000
                            ) ? (
                          <div className="equipment-details-row-status equipment-details-row-status--warning">
                            {new Date(values.endDate).toLocaleDateString(
                              'da-DK',
                              {
                                dateStyle: 'short',
                              }
                            )}
                            <Icon name="warning" />
                            Udløber inden for 1 måned
                          </div>
                        ) : (
                          new Date(values.endDate).toLocaleDateString('da-DK', {
                            dateStyle: 'short',
                          })
                        )
                      ) : (
                        'Ikke angivet'
                      )}
                    </div>
                  </div>
                  <div className="equipment-details-row equipment-details-row--assignedTo">
                    <span className="equipment-details-row__label">
                      Tildelt til
                    </span>
                    <div className="equipment-details-row__value">
                      {assignedTo.id === undefined ? (
                        <span className="equipment-details-row-assignedTo-row equipment-details-row-assignedTo-row--unassigned">
                          Ikke tildelt
                        </span>
                      ) : (
                        <Link
                          key={assignedTo.id}
                          className="equipment-details-row-assignedTo-row"
                          to={`/medarbejdere/${assignedTo.id}`}
                        >
                          <Avatar
                            key={assignedTo.id}
                            firstName={assignedTo.data.firstName}
                            lastName={assignedTo.data.lastName}
                            profilePhotoUrl={assignedTo.data.profilePhotoUrl}
                            size="small"
                          />
                          <span>
                            {assignedTo.data.firstName}{' '}
                            {assignedTo.data.lastName}
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="equipment-details-actions">
                    <Button
                      buttonStyle="text-link"
                      className="small"
                      onClick={handleEditModalToggle}
                    >
                      Ret detaljer
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Panel>
          <Panel noPadding>
            <div className="action-list">
              <div className="action-list-item">
                <div className="action-list-item__description">
                  <h5>Slet udstyr</h5>
                  <p>
                    Når udstyret er slettet, kan det ikke laves om. Vær sikker i
                    din beslutning.
                  </p>
                </div>
                <div className="action-list-item__action">
                  <Button
                    type="button"
                    buttonStyle="danger"
                    onClick={toggleDeleteModal}
                  >
                    Slet udstyr
                  </Button>
                  <Modal
                    isModalOpen={isDeleteModalOpen}
                    setIsModalOpen={setIsDeleteModalOpen}
                    header={`Slet udstyr`}
                    bottom={
                      <Button
                        buttonStyle="danger"
                        onClick={handleDeleteEquipment}
                      >
                        Slet udstyr
                      </Button>
                    }
                    content={
                      <div className="delete-modal-content">
                        <Icon name="warning" />
                        <p>Er du sikker på at du vil slette udstyret?</p>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </Panel>
        </Panels>
        <Panel noPadding columns="35">
          {isInitialLoad.current ? (
            <div className="equipment-attachments">
              <Skeleton height={50} count={2} />
            </div>
          ) : (
            <>
              <div className="equipment-attachments">
                <h4>Vedhæftede filer</h4>
                {attachments.length > 0 && (
                  <div className="equipment-attachments-files">
                    {attachments.map((file, index) => {
                      return (
                        <div className="equipment-attachments-file" key={index}>
                          <Icon name="description" />
                          <Link
                            to={file.filePath}
                            target="_blank"
                            className="equipment-attachments-file-information"
                          >
                            {file.name}
                            <span>
                              Uploaded{' '}
                              {file.date &&
                                file.date.toDate().toLocaleString('da-DK', {
                                  dateStyle: 'short',
                                  timeStyle: 'short',
                                })}
                            </span>
                          </Link>
                          <div
                            className="equipment-attachments-file-remove"
                            onClick={() => deleteFile(file)}
                          >
                            <Icon name="close" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <FileUpload
                  handleUpload={(files) =>
                    uploadFiles(
                      files,
                      userInfo.companyId,
                      equipmentId,
                      handleFilesUploaded
                    )
                  }
                />
              </div>
              <div className="equipment-notes">
                <h4>Noter</h4>
                <InputField
                  name="notes"
                  value={equipmentDetailData.notes}
                  type="textarea"
                  onChange={handleNotesChange}
                />
                <div className="equipment-notes-actions">
                  {notesSaveFeedback && (
                    <span className="equipment-notes-actions__feedback">
                      {notesSaveFeedback}
                    </span>
                  )}
                  <Button
                    disabled={!notesSaveButtonEnabled}
                    onClick={handleNotesSave}
                    buttonStyle="secondary"
                    className="small"
                  >
                    Gem noter
                  </Button>
                </div>
              </div>
              <div className="equipment-actions">
                <Button
                  onClick={toggleEquipmentStatus}
                  buttonStyle={isInactive ? 'secondary' : 'primary'}
                >
                  {isInactive ? 'Marker som aktiv' : 'Marker som inaktiv'}
                </Button>
              </div>
            </>
          )}
        </Panel>
      </Panels>
      <Modal
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
        header={`Ret detaljer`}
        bottom={
          <>
            {formFeedback && (
              <span className="equipment-edit-modal-feedback">
                {formFeedback}
              </span>
            )}
            <Button onClick={handleSubmit}>Gem ændringer</Button>
          </>
        }
        content={
          <div className="equipment-edit-modal-content">
            <InputField
              required
              type="creatableSelect2"
              label="Variant"
              name="equipmentVariant"
              value={getVariantObjectForValue(values.equipmentVariant)}
              options={
                equipmentVariants
                  .filter(
                    (variant) =>
                      variant.data.equipmentType.id === values.equipmentType
                  )
                  .map((variant) => ({
                    value: variant.id,
                    label: variant.data.name,
                    target: {
                      value: variant.id,
                      name: 'equipmentVariant',
                    },
                  })) || []
              }
              isDisabled={equipmentVariantsSelectDisable}
              onCreateOption={handleCreateOptionForVariant}
              onChange={handleChange}
              placeholder="Variant"
              error={errors.equipmentVariant}
            />
            <InputField
              label="Serienummer"
              name="serialNumber"
              value={values.serialNumber}
              onChange={handleChange}
              placeholder="Serienummer"
            />
            <InputField
              label="Startdato"
              name="startDate"
              type="date"
              value={values.startDate}
              datePickerFixed={true}
              onChange={handleChange}
              placeholder="Startdato"
              max="9999-12-31"
            />
            <InputField
              label="Slutdato"
              name="endDate"
              type="date"
              value={values.endDate}
              datePickerFixed={true}
              onChange={handleChange}
              placeholder="Slutdato"
              max="9999-12-31"
            />
            <InputField
              label="Tildelt til"
              name="assignedTo"
              type="select2"
              isClearable
              value={
                employees.find((employee) => employee.id === values.assignedTo)
                  ? {
                      value: values.assignedTo,
                      label:
                        employees.find(
                          (employee) => employee.id === values.assignedTo
                        )?.data?.firstName +
                        ' ' +
                        employees.find(
                          (employee) => employee.id === values.assignedTo
                        )?.data?.lastName,
                    }
                  : null
              }
              onChange={handleChange}
              options={
                employees.map((employee) => ({
                  value: employee.id,
                  label: `${employee.data.firstName} ${employee.data.lastName}`,
                  target: {
                    value: employee.id,
                    name: 'assignedTo',
                  },
                })) || []
              }
            />
          </div>
        }
      />
    </div>
  );
}
