import { useEffect, useRef } from 'react';
import './popover.css';

export default function Popover({
  className,
  children,
  content,
  fixed = false,
  position = 'bottom',
  onClose, // A callback to close the popover
  ...props
}) {
  // Handle click outside popover and its content/children to close the popover
  const popoverRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the click target is outside the popover and its children
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        onClose(); // Call the onClose function passed as a prop
      }
    }

    // Add the event listener when the popover is open
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup the event listener when the component unmounts or is hidden
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={popoverRef} // Attach ref to the popover element
      className={`popover popover--${position} ${className ?? ''} ${
        fixed ? 'popover--fixed' : ''
      }`}
      {...props}
    >
      {children}
      <div className="popover__content">{content}</div>
    </div>
  );
}
