import './timeregistration-user-settings.css';
import { useContext } from 'react';
import { Panel } from '../../panel/panel';
import { EmployeesContext } from '../../../context';
import { Link } from 'react-router-dom';
import Avatar from '../../avatar/avatar';
import { formatDateForTimeInput } from '../../../utils/formatDates';

const danishDays = [
  'Mandag',
  'Tirsdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lørdag',
  'Søndag',
];

const calculateTotalWorkTime = (settings) => {
  let totalMinutes = 0;

  if (!settings) return '0t 0m';

  settings?.weeklySchedule.forEach((day) => {
    if (day.notWorking) return;

    const fromTime = new Date(day.from.toDate());
    const endTime = new Date(day.end.toDate());

    const hours = endTime.getHours() - fromTime.getHours();
    const minutes = endTime.getMinutes() - fromTime.getMinutes();

    const breakTime = settings.paidBreak ? 0 : settings.breakDuration;

    totalMinutes += hours * 60 + minutes - breakTime;
  });

  if (totalMinutes < 0) {
    return '0t 0m';
  }

  return `${Math.floor(totalMinutes / 60)}t ${totalMinutes % 60}m`;
};

export default function TimeRegistrationUserSettings() {
  const employees = useContext(EmployeesContext);
  const filteredEmployees = employees.filter(
    (employee) =>
      employee?.data?.settings?.timeRegistration?.individualTimeLogSettings ||
      employee?.data?.settings?.timeRegistration?.exempt
  );

  if (filteredEmployees.length === 0) return null;

  const items = filteredEmployees
    .map((employee) => {
      const settings =
        employee?.data?.timeLogSettings?.[
          employee?.data?.timeLogSettings?.length - 1
        ];

      if (!settings) return null;

      return {
        id: employee.id,
        firstName: employee.data.firstName,
        lastName: employee.data.lastName,
        profilePhotoUrl: employee.data.profilePhotoUrl,
        jobPosition: employee.data.jobPosition,
        exempt: employee.data.settings.timeRegistration.exempt,
        paidBreak: settings.paidBreak,
        isPaidHourly: settings.isPaidHourly,
        summary: settings.weeklySchedule.map((day, index) => {
          return {
            day: danishDays[index],
            notWorking: day.notWorking,
            from: day.from,
            end: day.end,
          };
        }),
        totalWorkTime: calculateTotalWorkTime(settings),
      };
    })
    .filter((item) => item);

  return (
    <Panel
      header={
        <>
          <h4>Medarbejdere med individuelle tidsregistrerings indstillinger</h4>
          <p>
            Tilpasning af individuelle indstillinger kan gøres under den enkelte
            medarbejder i{' '}
            <Link to={`/timeregistrering/medarbejdere`}>medarbejdere</Link>.
          </p>
        </>
      }
    >
      <div className="timeregistration-user-settings">
        <h5>Medarbejdere med individuelle indstillinger</h5>
        {items.map((item) => (
          <Link
            to={`/medarbejdere/${item.id}/indstillinger`}
            key={item.id}
            className="timeregistration-user-settings-item"
          >
            <div className="timeregistration-user-settings-item-details">
              <div className="timeregistration-user-settings-item-details-user">
                <Avatar
                  firstName={item.firstName}
                  lastName={item.lastName}
                  profilePhotoUrl={item.profilePhotoUrl}
                />
                <div>
                  <span className="timeregistration-user-settings-item-details-user__name">
                    {item.firstName} {item.lastName}
                  </span>
                  <span>{item.jobPosition}</span>
                </div>
              </div>
              {item.paidBreak && (
                <div className="timeregistration-user-settings-item-details-rule">
                  Har betalt pause
                </div>
              )}
            </div>
            <div className="timeregistration-user-settings-item-schedule">
              {item.exempt && (
                <div className="timeregistration-user-settings-item-schedule-rule">
                  Fritaget for tidsregistrering
                </div>
              )}
              {item.isPaidHourly && (
                <div className="timeregistration-user-settings-item-schedule-rule">
                  Timelønnet
                </div>
              )}
              {!item.isPaidHourly && !item.exempt && (
                <>
                  <div className="timeregistration-user-settings-item-schedule-days">
                    {item.summary.map((day) => (
                      <div
                        key={day.day}
                        className={`timeregistration-user-settings-item-schedule-day${
                          day.notWorking
                            ? ' timeregistration-user-settings-item-schedule-day--not-working'
                            : ''
                        }`}
                      >
                        <span>{day.day}</span>
                        <span>
                          {day.notWorking
                            ? 'Arbejder ikke'
                            : `${formatDateForTimeInput(
                                day.from,
                                false
                              )} - ${formatDateForTimeInput(day.end, false)}`}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="timeregistration-user-settings-item-schedule-total">
                    <span>Total</span>
                    <span>{item.totalWorkTime}</span>
                  </div>
                </>
              )}
            </div>
          </Link>
        ))}
      </div>
    </Panel>
  );
}
