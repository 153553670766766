const AGREEMENT_TYPE = [
  { value: 'Onboarding', label: 'Onboarding', frequencyOptions: [] },
  { value: 'Offboarding', label: 'Offboarding', frequencyOptions: [] },
  { value: 'Reboarding', label: 'Reboarding', frequencyOptions: [] },
  {
    value: '1 til 1',
    label: '1 til 1',
    frequencyOptions: [
      { value: 7, label: 'Om en uge' },
      { value: 14, label: 'Om 14 dage' },
      { value: 30, label: 'Om 1 måned', default: true },
    ],
  },
  { value: 'Exit interview', label: 'Exit interview', frequencyOptions: [] },
  {
    value: 'Udviklingssamtale',
    label: 'Udviklingssamtale',
    frequencyOptions: [
      { value: 180, label: 'Om 6 måneder', default: true },
      { value: 365, label: 'Om 1 år' },
    ],
  },
  {
    value: 'Lønsamtale',
    label: 'Lønsamtale',
    frequencyOptions: [
      { value: 180, label: 'Om 6 måneder' },
      { value: 365, label: 'Om 1 år', default: true },
      { value: 730, label: 'Om 2 år' },
      { value: 1095, label: 'Om 3 år' },
    ],
  },
  { value: 'Advarsel', label: 'Advarsel', frequencyOptions: [] },
  {
    value: 'Målsætninger - S.M.A.R.T',
    label: 'Målsætninger - S.M.A.R.T',
    frequencyOptions: [
      { value: 180, label: 'Om 6 måneder', default: true },
      { value: 365, label: 'Om 1 år' },
    ],
  },
  { value: 'Andet', label: 'Andet', frequencyOptions: [] },
];

export default AGREEMENT_TYPE;
