import './preloader.css';

export default function PreLoader() {
  return (
    <div className="preloader">
      <div className="preloader__inner">
        <div className="preloader__leaf preloader__leaf--1"></div>
        <div className="preloader__leaf preloader__leaf--2"></div>
      </div>
    </div>
  );
}
