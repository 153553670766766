import { Link, useLocation } from 'react-router-dom';
import Logo from '../logo/logo';
import Icon from '../icon/icon';
import { useState, useContext, useEffect } from 'react';
import { auth } from '../../firebase-config';
import { getIdTokenResult } from 'firebase/auth';
import { UserContext } from '../../context';
import './sidebar.css';

const addActiveClass = (path) => {
  const className =
    location.pathname.split('/')[1] === path
      ? 'nav-item nav-item-active'
      : 'nav-item';

  return className;
};

export default function Sidebar() {
  const location = useLocation();
  const userContext = useContext(UserContext);
  const [showAdmin, setShowAdmin] = useState(false);

  // Determine if the sidebar should be displayed based on the current route
  const showSidebar = !(
    location.pathname.includes('/complete-signup') ||
    location.pathname.includes('/thank-you-upgrade') ||
    location.pathname.includes('/thank-you-downgrade') ||
    location.pathname.includes('/verify-email') ||
    location.pathname.includes('/forgot-password') ||
    location.pathname.includes('/email-action-handler')
  );

  // Check if user is superadmin, if not, redirect to home
  useEffect(() => {
    const checkSuperadmin = async () => {
      const idTokenResult = await getIdTokenResult(auth.currentUser);
      if (idTokenResult.claims.superadmin) {
        setShowAdmin(true);
      }
    };

    if (userContext.uid) {
      checkSuperadmin();
    }
  }, [userContext.uid]);

  // Render the sidebar if showSidebar is true
  return (
    showSidebar && (
      <div className="sidebar-container">
        <Logo />
        <div className="nav-items-container">
          <Link
            to="/"
            className={
              location.pathname === '/'
                ? 'nav-item nav-item-active'
                : 'nav-item'
            }
          >
            <Icon name="dashboard" />
            Dashboard
          </Link>
          <Link to="/medarbejdere" className={addActiveClass('medarbejdere')}>
            <Icon name="group" />
            Medarbejdere
          </Link>
          <Link
            to="/tidsregistrering"
            className={addActiveClass('tidsregistrering')}
          >
            <Icon name="pending_actions" />
            Tidsregistrering
          </Link>
          <Link to="/aftaler" className={addActiveClass('aftaler')}>
            <Icon name="handshake" />
            Møder og aftaler
          </Link>
          {/* <Link
            to="/haandbog"
            className={
              addActiveClass('haandbog')
            }
          >
            <Icon name="import_contacts" />
            Medarbejderhåndbog
          </Link> */}
          <Link to="/udstyr" className={addActiveClass('udstyr')}>
            <Icon name="devices" />
            Udstyr
          </Link>
          <Link
            to="/onboarding-offboarding"
            className={addActiveClass('onboarding-offboarding')}
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_572_82)">
                <path
                  d="M18.6711 3.58832L18.6709 3.58818C18.0239 3.02081 17.01 3.48042 17.01 4.34083V5.96362H11.001C10.448 5.96362 10 6.41194 10 6.96462V8.71262C10 9.26548 10.4482 9.71362 11.001 9.71362H17.01V11.3364C17.01 12.1964 18.0237 12.6569 18.6711 12.0889C18.6711 12.0889 18.6712 12.0889 18.6712 12.0888L22.6586 8.59113L22.6588 8.59097C23.1117 8.19344 23.1144 7.48598 22.6586 7.08611C22.6584 7.08594 22.6582 7.08576 22.658 7.08559L18.6711 3.58832Z"
                  fill="#C0CBC0"
                  stroke="#26532B"
                  strokeWidth="2"
                />
                <path
                  d="M5.32892 11.5883L5.3289 11.5883L1.34142 15.0861L1.34135 15.0862C0.887054 15.4848 0.88676 16.1922 1.34135 16.5911L1.34142 16.5911L5.3289 20.0889L5.32891 20.0889C5.97624 20.6568 6.99 20.1965 6.99 19.3364V17.7136H12.999C13.5525 17.7136 14 17.2648 14 16.7126V14.9646C14 14.4118 13.5518 13.9636 12.999 13.9636H6.99V12.3408C6.99 11.4805 5.97608 11.0206 5.32892 11.5883ZM5.98618 12.3376L5.98834 12.3401L5.98618 12.3376Z"
                  fill="#C0CBC0"
                  stroke="#26532B"
                  strokeWidth="2"
                />
              </g>
              <defs>
                <clipPath id="clip0_572_82">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0 0.338623)"
                  />
                </clipPath>
              </defs>
            </svg>
            On-/Offboarding
          </Link>
          <Link to="/rekruttering" className={addActiveClass('rekruttering')}>
            <Icon name="person_search" />
            Rekruttering
          </Link>
          <Link to="/skabeloner" className={addActiveClass('skabeloner')}>
            <Icon name="dynamic_feed" />
            Skabeloner
          </Link>
          <Link
            to="https://www.hrfamly.dk/ressourcer/guides"
            className={addActiveClass('guides')}
            target="_blank"
          >
            <Icon name="school" />
            Guides
          </Link>
          {/* <Link to="/whistleblower" className={addActiveClass('whistleblower')}>
            <Icon name="sports" />
            Whistleblower
          </Link> */}
          {/* <Link
            to="/insights"
            className={
              location.pathname.includes('/insights')
                ? 'nav-item nav-item-active'
                : 'nav-item'
            }
          >
            <Icon name="assessment" />
            Insights
          </Link> */}
        </div>
        <div className="nav-items-container">
          {showAdmin && (
            <Link
              to="/admin"
              className={
                location.pathname === '/admin'
                  ? 'nav-item nav-item-active'
                  : 'nav-item'
              }
            >
              <Icon name="admin_panel_settings" />
              Admin
            </Link>
          )}
        </div>
      </div>
    )
  );
}
