import './timeregistration-user-registrations-modal-entry-item.css';

import TIME_LOG_ENTRY_TYPES from '../../../constants/time/timeLogEntryTypes';
import { useState, useCallback } from 'react';
import InputField from '../../input-field/input-field';
import { Timestamp } from 'firebase/firestore';
import Button from '../../button/button';
import { useLogs } from '../../../logsContext';

function EntryItemAdd({
  modalEmployeeId,
  modalDate,
  timeOffTypes,
  setAddMode,
}) {
  const [formValues, setFormValues] = useState({
    hours: 0.5,
    type: 'overtime',
    note: '',
    startTime: Timestamp.fromDate(
      new Date(Math.ceil(Date.now() / 1800000) * 1800000)
    ),
    timeOffType: '',
    endTime: Timestamp.fromDate(
      new Date(Math.ceil(Date.now() / 3600000) * 3600000)
    ),
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { addLogEntry } = useLogs();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'endTime' || name === 'startTime') {
      // Keep the date the same
      const date = new Date(formValues[name].seconds * 1000);

      // Set the hours and minutes from 'value'
      const newTime = new Date(value);
      date.setHours(newTime.getHours());
      date.setMinutes(newTime.getMinutes());

      // Move to nearest 15 minutes, e.g. 12:07 -> 12:15 or 12:23 -> 12:30
      const minutesRounded = Math.ceil(date.getMinutes() / 15) * 15;
      date.setMinutes(minutesRounded);

      // Calculate hours
      if (name === 'endTime' && formValues.startTime) {
        const start = new Date(formValues.startTime.seconds * 1000).getTime();
        const end = date.getTime();
        const calculatedHours = (end - start) / 36e5;

        setFormValues({
          ...formValues,
          [name]: Timestamp.fromDate(date),
          hours: calculatedHours,
        });
      } else if (name === 'startTime' && formValues.endTime) {
        const start = date.getTime();
        const end = new Date(formValues.endTime.seconds * 1000).getTime();
        const calculatedHours = (end - start) / 36e5;
        setFormValues({
          ...formValues,
          [name]: Timestamp.fromDate(date),
          hours: calculatedHours,
        });
      } else {
        setFormValues({ ...formValues, [name]: Timestamp.fromDate(date) });
      }
    } else {
      // Save as string
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);

    try {
      // Prepare the new entry data based on formValues
      const newEntryData = {
        hours: formValues.hours,
        type: formValues.type,
        note: formValues.note,
        startTime: formValues.startTime, // Already a Timestamp
        endTime: formValues.endTime, // Already a Timestamp
        // Include timeOffType only if type is 'timeOff'
        ...(formValues.type === 'timeOff' && {
          timeOffType: formValues.timeOffType,
        }),
      };

      // Call addLogEntry with userId, date, and newEntryData
      await addLogEntry(modalEmployeeId, modalDate, newEntryData);

      // Reset the form to initial state (optional)
      setFormValues({
        hours: 0.5,
        type: 'overtime',
        note: '',
        startTime: Timestamp.fromDate(
          new Date(Math.ceil(Date.now() / 1800000) * 1800000)
        ),
        timeOffType: '',
        endTime: Timestamp.fromDate(
          new Date(Math.ceil(Date.now() / 3600000) * 3600000)
        ),
      });

      // Exit add mode
      setAddMode(false);
    } catch (error) {
      console.error('Error adding new log entry:', error);
      alert('Failed to add log entry. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  }, [addLogEntry, formValues, modalEmployeeId, modalDate, setAddMode]);

  return (
    <div className="timeregistration-user-registrations-modal-entry-item">
      <div className="timeregistration-user-registrations-modal-entry-item-form-row">
        <InputField
          name="type"
          type="select"
          label="Type"
          placeholder="- Vælg -"
          options={TIME_LOG_ENTRY_TYPES.map((type) => ({
            value: type.value,
            label: type.label,
          }))}
          value={formValues.type}
          onChange={handleChange}
          className={`timeregistration-user-registrations-modal-entry-item-form__type ${
            formValues.type === 'timeOff'
              ? 'timeregistration-user-registrations-modal-entry-item-form__type--timeOff'
              : ''
          }`}
        />
        {formValues.type === 'timeOff' && (
          <InputField
            name="timeOffType"
            type="select"
            label="Årsag"
            placeholder="- Vælg -"
            options={timeOffTypes}
            value={formValues.timeOffType}
            onChange={handleChange}
            className={`timeregistration-user-registrations-modal-entry-item-form__type ${
              formValues.type === 'timeOff'
                ? 'timeregistration-user-registrations-modal-entry-item-form__type--timeOff'
                : ''
            }`}
          />
        )}
      </div>
      <div className="timeregistration-user-registrations-modal-entry-item-form-row">
        <InputField
          name="startTime"
          type="time"
          label="Fra"
          placeholder="Fra"
          value={formValues.startTime.toDate()}
          onChange={handleChange}
          className="timeregistration-user-registrations-modal-entry-item-form__time"
        />
        <InputField
          name="endTime"
          type="time"
          label="Til"
          placeholder="Til"
          value={formValues.endTime.toDate()}
          onChange={handleChange}
          className="timeregistration-user-registrations-modal-entry-item-form__time"
        />
        <InputField
          name="hours"
          label="Timer"
          type="number"
          key={`hours-${formValues.hours}`}
          value={formValues.hours?.toFixed(2) || ''}
          disabled
          className="timeregistration-user-registrations-modal-entry-item-form__hours"
        />
      </div>
      <InputField
        name="note"
        label="Note"
        type="text"
        onChange={handleChange}
        value={formValues.note || ''}
      />
      <div className="timeregistration-user-registrations-modal-entry-item-form__buttons">
        <Button
          buttonStyle="text-link"
          className="small"
          onClick={() => setAddMode(false)}
        >
          Luk
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? 'Gemmer...' : 'Gem'}
        </Button>
      </div>
    </div>
  );
}

export default EntryItemAdd;
