import { useContext, useState, useEffect, useCallback, useRef } from 'react';
import './profile-view.css';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { UserContext, UserInfoContext } from '../../context';
import { useForm } from '../../hooks/useForm';
import Button from '../../components/button/button';
import InputField from '../../components/input-field/input-field';
import { Panels, Panel } from '../../components/panel/panel';
import ProfilePhotoUpload from '../../components/profile-photo-upload/profile-photo-upload';
import PageTop from '../../components/page-top/page-top';

export default function ProfileView() {
  const isInitialLoad = useRef(true);
  const userContext = useContext(UserContext);
  const userInfoContext = useContext(UserInfoContext);
  const [userData, setUserData] = useState(null);
  const [formFeedback, setFormFeedback] = useState('');

  // Form validation
  const validateProfileForm = (values) => {
    let errors = {};

    if (!values.firstName.trim()) errors.firstName = 'Fornavn mangler';
    if (!values.lastName.trim()) errors.lastName = 'Efternavn mangler';
    if (!values.jobPosition.trim()) errors.jobPosition = 'Arbejdstitel mangler';

    return errors;
  };

  // Handle form submission
  const onSubmit = useCallback(
    async (values) => {
      // Check if we have user data and a document ID
      if (userData && userData.id) {
        const userDocRef = doc(db, 'users', userContext.uid);

        const valuesToSave = {
          firstName: values.firstName,
          lastName: values.lastName,
          jobPosition: values.jobPosition,
        };

        try {
          // Update the document in Firestore
          await setDoc(userDocRef, valuesToSave, { merge: true });
          // Handle successful update
          setFormFeedback('Ændringerne er gemt.');
        } catch (error) {
          // Handle any errors
          setFormFeedback('Der skete en fejl. Prøv igen senere.');
          console.error('Error updating profile:', error);
        }
      } else {
        console.error('No user data or document ID available for update.');
      }
    },
    [userContext.uid, userData]
  );

  // Custom hook for handling form state
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    {
      firstName: '',
      lastName: '',
      jobPosition: '',
    },
    validateProfileForm,
    onSubmit
  );

  // Fetch the user's data from UserInfoContext
  useEffect(() => {
    if (userInfoContext) {
      setUserData(userInfoContext);
    }
  }, [userInfoContext]);

  // Populate the form with the user's data
  useEffect(() => {
    if (userData && isInitialLoad.current) {
      const fetchedUserData = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        jobPosition: userData.jobPosition,
        profilePhotoUrl: userData.profilePhotoUrl,
      };

      setValues(fetchedUserData);
      isInitialLoad.current = false;
    }
  }, [userData, setValues]);

  return (
    <div className="main-layout">
      <PageTop title="Profil" />
      <Panels>
        <Panel header={<h4>Informationer</h4>}>
          <ProfilePhotoUpload
            firstName={values.firstName}
            lastName={values.lastName}
            profilePhotoUrl={values.profilePhotoUrl}
          />
          <form onSubmit={handleSubmit}>
            <InputField
              label="Fornavn"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              placeholder="Skriv dit fornavn"
              error={errors.firstName}
            />
            <InputField
              label="Efternavn"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              placeholder="Skriv dit efternavn"
              error={errors.lastName}
            />
            <InputField
              label="Din arbejdstitel"
              name="jobPosition"
              value={values.jobPosition}
              onChange={handleChange}
              placeholder="Hvad er din stilling?"
              error={errors.jobPosition}
            />
            <InputField
              label="Email"
              name="email"
              value={userContext.email}
              disabled
              readOnly
              help="Email kan ikke ændres af sikkerhedsmæssige årsager. Kontakt HRfamly hvis du har behov for at ændre den."
            />
            <div className="form-actions">
              <Button type="submit">Gem ændringer</Button>
              {formFeedback && (
                <span className="form-actions__feedback">{formFeedback}</span>
              )}
            </div>
          </form>
        </Panel>
      </Panels>
    </div>
  );
}
