import { useRef, useEffect, useContext, useCallback, useState } from 'react';
import {
  UserInfoContext,
  EmployeesContext,
  EquipmentContext,
} from '../../context';
import {
  doc,
  collection,
  addDoc,
  Timestamp,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../firebase-config';
import Button from '../../components/button/button';
import Modal from '../../components/modal/modal';
import InputField from '../../components/input-field/input-field';
import Icon from '../../components/icon/icon';
import { useForm } from '../../hooks/useForm';
import './equipment-create-modal.css';
import EmojiPicker from '../../components/emoji-picker/emoji-picker';
import FileUpload from '../../components/file-upload/file-upload';

const uploadFiles = async (files, companyId, equipmentId) => {
  // Check for early return conditions
  if (!files || !companyId || !equipmentId) return false;

  // Normalize files to an array (handles both single file and FileList objects)
  const filesArray = files instanceof File ? [files] : Array.from(files);

  if (filesArray.length === 0) return false;

  await Promise.all(
    filesArray.map(async (file) => {
      const filePath = `${companyId}/equipment/${equipmentId}/${file.name}`;
      const fileRef = ref(storage, filePath);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);

      const fileData = {
        date: Timestamp.fromDate(new Date()),
        extension: file.name.split('.').pop(),
        filePath: fileUrl,
        name: file.name,
      };

      const equipmentDoc = doc(db, 'equipment', equipmentId);
      await updateDoc(equipmentDoc, {
        files: arrayUnion(fileData),
      });

      return fileData;
    })
  );
  return true;
};

export default function EquipmentCreateModal({
  isModalOpen,
  setIsModalOpen,
  assignedTo,
  newCategory = false,
}) {
  const fileUploadRef = useRef();
  const employees = useContext(EmployeesContext);
  const userInfo = useContext(UserInfoContext);
  const [formFeedback, setFormFeedback] = useState('');
  const { equipmentTypes, equipmentVariants } = useContext(EquipmentContext);
  const [selectedEquipmentVariants, setSelectedEquipmentVariants] = useState(
    []
  );
  const [newType, setNewType] = useState({
    name: '',
    icon: '',
  });
  const [equipmentNewTypeDisableButton, setEquipmentNewTypeDisableButton] =
    useState(true);
  const [equipmentVariantsSelectDisable, setEquipmentVariantsSelectDisable] =
    useState(false);

  const [step, setStep] = useState(0);

  // Form validation
  const validateEquipmentForm = (values) => {
    let errors = {};

    if (!values.equipmentVariant) errors.equipmentVariant = 'Variant mangler';

    return errors;
  };

  // Handle new type value changes
  const handleNewTypeChange = (e) => {
    setNewType({ ...newType, [e.target.name]: e.target.value });

    if (newType.icon && e.target.value) {
      setEquipmentNewTypeDisableButton(false);
    } else {
      setEquipmentNewTypeDisableButton(true);
    }
  };

  // Handle icon selection for new type
  const handleIconSelect = (icon) => {
    setNewType({ ...newType, icon });

    // Enable/disable submit button if both fields are filled
    if (newType.name && icon) {
      setEquipmentNewTypeDisableButton(false);
    } else {
      setEquipmentNewTypeDisableButton(true);
    }
  };

  // Handle new type submit
  const handleNewTypeSubmit = () => {
    // Add new type to database
    const newTypeRef = collection(db, 'equipmentTypes');
    const newTypeData = {
      companyId: userInfo.companyId,
      createdBy: doc(db, 'users', userInfo.id),
      name: newType.name,
      icon: newType.icon,
    };

    addDoc(newTypeRef, newTypeData)
      .then((docRef) => {
        // Reset new type fields
        setNewType({ name: '', icon: '' });

        // Disable submit button for new type
        setEquipmentNewTypeDisableButton(true);

        // If new category, finish the process, close the modal
        if (newCategory) {
          setIsModalOpen(false);
          return;
        }

        // Set equipmentType value to the newly created type
        setValues({ ...values, equipmentType: docRef.id });

        // Move to next step
        setStep(1);
      })
      .catch((error) => {
        console.error('Error adding equipment type: ', error);
      });
  };

  const handleTypeSelect = (e) => {
    setValues({ ...values, equipmentType: e.target.value });
    setStep(1);
  };

  const getVariantObjectForValue = (variantId) => {
    const variant = equipmentVariants.find((v) => v.id === variantId);
    return variant ? { value: variant.id, label: variant.data.name } : null;
  };

  // Handle variant creation
  const handleCreateOptionForVariant = async (inputValue) => {
    const equipmentVariantsRef = collection(db, 'equipmentVariants');
    const equipmentTypeRef = doc(db, 'equipmentTypes', values.equipmentType);
    const newVariantData = {
      companyId: userInfo.companyId,
      createdBy: doc(db, 'users', userInfo.id),
      equipmentType: equipmentTypeRef,
      name: inputValue,
    };

    // Disable input while creating
    setEquipmentVariantsSelectDisable(true);

    try {
      const docRef = await addDoc(equipmentVariantsRef, newVariantData);

      // Set equipmentVariant value to the newly created variant
      setValues({ ...values, equipmentVariant: docRef.id });

      // Enable input after creation
      setEquipmentVariantsSelectDisable(false);
    } catch (error) {
      console.error('Error adding equipment variant: ', error);

      // Enable input
      setEquipmentVariantsSelectDisable(false);
    }
  };

  // Handle reset data when modal is closed
  const handleModalClose = () => {
    setFormFeedback('');
    setNewType({ name: '', icon: '' });
    setEquipmentNewTypeDisableButton(true);
    setEquipmentVariantsSelectDisable(false);
    setStep(0);
    setValues({
      assignedTo: '',
      equipmentType: '',
      equipmentVariant: '',
      startDate: '',
      endDate: '',
      serialNumber: '',
      notes: '',
    });

    setIsModalOpen(false);
  };

  // Handle form submission
  const onSubmit = useCallback(
    async (values) => {
      // Convert dates to Firestore Timestamps
      const valuesToSave = {
        ...values,
        assignedTo: values.assignedTo
          ? doc(db, 'users', values.assignedTo)
          : null,
        companyId: userInfo.companyId,
        createdBy: doc(db, 'users', userInfo.id),
        equipmentType: doc(db, 'equipmentTypes', values.equipmentType),
        equipmentVariant: doc(db, 'equipmentVariants', values.equipmentVariant),
        notes: values.notes || '',
        startDate: values.startDate
          ? Timestamp.fromDate(new Date(values.startDate))
          : null,
        endDate: values.endDate
          ? Timestamp.fromDate(new Date(values.endDate))
          : null,
      };

      const newEquipmentRef = collection(db, 'equipment');

      try {
        // Add a new document with a generated id.
        const docRef = await addDoc(newEquipmentRef, valuesToSave);

        // Check if there are selected files to upload
        const files = fileUploadRef.current.getSelectedFiles();
        if (files && files.length > 0) {
          // Upload files using the agreementId
          await uploadFiles(files, userInfo.companyId, docRef.id);

          // Optionally, clear the selected files from the FileUpload component
          fileUploadRef.current.clearSelectedFiles();
        }

        // Close the modal
        setIsModalOpen(false);

        // Use window.location to force a full page reload
        window.location.reload();
      } catch (error) {
        // Handle any errors
        setFormFeedback('Der skete en fejl. Prøv igen senere.');
        console.error('Error updating agreement:', error);
      }
    },
    [userInfo, fileUploadRef, setIsModalOpen]
  );

  // Custom hook for handling form state
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    {
      assignedTo: assignedTo || '',
      equipmentType: '',
      equipmentVariant: '',
      startDate: '',
      endDate: '',
      serialNumber: '',
      notes: '',
    },
    validateEquipmentForm,
    onSubmit
  );

  // Fetch equipment variants from context when the equipment type changes
  useEffect(() => {
    if (values.equipmentType) {
      setSelectedEquipmentVariants(
        equipmentVariants.filter(
          (variant) => variant.data.equipmentType.id === values.equipmentType
        )
      );
    }
  }, [values.equipmentType, equipmentVariants]);

  // Set initial values when modal is opened
  useEffect(() => {
    if (isModalOpen) {
      setValues({
        ...values,
        assignedTo: assignedTo || '',
      });
    }
  }, [isModalOpen]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={handleModalClose}
      header={newCategory ? `Tilføj kategori` : `Tilføj udstyr`}
      bottom={
        step === 1 && (
          <>
            {formFeedback && (
              <span className="equipment-create-modal-feedback">
                {formFeedback}
              </span>
            )}
            <Button onClick={handleSubmit}>Tilføj</Button>
          </>
        )
      }
      content={
        <div className="equipment-create-modal-content">
          {step === 0 && (
            <div className="equipment-create-modal-step equipment-create-modal-step--type">
              {!newCategory && (
                <>
                  {equipmentTypes.length > 0 && <h5>Vælg kategori</h5>}
                  {equipmentTypes.map((type) => (
                    <div
                      className="equipment-create-modal-step__type"
                      key={type.id}
                    >
                      <input
                        type="radio"
                        id={type.id}
                        name="equipmentType"
                        value={type.id}
                        onChange={handleTypeSelect}
                      />
                      <label htmlFor={type.id}>
                        <span className="equipment-create-modal-step__type-icon">
                          {type.data.icon}
                        </span>
                        {type.data.name}
                        <Icon name="checkmark" />
                      </label>
                    </div>
                  ))}
                </>
              )}
              <div className="equipment-create-modal-step__new-type">
                {!newCategory && <h5>Tilføj ny kategori</h5>}
                <div className="equipment-create-modal-step__new-type-field">
                  <EmojiPicker
                    value={newType.icon}
                    onSelect={handleIconSelect}
                  />

                  <InputField
                    required
                    label="Navn på kategori, f.eks. Computer"
                    name="name"
                    value={newType.name}
                    onChange={handleNewTypeChange}
                    placeholder="Navn på kategori, f.eks. Computer"
                    autoComplete="off"
                    data-1p-ignore // Prevent 1Password from autofilling
                  />
                  <Button
                    disabled={equipmentNewTypeDisableButton}
                    onClick={handleNewTypeSubmit}
                  >
                    Tilføj
                  </Button>
                </div>
              </div>
            </div>
          )}

          {step === 1 && (
            <div className="equipment-create-modal-step equipment-create-modal-step--variant">
              <InputField
                required
                type="creatableSelect2"
                label="Variant"
                name="equipmentVariant"
                value={getVariantObjectForValue(values.equipmentVariant)}
                options={
                  selectedEquipmentVariants
                    .sort((a, b) => a.data.name.localeCompare(b.data.name))
                    .map((variant) => ({
                      value: variant.id,
                      label: variant.data.name,
                      target: {
                        value: variant.id,
                        name: 'equipmentVariant',
                      },
                    })) || []
                }
                isDisabled={equipmentVariantsSelectDisable}
                onCreateOption={handleCreateOptionForVariant}
                onChange={handleChange}
                placeholder="Variant"
                error={errors.equipmentVariant}
              />
              <InputField
                type="select2"
                label="Tildel til"
                name="assignedTo"
                value={
                  values.assignedTo
                    ? {
                        value: values.assignedTo,
                        label: `${
                          employees?.find((e) => e.id === values.assignedTo)
                            ?.data.firstName
                        } ${
                          employees?.find((e) => e.id === values.assignedTo)
                            ?.data.lastName
                        }`,
                        target: {
                          value: values.assignedTo,
                          name: 'assignedTo',
                        },
                      }
                    : undefined
                }
                options={
                  employees.map((employee) => ({
                    value: employee.id,
                    label: `${employee.data.firstName} ${employee.data.lastName}`,
                    target: {
                      value: employee.id,
                      name: 'assignedTo',
                    },
                  })) || []
                }
                onChange={handleChange}
                placeholder="Tildel til"
                help="Efterlad blank hvis det er fælles udstyr"
              />
              <InputField
                label="Startdato"
                name="startDate"
                type="date"
                value={values.startDate}
                onChange={handleChange}
                placeholder="Startdato"
                help="Bruges til f.eks. hvornår udstyret er købt, tildelt eller lignende"
              />
              <InputField
                label="Slutdato"
                name="endDate"
                type="date"
                value={values.endDate}
                onChange={handleChange}
                placeholder="Slutdato"
                help="Bruges til f.eks. nedskrivning, udlån eller lignende"
              />
              <InputField
                label="Serienummer"
                name="serialNumber"
                value={newType.serialNumber}
                onChange={handleChange}
                placeholder="Serienummer"
              />
              <InputField
                label="Noter"
                name="notes"
                type="textarea"
                value={values.noter}
                onChange={handleChange}
                placeholder="Noter"
              />
              <FileUpload uploadImmediately={false} ref={fileUploadRef} />
            </div>
          )}
        </div>
      }
    />
  );
}
