import Icon from '../../components/icon/icon';
import './important-list.css';

export default function ImportantList({ className, header, content }) {
  // Remove null items from the content array
  content = content?.filter((item) => item);

  // If there are no items in the content array, return early
  if (!content?.length) return null;

  return (
    <div className={`important-list-wrapper ${className}`}>
      {header && <div className="important-list__header">{header}</div>}
      <div className="important-list">
        {content?.map((item, index) => {
          if (!item) return null;

          return (
            <div
              key={index}
              className={`important-list-item ${
                item.missing && 'important-list-item--missing'
              }`}
            >
              <div className="important-list-item__icon">
                <Icon name={item.missing ? 'warning' : 'check'} />
              </div>
              <div className="important-list-item__text">
                <h5>{item.title}</h5>
                <span>{item.text}</span>
              </div>
              <div className="important-list-item__actions">{item.actions}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
