import './employee-create-modal.css';
import { useContext, useCallback, useState } from 'react';
import {
  EmployeesContext,
  SubscriptionContext,
  UserContext,
  UserInfoContext,
} from '../../context';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase-config';
import Button from '../../components/button/button';
import Modal from '../../components/modal/modal';
import InputField from '../../components/input-field/input-field';
import { useForm } from '../../hooks/useForm';
import Icon from '../../components/icon/icon';

export default function EmployeeCreateModal({
  isModalOpen,
  setIsModalOpen,
  setIsPlansModalOpen = () => {},
}) {
  const user = useContext(UserContext);
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);
  const { subscription } = useContext(SubscriptionContext);
  const [formFeedback, setFormFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const subscriptionName = subscription?.name || '';
  const limit =
    subscriptionName === 'premium'
      ? 102
      : subscriptionName === 'premiumplus'
      ? 1000000
      : 12;

  const handleLimitedCTAClick = () => {
    if (setIsPlansModalOpen) {
      setIsModalOpen(false);
      setIsPlansModalOpen(true);
    }
  };

  // Form validation
  const validateForm = (values) => {
    let errors = {};

    if (!values.firstName.trim()) errors.firstName = 'Fornavn mangler';
    if (!values.lastName.trim()) errors.lastName = 'Efternavn mangler';
    if (!values.jobPosition.trim()) errors.jobPosition = 'Arbejdstitel mangler';
    if (!values.email.trim()) errors.email = 'Email mangler';

    return errors;
  };

  // Handle form submission
  const onSubmit = useCallback(
    async (values) => {
      // Convert dates to Firestore Timestamps
      const valuesToSave = {
        ...values,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        jobPosition: values.jobPosition.trim(),
        email: values.email.trim(),
        date_created: Timestamp.now(),
      };

      setIsSubmitting(true);

      // New employee: call serverless function
      try {
        const response = await fetch('/api/user/add', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-user-uuid': user.uid,
          },
          body: JSON.stringify({
            email: valuesToSave.email,
            companyId: userInfo.companyId,
          }),
        });

        if (!response.ok) {
          setIsSubmitting(false);
          setFormFeedback('E-mailen er allerede i brug');
          throw new Error('Error adding employee', response);
        }

        // Get the response data to get the employee ID
        const data = await response.json();
        const employeeDocRef = doc(db, 'users', data.employeeId);

        // Add the employee data to Firestore
        await setDoc(employeeDocRef, valuesToSave, { merge: true });

        // Close the modal
        setIsModalOpen(false);

        // Redirect to the new employee's profile with window.location to force a full page reload
        window.location = `/medarbejdere/${data.employeeId}`;
      } catch (error) {
        console.error('Error adding employee:', error);
        setFormFeedback(
          'Der skete en fejl. Er emailen allerede i brug eller oprettet i en anden virksomhed?'
        );
        setIsSubmitting(false);
        return;
      }
    },
    [user, userInfo, setIsModalOpen]
  );

  // Custom hook for handling form state
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    {
      firstName: '',
      lastName: '',
      email: '',
      jobPosition: '',
    },
    validateForm,
    onSubmit
  );

  // If the user has reached the employee limit, show a different Modal
  if (employees.length >= limit) {
    return (
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        customWidth={600}
        header={null}
        bottom={null}
        content={
          <div className="employee-limited-modal">
            <Icon name="group" />
            <h4>Opgradér abonnement for at tilføje over 10 medarbejdere</h4>
            <div className="employee-limited-modal-benefits">
              <h5>Fordele ved at opgradere til Premium</h5>
              <div className="employee-limited-modal-benefits-list">
                <div>
                  <div className="icon-container">
                    <Icon name="check" />
                  </div>
                  <span>Tilføj op til 100 medarbejdere</span>
                </div>
                <div>
                  <div className="icon-container">
                    <Icon name="check" />
                  </div>
                  <span>Rekruttering basic</span>
                </div>
                <div>
                  <div className="icon-container">
                    <Icon name="check" />
                  </div>
                  <span>Rekrutteringsplatform</span>
                </div>
                <div>
                  <div className="icon-container">
                    <Icon name="check" />
                  </div>
                  <span>Personlig kontaktperson</span>
                </div>
                <div>
                  <div className="icon-container">
                    <Icon name="check" />
                  </div>
                  <span>Komplet udstyrsliste</span>
                </div>
                <div>
                  <div className="icon-container">
                    <Icon name="check" />
                  </div>
                  <span>Juridisk rådgivning</span>
                </div>
              </div>
            </div>
            <Button onClick={handleLimitedCTAClick}>Vis prisoversigt</Button>
          </div>
        }
      />
    );
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      header={`Ny medarbejder`}
      bottom={
        <>
          {formFeedback && (
            <span className="employee-create-modal-feedback">
              {formFeedback}
            </span>
          )}
          <Button disabled={isSubmitting} onClick={handleSubmit}>
            {isSubmitting ? 'Opretter...' : 'Opret'}
          </Button>
        </>
      }
      content={
        <div className="employee-create-modal-content">
          <InputField
            required
            label="Fornavn"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            placeholder="Fornavn"
            error={errors.firstName}
          />
          <InputField
            required
            label="Efternavn"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            placeholder="Efternavn"
            error={errors.lastName}
          />
          <InputField
            required
            label="Arbejdstitel"
            name="jobPosition"
            value={values.jobPosition}
            placeholder="Arbejdstitel"
            onChange={handleChange}
            error={errors.jobPosition}
          />
          <InputField
            label="Arbejdsemail"
            name="email"
            value={values.email}
            required={true}
            onChange={handleChange}
            error={errors.email}
            help={
              'Arbejdsemail kan kun indstilles én gang. Det er vigtigt den er skrevet korrekt.'
            }
            placeholder="Arbejdsemail"
          />
        </div>
      }
    />
  );
}
