import { useCallback, useEffect, useState } from 'react';
import { Panel } from '../../components/panel/panel';
import InputField from '../../components/input-field/input-field';
import TimeRegistrationTop from '../../components/timeregistration/top/timeregistration-top';
import TimeRegistrationSummary from '../../components/timeregistration/summary/timeregistration-summary';
import TimeRegistrationUserMetrics from '../../components/timeregistration/user-metrics/timeregistration-user-metrics';
import TimeRegistrationDateRangeFilter from '../../components/timeregistration/date-range-filter/timeregistration-date-range-filter';
import { calculateAllUsersMetrics } from './timeregistration-utils';
import Button from '../../components/button/button';
import * as XLSX from 'xlsx';

const TimeRegistrationOverview = ({
  logs,
  companyData,
  dateRange,
  setDateRange,
  employees,
  employeesToDisplay,
  setEmployeesToDisplay,
  valueToDisplay,
  setValueToDisplay,
  employeeToDisplayInFilter,
  setEmployeeToDisplayInFilter,
}) => {
  const [metrics, setMetrics] = useState({});

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleEmployeeFilterChange = useCallback(
    (e) => {
      const selectedEmployeeId = e.target.value;
      const selectedEmployee = employees.find(
        (employee) => employee.id === selectedEmployeeId
      );

      if (selectedEmployee) {
        setEmployeesToDisplay([selectedEmployee]);
        setEmployeeToDisplayInFilter(selectedEmployee);
      } else {
        setEmployeesToDisplay(employees);
        setEmployeeToDisplayInFilter(null);
      }
    },
    [employees, setEmployeesToDisplay, setEmployeeToDisplayInFilter]
  );

  const handleExportToExcel = () => {
    const roundToNearestQuarter = (value) => {
      return Math.round(value * 4) / 4;
    };

    const convertedMetricsForXlsx = Object.keys(metrics).map((id) => {
      const data = metrics[id];
      const firstName = employees.find((employee) => employee.id === id).data
        .firstName;
      const lastName = employees.find((employee) => employee.id === id).data
        .lastName;

      return {
        Medarbejder: `${firstName} ${lastName}`,
        Normtid: Math.max(0, data.normHours), // Normtid (normal working hours)
        Arbejdstid: Math.max(0, data.actualWorkHours), // Actual work hours
        Sygdom: Math.max(0, data.sickLeave.hours), // Sick leave hours
        Fri: Math.max(0, data.timeOff.hours), // Time off hours
        Ferie: Math.max(0, data.holidays.hours), // Holiday hours
        'Arbejde u.f. normtid': Math.max(0, data.overtime.hours), // Overtime hours
        'Gennemsnit pr. uge': Math.max(
          0,
          roundToNearestQuarter(data.avgHoursPerWeek)
        ), // Average hours per week rounded to nearest 0.25
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(convertedMetricsForXlsx);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `HRfamly tidsregistreringsoverblik - ${new Date(
      dateRange.startDate
    ).toLocaleDateString('da-DK')}-${new Date(
      dateRange.endDate
    ).toLocaleDateString('da-DK')}.xlsx`;
    document.body.appendChild(link);
    link.click();
    link.remove();

    // Revoke the URL to free up memory
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const logsByUser = logs.reduce((acc, log) => {
      const { userId } = log;
      if (!acc[userId]) {
        acc[userId] = [];
      }
      acc[userId].push(log);
      return acc;
    }, {});

    const allUsersMetrics = calculateAllUsersMetrics(
      logsByUser,
      employeesToDisplay,
      companyData,
      dateRange
    );
    setMetrics(allUsersMetrics);
  }, [logs, employeesToDisplay, companyData, dateRange]);

  return (
    <Panel
      noPadding
      inlineHeader
      header={
        <TimeRegistrationTop
          title="Overblik"
          filters={
            <>
              <Button
                className="small"
                buttonStyle="text-link"
                onClick={handleExportToExcel}
              >
                Eksporter til XLSX
              </Button>
              <TimeRegistrationDateRangeFilter
                key={'filter-date'}
                name={'date'}
                value={dateRange}
                onChange={handleDateRangeChange}
                valueToDisplay={valueToDisplay}
                setValueToDisplay={setValueToDisplay}
              />

              <InputField
                key={'filter-employee'}
                label={'Medarbejder'}
                type="select"
                name={'employee'}
                options={employees.map((employee) => ({
                  value: employee.id,
                  label: `${employee.data.firstName} ${employee.data.lastName}`,
                }))}
                placeholder="- Vælg -"
                value={employeeToDisplayInFilter?.id}
                onChange={handleEmployeeFilterChange}
              />
            </>
          }
        />
      }
    >
      <TimeRegistrationSummary metrics={metrics} />
      <TimeRegistrationUserMetrics
        companyData={companyData}
        employees={employeesToDisplay}
        metrics={metrics}
      />
    </Panel>
  );
};

export default TimeRegistrationOverview;
