import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

const gtmId = 'GTM-WZS5S7ZD';

export const initializeTagManager = () => {
  if (import.meta.env.DEV) {
    // Do not initialize GTM in development
    return null;
  }
  TagManager.initialize({ gtmId });
};

export const PageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: location.pathname,
        path: location.pathname,
      },
    });
  }, [location]);

  if (import.meta.env.DEV) {
    // Do not initialize GTM in development
    return null;
  }
};
