import './subscription-transactions.css';
import Icon from '../../components/icon/icon';
import List from '../../components/list/list';
import { Panel } from '../../components/panel/panel';

export default function SubscriptionTransactions({ transactions }) {
  // Handle Download button click for invoice
  const handleDownloadInvoice = async (
    transactionId,
    transaction_number,
    companyId
  ) => {
    // Use /api/payment/generateinvoice to generate invoice PDF
    await fetch('/api/payment/generateinvoice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        companyId,
        id: transactionId,
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `HRfamly_faktura_${transaction_number}.pdf`;
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Revoke the URL to free up memory
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading invoice:', error);
      });
  };

  const transactionsData =
    transactions?.map((transactionItem) => {
      // Prepare transaction data for the state
      return {
        id: transactionItem.id,
        fields: [
          {
            label: 'Faktura #',
            value: transactionItem?.data?.onPayData?.data?.transaction_number,
            sortBy: transactionItem?.data?.onPayData?.data?.transaction_number,
            searchBy:
              transactionItem?.data?.onPayData?.data?.transaction_number,
          },
          {
            label: 'Dato',
            value: new Date(
              transactionItem?.data?.date_created.toDate()
            ).toLocaleDateString('da-DK'),
          },
          {
            label: 'Total ekskl. moms',
            value: `${(transactionItem.data?.amount / 100).toLocaleString(
              'da-DK'
            )} kr.`,
          },
          {
            label: 'Total inkl. moms',
            value: `${(
              (transactionItem.data?.amount / 100) *
              1.25
            ).toLocaleString('da-DK')} kr.`,
          },
          {
            label: 'Fil',
            value:
              transactionItem.data?.status === 'failed' ? (
                <div className="transactions-list-item__icon transactions-list-item__icon--failed">
                  <Icon name="close" />
                  <span title="Vi kunne ikke trække pengene fra din konto, vi forsøger igen om 24 timer">
                    Fejlet
                  </span>
                </div>
              ) : transactionItem.data?.status === 'pending' ? (
                <div className="transactions-list-item__icon transactions-list-item__icon--pending">
                  <Icon name="pending" />
                  <span title="Vi afventer betaling fra din bank">
                    Afventer
                  </span>
                </div>
              ) : (
                <div
                  className="transactions-list-item__icon"
                  onClick={() =>
                    handleDownloadInvoice(
                      transactionItem.id,
                      transactionItem?.data?.onPayData?.data
                        ?.transaction_number || '',
                      transactionItem?.data?.companyId
                    )
                  }
                >
                  <Icon name="download" />
                  <span>Download</span>
                </div>
              ),
          },
        ],
        filterData: {
          'Faktura #':
            transactionItem?.data?.onPayData?.data?.transaction_number,
        },
      };
    }) || [];

  return (
    <Panel noPadding header={<h4>Fakturaer</h4>}>
      <List
        head={[
          { label: 'Faktura #' },
          { label: 'Dato' },
          { label: 'Total ekskl. moms' },
          { label: 'Total inkl. moms' },
          { label: 'Fil' },
        ]}
        showSearch
        searchPlaceholder="Søg efter faktura"
        items={transactionsData || []}
        noResultsMessage="Ingen fakturaer"
      />
    </Panel>
  );
}
