import { useEffect } from 'react';

export default function ZendeskChatWidget() {
  useEffect(() => {
    const zendeskKey = import.meta.env.VITE_ZENDESK_KEY;
    if (!zendeskKey) {
      return;
    }

    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    script.async = true;

    // Append the script only if it doesn't exist in the document body
    if (!document.getElementById('ze-snippet')) {
      document.body.appendChild(script);
    }

    // Cleanup function to remove the script when the component unmounts
    return () => {
      const existingScript = document.getElementById('ze-snippet');
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return null;
}
