export const formatDateForTimeInput = (timestamp, includeSeconds = true) => {
  if (!timestamp) return '';

  if (timestamp.toDate) {
    timestamp = timestamp.toDate();
  }
  const localDate = new Date(timestamp.getTime());

  const hours = localDate.getHours().toString().padStart(2, '0');
  const minutes = localDate.getMinutes().toString().padStart(2, '0');
  // Always set seconds to 00
  const seconds = includeSeconds ? ':00' : '';

  return `${hours}:${minutes}${seconds}`; // Format to HH:MM:SS
};

export const convertToUTC = (date) => {
  if (!date) return null;

  if (date.toDate) {
    date = date.toDate();
  } else {
    date = new Date(date);
  }

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      0,
      0
    )
  );
};

export const convertToLocalTime = (utcDate, format) => {
  if (!utcDate) return null;

  if (utcDate.toDate) {
    utcDate = utcDate.toDate();
  }

  const localDate = new Date(utcDate.getTime());

  if (!format) {
    return localDate;
  }

  return localDate.toLocaleString('da-DK', format);
};
