import { useContext } from 'react';
import './dashboard-view.css';
import { UserInfoContext } from '../../context.js';
import { Panels, Panel, PanelGroup } from '../../components/panel/panel';
import PageTop from '../../components/page-top/page-top.jsx';
import DashboardAgreements from './dashboard-agreements.jsx';
import Button from '../../components/button/button.jsx';
import DashboardOnboardingOffboarding from './dashboard-onboarding-offboarding.jsx';
import DashboardTasks from './dashboard-tasks.jsx';
import DashboardOccasions from './dashboard-occasions.jsx';
import DashboardFirstEmployee from './dashboard-first-employee.jsx';
import DashboardTimeRegistrationTeaser from './dashboard-timeregistration-teaser.jsx';

export default function DashboardView() {
  const userInfo = useContext(UserInfoContext);

  return (
    <div className="main-layout">
      <PageTop title={`Velkommen ${userInfo?.firstName} 👋`} />
      <Panels>
        <PanelGroup columns={60}>
          <DashboardFirstEmployee />
          <DashboardTimeRegistrationTeaser />
          <Panel
            noPadding
            headerTop
            header={
              <div className="dashboard-panel-header">
                <h4>Aftaler</h4>
                <Button
                  href="/aftaler"
                  buttonStyle="text-link"
                  className="small"
                >
                  Se alle aftaler
                </Button>
              </div>
            }
          >
            <DashboardAgreements />
          </Panel>
          <Panel
            noPadding
            headerTop
            header={
              <div className="dashboard-panel-header">
                <h4>Igangværende On-/offboarding</h4>
                <Button
                  href="/onboarding-offboarding"
                  buttonStyle="text-link"
                  className="small"
                >
                  Se alle forløb
                </Button>
              </div>
            }
          >
            <DashboardOnboardingOffboarding />
          </Panel>

          <Panel noBox headerTop header={<h4>Forslag til opgaver</h4>}>
            <DashboardTasks />
          </Panel>
          {/* <Panel
          columns={60}
          noPadding
          headerTop
          header={
            <div className="dashboard-panel-header">
              <h4>Whistleblower</h4>
              <Button
                href="/whistleblower"
                buttonStyle="text-link"
                className="small"
              >
                Se alle sager
              </Button>
            </div>
          }
        ></Panel> */}
        </PanelGroup>
        <PanelGroup columns={40}>
          <Panel noBox headerTop header={<h4>Mærkedage</h4>}>
            <DashboardOccasions />
          </Panel>
        </PanelGroup>
      </Panels>
    </div>
  );
}
