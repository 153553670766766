import './panel.css';

export function Panels({ columns, sameHeight, noMargin, children }) {
  return (
    <div
      className={`panels ${sameHeight ? 'panels--same-height' : ''}
      ${noMargin ? 'panels--no-margin' : ''}
      `}
      style={
        columns && {
          flexBasis: `${columns - 2}%`,
          minWidth: `${columns - 2}%`,
          width: `${columns - 2}%`,
        }
      }
    >
      {children}
    </div>
  );
}

export function PanelGroup({ children, columns }) {
  return (
    <div
      className="panel-group"
      style={
        columns && {
          flexBasis: `${columns - 2}%`,
          minWidth: `${columns - 2}%`,
          width: `${columns - 2}%`,
        }
      }
    >
      {children}
    </div>
  );
}

export function Panel({
  columns,
  header,
  inlineHeader = false,
  headerTop = false,
  children,
  bottom,
  noPadding,
  smallPadding,
  noBox,
  highlighted,
  ...props
}) {
  return (
    <div
      className={`panel${noPadding ? ' panel--no-padding' : ''} 
        ${smallPadding ? 'panel--small-padding' : ''}
        ${noBox && 'panel--no-box'}
        ${headerTop && 'panel--header-top'}
        ${inlineHeader && 'panel--inline-header'}
        ${highlighted && `panel--highlighted panel--highlighted-${highlighted}`}
      `}
      style={
        columns && {
          flexBasis: `${columns - 2}%`,
          minWidth: `${columns - 2}%`,
          width: `${columns - 2}%`,
        }
      }
      {...props}
    >
      {header && !inlineHeader && <div className="panel__header">{header}</div>}
      <div className="panel__content">
        {header && inlineHeader && (
          <div className="panel__header">{header}</div>
        )}
        <div className="panel__middle">{children}</div>
        {bottom && <div className="panel__bottom">{bottom}</div>}
      </div>
    </div>
  );
}
