import { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import Button from '../button/button';
import Icon from '../../components/icon/icon';
import './file-upload.css';

const FileUpload = forwardRef(
  (
    {
      dragAndDrop = true,
      singleFile = false,
      label = 'Upload en fil',
      handleUpload,
      uploadImmediately = true,
    },
    ref
  ) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [dragActive, setDragActive] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(null);
    const inputRef = useRef(null);

    // Handle the upload process
    const processUpload = (files) => {
      if (!uploadImmediately) {
        // Just mark files for upload and do nothing else
        setSelectedFiles([...selectedFiles, ...files]);
        return;
      }

      setIsUploading(true); // Indicate that upload is in progress
      handleUpload(files) // Assuming handleUpload returns a Promise
        .then((success) => {
          setUploadSuccess(success); // true if upload succeeded, false otherwise
          setIsUploading(false); // Reset uploading indicator
        })
        .catch((error) => {
          console.error(error);
          setUploadSuccess(false); // Handle failure
          setIsUploading(false); // Reset uploading indicator
        });
    };

    // handle drag events
    const handleDrag = function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length) {
        processUpload(e.dataTransfer.files);
      }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
      e.preventDefault();
      if (e.target.files && e.target.files.length) {
        processUpload(e.target.files);
      }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
      inputRef.current.click();
    };

    // Use useImperativeHandle to expose selectedFiles and a manual upload method
    useImperativeHandle(ref, () => ({
      getSelectedFiles: () => selectedFiles,
      clearSelectedFiles: () => setSelectedFiles([]),
    }));

    return (
      <form
        className="file-upload"
        onDragEnter={dragAndDrop ? handleDrag : undefined}
        onSubmit={(e) => e.preventDefault()}
      >
        {selectedFiles.length > 0 && (
          <div className="file-upload-files">
            {selectedFiles.map((file, index) => {
              return (
                <div className="file-upload-file" key={index}>
                  <Icon name="description" />
                  <span>{file.name}</span>
                  <div
                    className="file-upload-file-remove"
                    onClick={() => {
                      setSelectedFiles(
                        selectedFiles.filter((_, i) => i !== index)
                      );
                    }}
                  >
                    <Icon name="close" />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <input
          ref={inputRef}
          className="file-upload-input"
          type="file"
          multiple={!singleFile}
          onChange={handleChange}
        />
        {dragAndDrop ? (
          <>
            <label
              className={`file-upload-label ${dragActive ? 'drag-active' : ''}`}
            >
              <div className="file-upload-label-inner">
                {isUploading ? (
                  <p>Uploader filer...</p>
                ) : (
                  <>
                    <p>Drag and drop din fil her eller</p>
                    <Button
                      className="small"
                      buttonStyle="text-link"
                      onClick={onButtonClick}
                    >
                      {label}
                    </Button>
                  </>
                )}
              </div>
            </label>
            {dragActive && (
              <div
                className="file-upload-drag"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </>
        ) : (
          <div className="upload-button" onClick={onButtonClick}>
            {label}
          </div>
        )}
        {uploadSuccess === false && (
          <p className="feedback">Der sket en fejl med upload. Prøv igen.</p>
        )}
      </form>
    );
  }
);

FileUpload.displayName = 'FileUpload';

export default FileUpload;
