import './page-top.css';

export default function PageTop({ title, description, actions, columns }) {
  return (
    <div
      className="page-top"
      style={
        columns && {
          flexBasis: `${columns - 2}%`,
          minWidth: `${columns - 2}%`,
          width: `${columns - 2}%`,
        }
      }
    >
      <div className="page-top__content">
        <h1>{title}</h1>
        {description && <p>{description}</p>}
      </div>
      <div className="page-top__actions">{actions}</div>
    </div>
  );
}
