import { useState, useEffect } from 'react';
import Button from '../../components/button/button';
import Logo from '../../components/logo/logo';
import { logout } from '../../utils/logout-function';

export default function NoActiveSubscriptionView() {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // After 2 seconds, show the content
    setTimeout(() => {
      setShowContent(true);
    }, 2000);
  }, []);

  if (!showContent) {
    return null;
  }

  return (
    <div className="centered-layout">
      <Logo />
      <div className="centered-layout__content">
        <div className="centered-layout__main" style={{ textAlign: 'center' }}>
          <h1>Aktivt abonnement mangler</h1>
          <p>Kontakt din HRfamly administrator for at få adgang til HRfamly.</p>
        </div>
      </div>
      <Button
        buttonStyle="text-link"
        style={{ marginTop: '30px' }}
        onClick={logout}
      >
        Log ud
      </Button>
    </div>
  );
}
