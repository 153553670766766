import { useState, useEffect } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase-config';
import PreLoader from '../../components/preloader/preloader';
import Button from '../../components/button/button';
import InputField from '../../components/input-field/input-field';
import Logo from '../../components/logo/logo';

export default function LoginView() {
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [user, setUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

  // Effect hook to check user login status and update states accordingly
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setIsLoggedIn(!!currentUser); // Update isLoggedIn state based on currentUser
      setIsLoading(false);
      setCurrentUser(currentUser);
    });

    // Unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  // Check if the app is still loading, show loading spinner while loading
  if (isLoading) {
    return <PreLoader />;
  }

  // Function to handle user login
  const login = async (e) => {
    e.preventDefault();

    try {
      // Sign in the user with email and password
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
    } catch (error) {
      // Handle different error cases and set appropriate error messages
      setEmailErrorMessage('');
      setPasswordErrorMessage('');
      setEmailErrorMessage('Fejl i email eller kodeord. Prøv igen.');
      setPasswordErrorMessage('Fejl i email eller kodeord. Prøv igen.');
    }
  };

  return (
    <div className="centered-layout">
      <Logo />
      <div className="centered-layout__content">
        <form className="centered-layout__form" onSubmit={login}>
          <h3>Log ind</h3>

          <InputField
            name="email"
            label="Email"
            type="email"
            placeholder="Skriv din email"
            onChange={(event) => {
              setLoginEmail(event.target.value);
            }}
            error={emailErrorMessage}
          />

          <InputField
            name="password"
            label="Kodeord"
            type="password"
            placeholder="Skriv dit kodeord"
            onChange={(event) => {
              setLoginPassword(event.target.value);
            }}
            error={passwordErrorMessage}
          />
          <Button type="submit">Log ind</Button>
          <Button
            buttonStyle="text-link"
            className="forgot-password-link"
            href="/forgot-password"
          >
            Glemt kodeord?
          </Button>
        </form>

        <div className="centered-layout__bottom">
          <h4>Har du ikke en bruger?</h4>
          <Button buttonStyle="text-link" href="/signup">
            Opret en bruger
          </Button>
        </div>
      </div>
    </div>
  );
}
