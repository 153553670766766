// const {
//   onpay_uuid, //f73f8e4c-48dc-11ef-9a48-0dd9ab50386c
//   onpay_number, //1033
//   onpay_reference, //EDa6Z8o98oGFhO6Sqqdw
//   onpay_amount, //0
//   onpay_currency, //208
//   onpay_method, //card
//   onpay_type, //subscription
//   onpay_3dsecure, //1
//   onpay_cardtype, //dankort
//   onpay_cardcountry, //208
//   onpay_acquirercode, //-5
//   onpay_errorcode, //1000
// } = searchParams;

const ONPAYPARAMSVALUES = {
  acquirerCode: {
    '-1': {
      code: -1,
      error: true,
      description: 'Communication error, acquirer may not be available',
    },
    '-2': {
      code: -2,
      error: true,
      description: 'Unknown error',
    },
    '-5': {
      code: -5,
      error: true,
      description: 'ACS denied, 3DSecure verification failed',
    },
    '000': {
      code: '000',
      error: false,
      description: 'Transaction approved. No action required',
    },
  },
  errorCode: {
    0: {
      code: 0,
      error: false,
      description: 'Transaction approved without issue',
    },
    1: {
      code: 1,
      error: true,
      description: 'Reference supplied is already used',
    },
    2: {
      code: 2,
      error: true,
      description: 'Wallet Service communication error',
    },
    3: {
      code: 3,
      error: true,
      description: 'Transaction cancelled or rejected',
    },
    1000: {
      code: 1000,
      error: true,
      description: 'Unknown error',
    },
  },
};

export default ONPAYPARAMSVALUES;
