import { useState } from 'react';
import './timeregistration-settings.css';
import InputField from '../../input-field/input-field';
import { Timestamp } from 'firebase/firestore';

const danishDays = [
  'Mandag',
  'Tirsdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lørdag',
  'Søndag',
];

export default function TimeLogSettings({
  settings,
  setSettings,
  isEmployee = false,
}) {
  const [localBreakDuration, setLocalBreakDuration] = useState(
    `${settings.breakDuration} min`
  );

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    const day = settings.weeklySchedule.find(
      (item) => item.day.toLowerCase() === name.split('.')[1].toLowerCase()
    )?.day;

    if (!day) return;

    let updatedSchedule = [...settings.weeklySchedule];

    if (type === 'time') {
      const fromOrEnd = name.split('.')[2];
      const updatedDay = updatedSchedule.find((item) => item.day === day);
      const originalTimestamp =
        fromOrEnd === 'from' ? updatedDay.from : updatedDay.end;
      const originalDate = originalTimestamp.toDate(); // Extract the original date

      // Create a new Date object with the original date
      const updatedDate = new Date(originalDate);
      updatedDate.setHours(new Date(value).getHours());
      updatedDate.setMinutes(new Date(value).getMinutes());
      updatedDate.setSeconds(0);
      updatedDate.setMilliseconds(0);

      if (fromOrEnd === 'from') {
        updatedDay.from = Timestamp.fromDate(updatedDate);
      } else if (fromOrEnd === 'end') {
        updatedDay.end = Timestamp.fromDate(updatedDate);
      }

      setSettings((prevSettings) => ({
        ...prevSettings,
        weeklySchedule: updatedSchedule,
      }));
    } else if (type === 'checkbox') {
      const checked = !settings.weeklySchedule.find(
        (item) => item.day.toLowerCase() === day.toLowerCase()
      )[name.split('.')[2]];

      setSettings({
        ...settings,
        weeklySchedule: settings.weeklySchedule.map((item) =>
          item.day === day ? { ...item, [name.split('.')[2]]: checked } : item
        ),
      });
    }
  };

  const handlePauseInputChange = (e) => {
    const { name, checked, value } = e.target;

    if (name === 'breakDuration') {
      let newValue = value.replace(/\D/g, '');
      if (newValue === '') {
        newValue = 0;
      }
      if (newValue === '0' || newValue === '00') {
        newValue = 0;
      }
      if (newValue.length > 2) {
        newValue = newValue.replace(/^0+/, '');
      }
      setLocalBreakDuration(`${newValue} min`);

      setSettings({
        ...settings,
        [name]: parseInt(value),
      });
    } else {
      setSettings({
        ...settings,
        [name]: checked,
      });
    }
  };

  const handleHasNormTimeChange = (e) => {
    const { name, checked } = e.target;

    setSettings({
      ...settings,
      [name]: checked,
    });
  };

  const calculateWorkTime = (
    notWorking,
    from,
    end,
    breakDuration,
    paidBreak
  ) => {
    if (notWorking) return '0t 0m';
    if (!from || !end) return '0t 0m';

    const fromTime = new Date(from.toDate());
    const endTime = new Date(end.toDate());

    const hours = endTime.getHours() - fromTime.getHours();
    const minutes = endTime.getMinutes() - fromTime.getMinutes();

    const breakTime = paidBreak ? 0 : breakDuration;

    const totalMinutes = hours * 60 + minutes - breakTime;

    if (totalMinutes < 0) {
      return '0t 0m';
    }

    if (isNaN(hours) || isNaN(minutes) || isNaN(breakTime)) {
      return '0t 0m';
    }

    return `${Math.floor(totalMinutes / 60)}t ${totalMinutes % 60}m`;
  };

  const calculateTotalWorkTime = (weeklySchedule) => {
    let totalMinutes = 0;

    weeklySchedule.forEach((day) => {
      if (day.notWorking) return;

      const fromTime = new Date(day.from.toDate());
      const endTime = new Date(day.end.toDate());

      const hours = endTime.getHours() - fromTime.getHours();
      const minutes = endTime.getMinutes() - fromTime.getMinutes();

      const breakTime = settings.paidBreak ? 0 : settings.breakDuration;

      totalMinutes += hours * 60 + minutes - breakTime;
    });

    if (totalMinutes < 0) {
      return '0t 0m';
    }

    return `${Math.floor(totalMinutes / 60)}t ${totalMinutes % 60}m`;
  };

  return (
    <div className="time-log-settings">
      <div className="time-log-settings-is-paid-hourly">
        <h5>Timelønnet</h5>
        <p className="small">
          Alle har en normtid som standard. Hvis medarbejder
          {isEmployee ? 'en' : 'ne'} er timelønnet, kan du ændre det her.
        </p>
        <InputField
          name="isPaidHourly"
          type="checkbox"
          label={`${isEmployee ? 'Er ' : 'Alle er'} timelønnet`}
          onChange={handleHasNormTimeChange}
          checked={settings.isPaidHourly}
          value={settings.isPaidHourly}
        />
      </div>
      {!settings.isPaidHourly && (
        <>
          <div className="time-log-settings-break">
            <h5>Pause</h5>
            <p className="small">
              {isEmployee
                ? 'Medarbejdere har denne indstilling som standard. Du kan overskrive den, ved at tilpasse for den enkelte medarbejder.'
                : 'Alle medarbejdere har denne indstilling som standard.'}
            </p>
            <div className="time-log-settings-break-fields">
              <InputField
                required
                label="Daglig pause"
                name="breakDuration"
                value={localBreakDuration}
                onChange={handlePauseInputChange}
                placeholder="Daglig pause"
                className="time-log-settings-break-input"
              />
              <InputField
                name="paidBreak"
                type="checkbox"
                label={`Betalt pause`}
                onChange={handlePauseInputChange}
                checked={settings.paidBreak}
                value={settings.paidBreak}
              />
            </div>
          </div>
          <div className="time-log-settings-days">
            {settings.weeklySchedule.map((day, index) => (
              <div
                className={`time-log-settings-days-item ${
                  day.notWorking
                    ? 'time-log-settings-days-item--not-working'
                    : ''
                }`}
                key={day.day}
              >
                <InputField
                  name={`weeklySchedule.${day.day.toLowerCase()}.notWorking`}
                  type="checkbox"
                  label={danishDays[index]}
                  onChange={handleChange}
                  checked={!settings.weeklySchedule[index].notWorking}
                  value={!settings.weeklySchedule[index].notWorking}
                />
                <InputField
                  required
                  label="Fra"
                  type="time"
                  name={`weeklySchedule.${day.day.toLowerCase()}.from`}
                  value={settings.weeklySchedule[index].from.toDate()}
                  onChange={handleChange}
                  placeholder="Fra"
                />
                <InputField
                  required
                  label="Til"
                  type="time"
                  name={`weeklySchedule.${day.day.toLowerCase()}.end`}
                  value={settings.weeklySchedule[index].end.toDate()}
                  onChange={handleChange}
                  placeholder="Til"
                />
                <InputField
                  readOnly
                  label="Arbejdstid"
                  name={`weeklySchedule.${day.day.toLowerCase()}.workTime`}
                  value={calculateWorkTime(
                    settings.weeklySchedule[index].notWorking,
                    settings.weeklySchedule[index].from,
                    settings.weeklySchedule[index].end,
                    settings.breakDuration,
                    settings.paidBreak
                  )}
                  placeholder="Arbejdstid"
                />
              </div>
            ))}
            <div className="time-log-settings-days-total">
              <InputField
                readOnly
                label="Arbejdstid total"
                name="totalWorkTime"
                value={calculateTotalWorkTime(settings.weeklySchedule)}
                placeholder="Arbejdstid total"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
