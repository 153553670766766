import './timeregistration-top.css';

function TimeRegistrationTop({ title, filters }) {
  return (
    <div className="timeregistration-top">
      <h4>{title}</h4>
      <div className="timeregistration-filters">{filters}</div>
    </div>
  );
}

export default TimeRegistrationTop;
