import { useState } from 'react';
import { Link } from 'react-router-dom';
import './list.css';
import Icon from '../icon/icon';
import InputField from '../input-field/input-field';

export default function List({
  head,
  items,
  filters,
  actions,
  showSearch,
  noResultsMessage = 'Ingen resultater',
  maxHeight,
  inlineBackground = false,
  hideListActions = false,
  className = '',
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterConfig, setFilterConfig] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  // Handler to update filter state
  const handleFilterChange = (filterLabel, selectedValue) => {
    setFilterConfig((prevConfig) => ({
      ...prevConfig,
      [filterLabel]: selectedValue,
    }));
  };

  // Apply search filter
  const searchFilteredItems = items.filter((item) => {
    if (!searchTerm) return true;
    if (!item.filterData) {
      return false;
    }
    return Object.values(item.filterData).some((value) => {
      // Check if value is not undefined before converting to string
      if (value !== undefined && value !== null) {
        return value
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      }
      return false;
    });
  });

  // Apply other filters
  const filteredItems = searchFilteredItems.filter((item) => {
    return Object.entries(filterConfig).every(
      ([key, value]) => !value || item.filterData[key] === value
    );
  });

  // Apply sorting
  const sortedItems = [...filteredItems].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const aValue = a.filterData[sortConfig.key];
    const bValue = b.filterData[sortConfig.key];
    if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
    return 0;
  });

  // Handler to update sort state
  const handleSortChange = (label) => {
    setSortConfig((prevConfig) => ({
      key: label,
      direction:
        prevConfig.key === label && prevConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending',
    }));
  };

  const showActionBar =
    (showSearch || (filters && filters.length) || actions) && true;

  return (
    <div
      className={`list ${className} ${
        inlineBackground && 'list--inline-background'
      }`}
    >
      {!hideListActions && (
        <div className="list-actions">
          {showSearch && (
            <InputField
              label="Søg..."
              type="text"
              name="search"
              placeholder="Søg..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          )}
          {filters && (
            <div className="list-actions__filters">
              {filters.map((filter) => {
                if (filter.type === 'checkbox') {
                  return (
                    <InputField
                      key={filter.label}
                      label={filter.label}
                      type="checkbox"
                      name={filter.label.toLowerCase()}
                      onChange={(e) => {
                        handleFilterChange(filter.label, e.target.checked);

                        if (filter.onChange) {
                          filter.onChange(e.target.checked);
                        }
                      }}
                    />
                  );
                }

                // Default to select
                return (
                  <InputField
                    key={filter.label}
                    label={filter.label}
                    type="select"
                    name={filter.label.toLowerCase()}
                    options={filter.options}
                    placeholder="- Vælg -"
                    onChange={(e) =>
                      handleFilterChange(filter.label, e.target.value)
                    }
                  />
                );
              })}
            </div>
          )}
          {actions && <div className="list-actions__actions">{actions}</div>}
        </div>
      )}
      <div className="list-rows" style={{ maxHeight: maxHeight }}>
        {head && (
          <div
            className={`list-row list-row--head ${
              !showActionBar && 'list-row--head-no-border'
            }`}
          >
            {head.map(({ label, sortable, flex, align }, index) => (
              <div
                key={index}
                onClick={() => sortable && handleSortChange(label)}
                className={`list-row__item${
                  sortConfig.key === label
                    ? ` list-row__item--${sortConfig.direction}`
                    : ''
                }${flex ? ` list-row__item--flex-${flex}` : ''}${
                  align ? ` list-row__item--align-${align}` : ''
                }`}
              >
                {label}
                {sortable && (
                  <Icon
                    name={
                      sortConfig.direction === 'ascending'
                        ? 'arrow_drop_down'
                        : 'arrow_drop_up'
                    }
                  />
                )}
              </div>
            ))}
          </div>
        )}
        {sortedItems.map((item, index) => {
          const Element = item.link ? Link : 'div';

          return (
            <Element
              key={index}
              to={item.link}
              target={
                // If the link is an external link, open in new tab
                item.link && item.link.startsWith('http') ? '_blank' : ''
              }
              className={`list-row ${
                item.className ? `${item.className}` : ''
              }`}
              {...(item.onClick && !item.link && { onClick: item.onClick })}
            >
              {item.fields.map((field, index) => {
                return (
                  <div
                    className={`${
                      field.flex ? `list-row__item--flex-${field.flex}` : ''
                    }${
                      field.align ? ` list-row__item--align-${field.align}` : ''
                    }`}
                    key={index}
                  >
                    {field.value}
                  </div>
                );
              })}
            </Element>
          );
        })}
        {!sortedItems.length && (
          <div className="list-row list-row--empty">
            <div>{noResultsMessage}</div>
          </div>
        )}
      </div>
    </div>
  );
}
