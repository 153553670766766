import { Panels } from '../../components/panel/panel';
import PageTop from '../../components/page-top/page-top';
import './subscription-view.css';
import { useState, useContext, useEffect } from 'react';
import { UserInfoContext } from '../../context';
import { db } from '../../firebase-config';
import { collection, getDocs, where, query, orderBy } from 'firebase/firestore';

import SubscriptionTransactions from './subscription-transactions';
import SubscriptionPlan from './subscription-plan';
import SubscriptionAddresses from './subscription-addresses';

export default function SubscriptionView() {
  const userInfo = useContext(UserInfoContext);
  const [transactions, setTransactions] = useState([]);

  // Fetch data from Firestore
  useEffect(() => {
    // Fetch transactions data
    const fetchTransactions = async () => {
      if (userInfo?.companyId) {
        const transactionRef = collection(db, 'transactions');
        const q = query(
          transactionRef,
          where('companyId', '==', userInfo.companyId),
          orderBy('date_created', 'desc')
        );
        const querySnapshot = await getDocs(q);

        const fetchPromises = querySnapshot.docs
          .filter(
            (transactionItem) => transactionItem.data().status === 'completed'
          )
          .map(async (transactionItem) => {
            return {
              id: transactionItem.id,
              data: transactionItem.data(),
            };
          });

        const resolvedTransactions = await Promise.all(fetchPromises);

        setTransactions(resolvedTransactions);
      }
    };

    fetchTransactions();
  }, [userInfo]);

  return (
    <div className="main-layout">
      <PageTop title="Abonnement" />
      <Panels>
        <SubscriptionPlan />
        <SubscriptionAddresses />
        <SubscriptionTransactions transactions={transactions} />
      </Panels>
    </div>
  );
}
