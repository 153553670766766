const PLANS = {
  FREEMIUM: {
    name: 'freemium',
    label: 'Freemium',
    price: 0,
    freeAdmins: 0,
  },
  PREMIUM: {
    name: 'premium',
    label: 'Premium',
    price: 50000,
    freeAdmins: 1,
  },
  PREMIUMPLUS: {
    name: 'premiumplus',
    label: 'Premium+',
    price: 250000,
    freeAdmins: 2,
  },
};

export default PLANS;
