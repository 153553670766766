import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import ChecklistGroup from './checklist-group';
import Icon from '../../components/icon/icon';
import Button from '../../components/button/button';
import ChecklistAddGroup from './checklist-add-group';
import './checklist.css';

export default function Checklist({
  data,
  handleCheck,
  updateChecklist,
  addGroup,
  updateGroup,
  deleteGroup,
  addTask,
  updateTask,
  deleteTask,
}) {
  const [addGroupMode, setAddGroupMode] = useState(false);
  const [localGroups, setLocalGroups] = useState(data.groups);

  // Function to help reorder the subtasks after drag ends
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // Handle drag end
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedGroups = reorder(
      localGroups,
      result.source.index,
      result.destination.index
    );

    setLocalGroups(reorderedGroups);

    updateChecklist({
      ...data,
      groups: reorderedGroups,
    });
  };

  if (!data) {
    return null;
  }

  return (
    <div className="checklist-wrapper">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={`checklist`}
          renderClone={(provided, snapshot, rubric) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              style={{
                userSelect: 'none',
                backgroundColor: snapshot.isDragging ? '#f5f7f3' : '#fff',
                ...provided.draggableProps.style,
              }}
            >
              <ChecklistGroup
                isClone
                group={localGroups[rubric.source.index]}
              />
            </div>
          )}
        >
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="checklist"
            >
              {localGroups.map((group, index) => (
                <Draggable key={group.id} draggableId={group.id} index={index}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <ChecklistGroup
                        key={group.id}
                        groupId={group.id}
                        group={group}
                        updateGroup={updateGroup}
                        deleteGroup={deleteGroup}
                        handleCheck={handleCheck}
                        addTask={addTask}
                        updateTask={updateTask}
                        deleteTask={deleteTask}
                        {...provided.draggableProps}
                        dragHandle={provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {!addGroupMode && (
        <div className="checklist-group-add">
          <Button buttonStyle="text-link" onClick={() => setAddGroupMode(true)}>
            <Icon name={'add'} /> Tilføj gruppe
          </Button>
        </div>
      )}
      {addGroupMode && (
        <ChecklistAddGroup
          addGroup={addGroup}
          handleClose={() => setAddGroupMode(false)}
        />
      )}
    </div>
  );
}
