import { useState } from 'react';
import Icon from '../../components/icon/icon';
import Button from '../../components/button/button';

export default function RecruitmentContentServiceItem({
  service,
  plan,
  contactLink,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleReadMore = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="recruitment-content-panel-services-list-item">
      <div className="recruitment-content-panel-services-list-item-content">
        <div className="recruitment-content-panel-services-list-item-content-icon">
          <Icon name={service.icon} />
        </div>
        <div
          className={`recruitment-content-panel-services-list-item-content__inner
          ${
            expanded
              ? 'recruitment-content-panel-services-list-item-content__inner--expanded'
              : ''
          }`}
        >
          <h4>{service.title}</h4>
          <p dangerouslySetInnerHTML={{ __html: service.description }} />

          {service.expandedDescription && (
            <>
              <p
                className="recruitment-content-panel-services-list-item-content__expanded-description"
                dangerouslySetInnerHTML={{
                  __html: service.expandedDescription,
                }}
              />
              <button
                className="recruitment-content-panel-services-list-item-content__read-more"
                onClick={handleReadMore}
              >
                {expanded ? 'Læs mindre' : 'Læs mere'}
                <svg
                  className={`recruitment-content-panel-services-list-item-content__read-more-icon ${
                    expanded
                      ? 'recruitment-content-panel-services-list-item-content__read-more-icon--expanded'
                      : ''
                  }`}
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.42188L4.29289 4.71477C4.68342 5.10529 5.31658 5.10529 5.70711 4.71477L9 1.42188"
                    stroke="#3D8545"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            </>
          )}
        </div>
      </div>
      <div className="recruitment-content-panel-services-list-item-price">
        {plan === 'freemium' ? (
          <>
            <Button
              href={contactLink}
              target="_blank"
              className="small"
              buttonStyle="text-link"
            >
              Kontakt os for pris
            </Button>
            {service.priceDescription && (
              <span className="recruitment-content-panel-services-list-item-price__description">
                {service.priceDescription}
              </span>
            )}
          </>
        ) : (
          <>
            <span className="recruitment-content-panel-services-list-item-price__actual">
              {plan === 'premiumplus'
                ? service.plusPrice.toLocaleString('da-DK')
                : service.price.toLocaleString('da-DK')}
              ,-
            </span>

            {plan === 'premiumplus' && (
              <span className="recruitment-content-panel-services-list-item-price__discount">
                <span className="recruitment-content-panel-services-list-item-price__discount-percent">
                  {((service.price - service.plusPrice) / service.price) * 100}%
                </span>
                <span className="recruitment-content-panel-services-list-item-price__discount-before">
                  {service.price.toLocaleString('da-DK')},-
                </span>
              </span>
            )}
            {service.priceDescription && (
              <span className="recruitment-content-panel-services-list-item-price__description">
                {service.priceDescription}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
}
