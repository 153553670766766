import { createContext } from 'react';

export const UserContext = createContext();
export const UserInfoContext = createContext();
export const SubscriptionContext = createContext({
  onPaySubscription: null,
  subscription: null,
});
export const EmployeesContext = createContext();
export const EquipmentContext = createContext({
  equipmentTypes: [],
  equipmentVariants: [],
});
