import { useCallback, useState, useEffect, useRef } from 'react';
import './emoji-picker.css';
import 'emoji-picker-element';

const da = {
  categories: {
    custom: 'Custom',
    'smileys-emotion': 'Smileys and emoticons',
    'people-body': 'People and body',
    'animals-nature': 'Animals and nature',
    'food-drink': 'Food and drink',
    'travel-places': 'Travel and places',
    activities: 'Activities',
    objects: 'Objects',
    symbols: 'Symbols',
    flags: 'Flags',
  },
  categoriesLabel: 'Categories',
  emojiUnsupportedMessage: 'Your browser does not support color emoji.',
  favoritesLabel: 'Favorites',
  loadingMessage: 'Loading…',
  networkErrorMessage: 'Could not load emoji.',
  regionLabel: 'Emoji picker',
  searchDescription:
    'When search results are available, press up or down to select and enter to choose.',
  searchLabel: 'Søg',
  searchResultsLabel: 'Search results',
  skinToneDescription:
    'When expanded, press up or down to select and enter to choose.',
  skinToneLabel: 'Choose a skin tone (currently {skinTone})',
  skinTones: [
    'Default',
    'Light',
    'Medium-Light',
    'Medium',
    'Medium-Dark',
    'Dark',
  ],
  skinTonesLabel: 'Skin tones',
};

export default function EmojiPicker({ value = '', onSelect }) {
  const [selectedEmoji, setSelectedEmoji] = useState(value);
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);

  const handleShowPicker = useCallback((event) => {
    const pickerElement = pickerRef.current;
    if (event === undefined) {
      setShowPicker(false);
      // add class to emoji picker to show/hide it
      pickerElement.classList.remove('visible');
    } else {
      setShowPicker(true);
      // add class to emoji picker to show/hide it
      pickerElement.classList.add('visible');
    }
  }, []);

  useEffect(() => {
    const handleEmojiClick = (event) => {
      onSelect(event.detail.unicode ?? '');
      setSelectedEmoji(event.detail.unicode ?? '');
      handleShowPicker();
    };

    const pickerElement = pickerRef.current;
    if (pickerElement != null) {
      // Apply i18n configuration directly to the element
      pickerElement.i18n = da;

      // Add the event listener
      pickerElement.addEventListener('emoji-click', handleEmojiClick);

      // Set additional properties or styles if needed
      pickerElement.setAttribute('skin-tone-emoji', '👌');
      pickerElement.classList.add('light');
      pickerElement.classList.add('emoji-picker');

      // Add custom stylesheet to shadowRoot
      const style = document.createElement('style');
      style.textContent = `
      .picker {
        border-radius: var(--border-radius-bubble);
        box-shadow: var(--shadow-2);
      }
      `;

      pickerElement.shadowRoot.appendChild(style);
    }

    // Cleanup function to remove the event listener
    return () => {
      if (pickerElement != null) {
        pickerElement.removeEventListener('emoji-click', handleEmojiClick);
      }
    };
    // Ensure the effect runs only once unless onSelect changes
  }, [onSelect, handleShowPicker]);

  return (
    <div className="emoji-picker-wrapper">
      <span className="emoji-picker-selected" onClick={handleShowPicker}>
        {selectedEmoji}
      </span>
      <div
        className={`emoji-picker-backdrop ${
          showPicker ? 'emoji-picker-backdrop--visible' : ''
        }`}
        onClick={handleShowPicker}
      >
        <emoji-picker ref={pickerRef} />
      </div>
    </div>
  );
}
