import { Link } from 'react-router-dom';
import './button.css';

export default function Button({
  href,
  to,
  buttonStyle = 'primary',
  className = '',
  children,
  ...props
}) {
  if (href || to) {
    return (
      <Link
        className={`button ${buttonStyle} ${className}`}
        to={href}
        {...props}
      >
        {children}
      </Link>
    );
  }

  return (
    <button className={`button ${buttonStyle} ${className}`} {...props}>
      {children}
    </button>
  );
}
