import { useEffect, useState } from 'react';
import Button from '../../components/button/button';
import InputField from '../../components/input-field/input-field';
import Icon from '../../components/icon/icon';
import './checklist-group.css';

export default function ChecklistAddGroup({
  addGroup,
  handleClose,
  className = '',
}) {
  const [addGroupValues, setAddGroupValues] = useState({
    id: crypto.randomUUID(),
    tasks: [],
    title: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // If the input is a textarea, set the height of the textarea to fit the content
    if (event.target.tagName === 'TEXTAREA') {
      event.target.style.height = 'auto';
      event.target.style.height = `${event.target.scrollHeight}px`;
    }

    setAddGroupValues({ ...addGroupValues, [name]: value });
  };

  const handleAddGroup = () => {
    if (!addGroupValues.title.trim()) return;

    addGroup(addGroupValues);
    setAddGroupValues({
      id: crypto.randomUUID(),
      tasks: [],
      title: '',
    });
  };

  // Set focus on title input when component mounts
  useEffect(() => {
    const titleInput = document.querySelector(
      '.checklist-add-group__title input'
    );
    titleInput.focus();
  }, []);

  return (
    <div className={`checklist-add-group ${className}`}>
      <InputField
        className={`checklist-add-group__title`}
        label="Gruppenavn"
        type="text"
        name="title"
        value={addGroupValues.title || ''}
        placeholder="Titel..."
        onChange={handleInputChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            handleAddGroup();
          }
        }}
      />
      <div className={`checklist-add-group__buttons`}>
        <Button buttonStyle="text-link" className="small" onClick={handleClose}>
          Luk
        </Button>
        <Button
          disabled={addGroupValues.title.trim() === ''}
          className="small"
          onClick={handleAddGroup}
        >
          Gem
        </Button>
      </div>
    </div>
  );
}
