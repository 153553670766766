import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { applyActionCode, verifyPasswordResetCode } from 'firebase/auth';
import { auth } from '../../firebase-config';
import Logo from '../../components/logo/logo';
import Button from '../../components/button/button';
import ResetPasswordForm from './reset-password-form';

export default function EmailActionHandlerView() {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  const actionCode = searchParams.get('oobCode');
  const [title, setTitle] = useState('Behandler...');
  const [text, setText] = useState('');
  const [error, setError] = useState(null);
  const [actionAttempted, setActionAttempted] = useState(false);
  const [displayResetPasswordForm, setDisplayResetPasswordForm] =
    useState(false);
  const [accountEmail, setAccountEmail] = useState('');

  useEffect(() => {
    if (actionAttempted) return;
    switch (mode) {
      case 'verifyEmail':
        applyActionCode(auth, actionCode)
          .then(() => {
            // Email verified successfully
            setTitle('Din e-mail er verificeret');
            setText(
              'Du kan nu færdiggøre din profil, ved at trykke på knappen nedenfor.'
            );
            setActionAttempted(true);
          })
          .catch((error) => {
            if (!actionAttempted) {
              setError(error);
              console.error(error);
            }
          });
        break;
      case 'resetPassword':
        verifyPasswordResetCode(auth, actionCode).then((email) => {
          setAccountEmail(email);
          setDisplayResetPasswordForm(true);
          setTitle('Nulstil dit kodeord');
          setText('Indtast dit nye kodeord og tryk på knappen nedenfor.');
          setActionAttempted(true);
        });
        break;
      // Handle other cases (recoverEmail, etc.)
      default:
        // Handle error or unknown mode
        break;
    }
  }, [mode, actionCode, actionAttempted]);

  // Render component based on the mode or a generic message
  return (
    <div className="centered-layout">
      <Logo />
      <div className="centered-layout__content">
        <div className="centered-layout__main">
          <h3>{title}</h3>
          <p>{text}</p>
          {displayResetPasswordForm && (
            <ResetPasswordForm
              actionCode={actionCode}
              accountEmail={accountEmail}
            />
          )}
          {error && !actionAttempted && <p>{error.message}</p>}
        </div>
        <div className="centered-layout__bottom">
          <Button
            buttonStyle="text-link"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            {!error && mode === 'verifyEmail'
              ? 'Færdiggør profil'
              : 'Tilbage til forsiden'}
          </Button>
        </div>
      </div>
    </div>
  );
}
