import './infobox.css';
import { Link } from 'react-router-dom';

export default function InfoBox({ link, type, size, children, style }) {
  if (link) {
    return (
      <Link
        to={link}
        className={
          `infobox` +
          (type ? ` infobox--type-${type}` : '') +
          (size ? ` infobox--size-${size}` : '')
        }
        style={style}
      >
        <div className="infobox-inner">{children}</div>
      </Link>
    );
  }

  return (
    <div
      className={
        `infobox` +
        (type ? ` infobox--type-${type}` : '') +
        (size ? ` infobox--size-${size}` : '')
      }
      style={style}
    >
      <div className="infobox-inner">{children}</div>
    </div>
  );
}
