import './empty-state.css';

export default function EmptyState({ title, description, image, children }) {
  return (
    <div className="empty-state">
      <div className="empty-state-content">
        <h3 className="empty-state-content__title">{title}</h3>
        <p className="empty-state-content__description">{description}</p>
        <div className="empty-state-content__children">{children}</div>
      </div>
      <div className="empty-state__image">
        {image && <img src={image} alt="" />}
      </div>
    </div>
  );
}
