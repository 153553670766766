import { useState, useCallback, useRef } from 'react';
import { Panels, Panel } from '../../components/panel/panel';
import Button from '../../components/button/button';
import InputField from '../../components/input-field/input-field';
import './employee-settings.css';
import TimeLogSettings from '../../components/timeregistration/settings/timeregistration-settings';
import { db, firebaseConfig } from '../../firebase-config';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { initializeApp, deleteApp } from 'firebase/app';
import {
  getAuth,
  signInWithCustomToken,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { getDefaultWeeklySchedule } from '../../views/timeregistration/timeregistration-utils';
import TimeRegistrationIntroductionModal from '../../components/timeregistration/introduction-modal/timeregistration-introduction-modal';

export default function EmployeeSettings({
  user,
  initialValues,
  setInitialValues,
  employeeId,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [formFeedback, setFormFeedback] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employeeSettingsObject, setEmployeeSettingsObject] = useState({
    agreements: {
      exemptFromEPR: initialValues.settings?.agreements?.exemptFromEPR || false,
      exemptFrom1to1:
        initialValues.settings?.agreements?.exemptFrom1to1 || false,
      exemptFromSalary:
        initialValues.settings?.agreements?.exemptFromSalary || false,
    },
    documents: {
      exemptFromEmploymentContract:
        initialValues.settings?.documents?.exemptFromEmploymentContract ||
        false,
    },
    mobileApp: {
      access: initialValues.settings?.mobileApp?.access || false,
    },
    timeRegistration: {
      exempt: initialValues.settings?.timeRegistration?.exempt || false,
      individualTimeLogSettings:
        initialValues.settings?.timeRegistration?.individualTimeLogSettings ||
        false,
    },
    role: initialValues.settings?.role || 'employee',
  });

  const [employeeTimeLogSettings, setEmployeeTimeLogSettings] = useState(
    (initialValues.timeLogSettings?.length > 0 &&
      initialValues.timeLogSettings[
        initialValues.timeLogSettings.length - 1
      ]) || {
      breakDuration: 30,
      createdDate: Timestamp.now(),
      paidBreak: false,
      isPaidHourly: false,
      weeklySchedule: getDefaultWeeklySchedule(),
    }
  );

  // Store initial value of mobileApp.access for comparison when saving
  const initialMobileAccess = useRef(
    initialValues.settings?.mobileApp?.access || false
  );

  const handleCheckboxChange = useCallback((event) => {
    const { name, checked } = event.target;
    const [category, subCategory] = name.split('.');
    setEmployeeSettingsObject((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [subCategory]: checked,
      },
    }));
  }, []);

  const handleSubmit = useCallback(async () => {
    if (submitting) return;

    setSubmitting(true);
    setFormFeedback('Gemmer...');

    // Save createdDate at midnight UTC
    const createdDateAtMidnight = new Date();
    createdDateAtMidnight.setUTCHours(0, 0, 0, 0);
    const createdDate = Timestamp.fromDate(createdDateAtMidnight);

    const valuesToSave = {
      settings: {
        agreements: { ...employeeSettingsObject.agreements },
        documents: { ...employeeSettingsObject.documents },
        mobileApp: { ...employeeSettingsObject.mobileApp },
        timeRegistration: { ...employeeSettingsObject.timeRegistration },
        role: employeeSettingsObject.role,
      },
      timeLogSettings: [
        ...(initialValues?.timeLogSettings || []),
        {
          ...employeeTimeLogSettings,
          createdDate,
        },
      ],
    };

    const employeeDocRef = doc(db, 'users', employeeId);

    try {
      await setDoc(employeeDocRef, valuesToSave, { merge: true });

      if (
        employeeSettingsObject.mobileApp.access !== initialMobileAccess.current
      ) {
        const mobileaccessResponse = await fetch('/api/user/mobileaccess', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-user-uuid': user.uid,
          },
          body: JSON.stringify({
            employeeId,
            mobileAccess: valuesToSave.settings.mobileApp.access,
          }),
        });

        const result = await mobileaccessResponse.json();

        if (result.error) {
          throw new Error('Error updating mobile access');
        }

        if (result.token) {
          try {
            const tempApp = initializeApp(firebaseConfig, 'tempApp');
            const tempAuth = getAuth(tempApp);
            await signInWithCustomToken(tempAuth, result.token).then(
              async (userCredential) => {
                if (!userCredential.user.emailVerified) {
                  await sendPasswordResetEmail(
                    tempAuth,
                    userCredential.user.email
                  );
                }
                await tempAuth.signOut();
              }
            );
            deleteApp(tempApp);
          } catch (error) {
            console.error('Error sending email verification:', error);
          }
        }
      }

      setInitialValues((prev) => ({
        ...prev,
        settings: valuesToSave.settings,
        timeLogSettings: valuesToSave.timeLogSettings,
      }));

      setFormFeedback('Indstillingerne er gemt.');
      setSubmitting(false);
    } catch (error) {
      setFormFeedback('Der skete en fejl. Prøv igen senere.');
      console.error('Error updating profile:', error);
      setSubmitting(false);
    }
  }, [
    user,
    employeeId,
    employeeSettingsObject,
    employeeTimeLogSettings,
    initialValues,
    setInitialValues,
    submitting,
  ]);

  return (
    <Panels>
      <Panel
        header={
          <>
            <h4>Tidsregistrering</h4>
            <p>
              Medarbejdere har denne normtid som standard. Du kan overskrive
              den, ved at tilpasse for den enkelte medarbejder.
            </p>
            <Button
              buttonStyle="text-link"
              className="small"
              onClick={() => setIsModalOpen(true)}
              style={{ marginTop: '2rem' }}
            >
              Sådan bliver medarbejdere introduceret
            </Button>
          </>
        }
      >
        <InputField
          name="mobileApp.access"
          type="checkbox"
          label={`Aktivér tidsregistrering - Koster 10 kr. pr. måned.`}
          onChange={handleCheckboxChange}
          checked={employeeSettingsObject.mobileApp.access}
          value={employeeSettingsObject.mobileApp.access}
        />
        {employeeSettingsObject.mobileApp.access && (
          <InputField
            name="timeRegistration.exempt"
            type="checkbox"
            label={`Fritaget for tidsregistrering`}
            onChange={handleCheckboxChange}
            checked={employeeSettingsObject.timeRegistration.exempt}
            value={employeeSettingsObject.timeRegistration.exempt}
          />
        )}
        {employeeSettingsObject.mobileApp.access &&
          !employeeSettingsObject.timeRegistration.exempt && (
            <InputField
              name="timeRegistration.individualTimeLogSettings"
              type="checkbox"
              label={`Individuelle tidsregistreringsindstillinger`}
              onChange={handleCheckboxChange}
              checked={
                employeeSettingsObject.timeRegistration
                  .individualTimeLogSettings
              }
              value={
                employeeSettingsObject.timeRegistration
                  .individualTimeLogSettings
              }
            />
          )}
        {employeeSettingsObject.mobileApp.access &&
          !employeeSettingsObject.timeRegistration.exempt &&
          employeeSettingsObject.timeRegistration.individualTimeLogSettings && (
            <TimeLogSettings
              settings={employeeTimeLogSettings}
              setSettings={setEmployeeTimeLogSettings}
              isEmployee
            />
          )}
      </Panel>
      <TimeRegistrationIntroductionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Panel
        header={
          <>
            <h4>Vigtige aftaler</h4>
            <p>
              Indstil om medarbejderen skal have vigtige aftaler, som du får
              påmindelser om at oprette.
            </p>
          </>
        }
      >
        <div className="employee-settings-checkboxes">
          <InputField
            name="agreements.exemptFromEPR"
            type="checkbox"
            label={`Fritaget for udviklingssamtale`}
            onChange={handleCheckboxChange}
            checked={employeeSettingsObject.agreements.exemptFromEPR}
            value={employeeSettingsObject.agreements.exemptFromEPR}
          />
          <InputField
            name="agreements.exemptFrom1to1"
            type="checkbox"
            label={`Fritaget for 1-til-1 samtaler`}
            onChange={handleCheckboxChange}
            checked={employeeSettingsObject.agreements.exemptFrom1to1}
            value={employeeSettingsObject.agreements.exemptFrom1to1}
          />
          <InputField
            name="agreements.exemptFromSalary"
            type="checkbox"
            label={`Fritaget for lønsamtaler`}
            onChange={handleCheckboxChange}
            checked={employeeSettingsObject.agreements.exemptFromSalary}
            value={employeeSettingsObject.agreements.exemptFromSalary}
          />
        </div>
      </Panel>
      <Panel
        header={
          <>
            <h4>Vigtige dokumenter</h4>
            <p>
              Indstil om medarbejderen skal have ansættelseskontrakt i HRfamly.
            </p>
          </>
        }
      >
        <div className="employee-settings-checkboxes">
          <InputField
            name="documents.exemptFromEmploymentContract"
            type="checkbox"
            label={`Fritaget for ansættelseskontrakt`}
            onChange={handleCheckboxChange}
            checked={
              employeeSettingsObject.documents.exemptFromEmploymentContract
            }
            value={
              employeeSettingsObject.documents.exemptFromEmploymentContract
            }
          />
        </div>
      </Panel>

      <Panel header={<></>} noBox>
        <div className="employee-settings-actions">
          <Button disabled={submitting} onClick={handleSubmit}>
            Gem indstillinger
          </Button>
          {formFeedback && (
            <span className="form-actions__feedback">{formFeedback}</span>
          )}
        </div>
      </Panel>
    </Panels>
  );
}
