import { useState, useEffect, useRef, useContext } from 'react';
import { UserInfoContext, EmployeesContext } from '../../context';
import Avatar from '../../components/avatar/avatar';
import { Panel, Panels } from '../../components/panel/panel';

const isDayToday = (date) => {
  const today = new Date();

  const todayDay = today.getDate();
  const todayMonth = today.getMonth();

  const targetDay = date.getDate();
  const targetMonth = date.getMonth();

  return todayDay === targetDay && todayMonth === targetMonth;
};

const isDayInNext30Days = (date) => {
  const today = new Date();

  const todayDay = today.getDate();
  const todayMonth = today.getMonth();

  const targetDay = date.getDate();
  const targetMonth = date.getMonth();

  return (
    todayMonth === targetMonth &&
    targetDay - todayDay <= 30 &&
    targetDay - todayDay > 0
  );
};

export default function DashboardOccasions() {
  const isInitialLoad = useRef(true);
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);
  const [occasions, setOccasions] = useState([]);

  // Prepare occasions based on employee data
  useEffect(() => {
    const fetchOccasions = async () => {
      if (employees.length !== 0 && userInfo?.companyId) {
        const occasionsData = [];

        // Run through all employees and create occasions based on their data
        employees.forEach((employee) => {
          if (!employee.data) return;

          if (!employee.data.dateOfBirth || !employee.data.startDate) return;

          const dateOfBirth = new Date(employee?.data?.dateOfBirth?.toDate());
          const startDate = new Date(employee?.data?.startDate?.toDate());

          // If the employee has a birthday today, create an occasion -- If the birthday is in the next 30 days, create an occasion
          if (isDayToday(dateOfBirth)) {
            occasionsData.push({
              link: `/employees/${employee.id}`,
              title: `${employee.data.firstName} ${employee.data.lastName} har fødselsdag 🇩🇰`,
              text: `${employee.data.firstName} fylder ${
                new Date().getFullYear() - dateOfBirth.getFullYear()
              } år i dag`,
              avatar: (
                <Avatar
                  firstName={employee.data.firstName}
                  lastName={employee.data.lastName}
                  profilePhotoUrl={employee.data.profilePhotoUrl}
                />
              ),
            });
          } else if (isDayInNext30Days(dateOfBirth)) {
            occasionsData.push({
              link: `/employees/${employee.id}`,
              title: `${employee.data.firstName} ${employee.data.lastName} har snart fødselsdag`,
              text: `${employee.data.firstName} fylder ${
                new Date().getFullYear() - dateOfBirth.getFullYear()
              } år
              
              ${
                dateOfBirth.getDate() - new Date().getDate() === 1
                  ? `i morgen`
                  : `om ${dateOfBirth.getDate() - new Date().getDate()} dage`
              }`,
              avatar: (
                <Avatar
                  firstName={employee.data.firstName}
                  lastName={employee.data.lastName}
                  profilePhotoUrl={employee.data.profilePhotoUrl}
                />
              ),
            });
          }

          // If the employee has a work anniversary today, create an occasion
          if (isDayToday(startDate)) {
            occasionsData.push({
              link: `/employees/${employee.id}`,
              title: `${employee.data.firstName} ${employee.data.lastName} har jubilæum 🎉`,
              text: `${employee.data.firstName} har været ansat i ${
                new Date().getFullYear() - startDate.getFullYear()
              } år i dag`,
              avatar: (
                <Avatar
                  firstName={employee.data.firstName}
                  lastName={employee.data.lastName}
                  profilePhotoUrl={employee.data.profilePhotoUrl}
                />
              ),
            });
          } else if (isDayInNext30Days(startDate)) {
            // If the work anniversary is in the next 30 days, create an occasion
            occasionsData.push({
              link: `/employees/${employee.id}`,
              title: `${employee.data.firstName} ${employee.data.lastName} har snart jubilæum`,
              text: `${employee.data.firstName} har været ansat i ${
                new Date().getFullYear() - startDate.getFullYear()
              } år
              
              ${
                startDate.getDate() - new Date().getDate() === 1
                  ? `i morgen`
                  : `om ${startDate.getDate() - new Date().getDate()} dage`
              }`,
              avatar: (
                <Avatar
                  firstName={employee.data.firstName}
                  lastName={employee.data.lastName}
                  profilePhotoUrl={employee.data.profilePhotoUrl}
                />
              ),
            });
          }
        });

        occasionsData.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });

        setOccasions(occasionsData);

        isInitialLoad.current = false;
      }
    };

    fetchOccasions();
  }, [userInfo, employees]);

  return occasions.length === 0 && !isInitialLoad.current ? (
    <div className="dashboard-panel-empty">
      <span className="dashboard-panel-empty__icon">🔖</span>
      Ingen opkommende mærkedage
    </div>
  ) : (
    <Panels noMargin>
      {occasions.map((occasion, index) => (
        <Panel noPadding key={index}>
          <div className="dashboard-occasion-item">
            {occasion.avatar}
            <div className="dashboard-occasion-item__person">
              <h5>{occasion.title}</h5>
              <p>{occasion.text}</p>
            </div>
          </div>
        </Panel>
      ))}
    </Panels>
  );
}
