import { useRef, useState, useContext, useEffect } from 'react';

import { db } from '../../firebase-config';
import List from '../../components/list/list';
import Avatar from '../../components/avatar/avatar';
import ProgressIndicator from '../../components/progress-indicator/progress-indicator';
import { UserInfoContext, EmployeesContext } from '../../context';
import { collection, query, where, getDocs } from 'firebase/firestore';

export default function DashboardOnboardingOffboarding() {
  const isInitialLoad = useRef(true);
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);
  const [list, setList] = useState([]);

  // Fetch onboarding/offboarding from database and display them in a list
  useEffect(() => {
    const fetchList = async () => {
      if (employees.length !== 0 && userInfo?.companyId) {
        const listRef = collection(db, 'onboardingOffboarding');
        const q = query(
          listRef,
          where('companyId', '==', userInfo.companyId),
          where('completed', '==', false)
        );
        const querySnapshot = await getDocs(q);
        const listData = [];

        const fetchPromises = querySnapshot.docs.map(async (item) => {
          const regarding = employees.find(
            (employee) => employee.id === item.data()?.regarding?.id
          );

          const totalTasksCount = item
            .data()
            .groups.reduce((acc, group) => acc + group.tasks.length, 0);
          const currentCompletedTasksCount = item
            .data()
            .groups.reduce(
              (acc, group) =>
                acc + group.tasks.filter((task) => task.checked).length,
              0
            );

          const allTasksCompleted =
            totalTasksCount === currentCompletedTasksCount &&
            totalTasksCount !== 0;

          // Prepare onboarding/offboarding data for the state
          return {
            link: `/onboarding-offboarding/${item.id}`,
            id: item.id,
            fields: [
              {
                label: 'Vedrørende',
                value: (
                  <div className={`onboarding-offboarding-list-row-profile`}>
                    {regarding ? (
                      <>
                        <Avatar
                          firstName={regarding?.data.firstName}
                          lastName={regarding?.data.lastName}
                          profilePhotoUrl={regarding?.data.profilePhotoUrl}
                        />
                        <div className="onboarding-offboarding-list-row-title-wrapper">
                          <span className="onboarding-offboarding-list-row-title">
                            {item.data().title}
                          </span>
                          <span className="onboarding-offboarding-list-row-regarding">
                            {`${regarding?.data.firstName} ${regarding?.data.lastName}`}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="onboarding-offboarding-list-row-title-wrapper onboarding-offboarding-list-row-title-wrapper--no-avatar">
                        <span className="onboarding-offboarding-list-row-title">
                          {item.data().title}
                        </span>
                        <span className="onboarding-offboarding-list-row-regarding">
                          Ikke tildelt
                        </span>
                      </div>
                    )}
                  </div>
                ),
              },
              {
                label: 'Sidst opdateret',
                value:
                  item?.data()?.updatedAt?.toDate()?.toLocaleString('da-DK', {
                    dateStyle: 'short',
                  }) ||
                  item?.data()?.date?.toDate()?.toLocaleString('da-DK', {
                    dateStyle: 'short',
                  }) ||
                  'N/A',
              },
              {
                label: 'Status',
                value: (
                  <ProgressIndicator
                    current={currentCompletedTasksCount}
                    total={totalTasksCount}
                    completed={allTasksCompleted}
                    label={'Udførte opgaver'}
                  />
                ),
              },
            ],
          };
        });

        const resolvedList = await Promise.all(fetchPromises);
        listData.push(...resolvedList);
        setList(listData);

        isInitialLoad.current = false;
      }
    };

    fetchList();
  }, [userInfo]);

  return list.length === 0 && !isInitialLoad.current ? (
    <div className="dashboard-panel-empty">
      <span className="dashboard-panel-empty__icon">🎉</span>
      Du har ingen igangværende on-/offboarding forløb.
    </div>
  ) : (
    <List
      key={'onboarding-offboarding'}
      head={[
        { label: 'Vedrørende' },
        { label: 'Sidst opdateret' },
        { label: 'Status' },
      ]}
      items={list}
    />
  );
}
