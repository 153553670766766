import './timeregistration-summary.css';
import { convertValueToHoursAndMinutes } from '../../../utils/convertValueToHoursAndMinutes';

const mapSummaryKeyToLabel = {
  actualWorkHours: 'Arbejdstid',
  sickLeave: 'Sygdom',
  timeOff: 'Fri',
  holidays: 'Ferie',
  overtime: 'Arbejde uden for normtid',
};

const calculateSummary = (allUsersMetrics) => {
  const summaryMetrics = {
    normHours: 0,
    actualWorkHours: 0,
    overtime: 0,
    sickLeave: 0,
    timeOff: 0,
    holidays: 0,
  };

  Object.values(allUsersMetrics).forEach((userMetrics) => {
    summaryMetrics.normHours += userMetrics.normHours;
    summaryMetrics.actualWorkHours += userMetrics.actualWorkHours;
    summaryMetrics.overtime += parseInt(userMetrics.overtime.hours, 10);
    summaryMetrics.sickLeave += parseInt(userMetrics.sickLeave.hours, 10);
    summaryMetrics.timeOff += parseInt(userMetrics.timeOff.hours, 10);
    summaryMetrics.holidays += parseInt(userMetrics.holidays.hours, 10);
  });

  return summaryMetrics;
};

function SummaryItem({ label, value, percentage }) {
  return (
    <div className="timeregistration-summary-item">
      <div className="timeregistration-summary-item-top">
        <h5 className="timeregistration-summary-item-label">{label}</h5>
        <span className="timeregistration-summary-item-value">{value}</span>
      </div>
      <h2 className="timeregistration-summary-item-percentage">
        {percentage}%
      </h2>
    </div>
  );
}

function TimeRegistrationSummary({ metrics }) {
  const calculatedSummary = calculateSummary(metrics);

  const calculatePercentage = (value, key) => {
    if (key === 'actualWorkHours') {
      // Calculate percentage of actualWorkHours based on normHours
      return calculatedSummary.normHours > 0
        ? ((value / calculatedSummary.normHours) * 100).toFixed(2)
        : (0).toFixed(2);
    }

    // Exclude normHours and actualWorkHours from the total calculation
    const total = Object.keys(calculatedSummary).reduce((acc, k) => {
      if (k !== 'normHours' && k !== 'actualWorkHours') {
        return acc + calculatedSummary[k];
      }
      return acc;
    }, 0);

    return total > 0 ? ((value / total) * 100).toFixed(2) : (0).toFixed(2);
  };

  return (
    <div className="timeregistration-summary">
      {Object.entries(mapSummaryKeyToLabel).map(([key, label]) => (
        <SummaryItem
          key={key}
          label={label}
          value={convertValueToHoursAndMinutes(calculatedSummary[key])}
          percentage={calculatePercentage(calculatedSummary[key], key)}
        />
      ))}
    </div>
  );
}

export default TimeRegistrationSummary;
