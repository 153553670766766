import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  signInWithEmailAndPassword,
  confirmPasswordReset,
} from 'firebase/auth';
import { auth } from '../../firebase-config';
import { useForm } from '../../hooks/useForm';
import Button from '../../components/button/button';
import InputField from '../../components/input-field/input-field';

const validateSignupForm = (values) => {
  let errors = {};

  if (!values.registerPassword.trim())
    errors.registerPassword = 'Password mangler';
  if (!values.registerRepeatPassword.trim())
    errors.registerRepeatPassword = 'Gentag password';

  return errors;
};

export default function ResetPasswordForm({ actionCode, accountEmail }) {
  const navigate = useNavigate();

  const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] =
    useState('');

  // Function to handle user registration
  const onSubmit = useCallback(
    async (values) => {
      const { registerPassword, registerRepeatPassword } = values;

      // Confirm that the passwords match
      if (registerPassword !== registerRepeatPassword) {
        setRepeatPasswordErrorMessage('Passwords skal være ens');
        return;
      }

      try {
        await confirmPasswordReset(auth, actionCode, registerPassword).then(
          () => {
            // Password reset successful, login the user and redirect to the dashboard
            signInWithEmailAndPassword(
              auth,
              accountEmail,
              registerPassword
            ).then(() => {
              navigate('/', { replace: true });
            });
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    [accountEmail, actionCode, navigate]
  );

  const { values, handleChange, handleSubmit, errors } = useForm(
    {
      registerPassword: '',
      registerRepeatPassword: '',
    },
    validateSignupForm,
    onSubmit
  );

  return (
    <form onSubmit={handleSubmit}>
      <InputField
        name="registerPassword"
        label="Kodeord"
        required
        type="password"
        placeholder="Skriv dit kodeord"
        onChange={handleChange}
        value={values.registerPassword}
        error={errors.registerPassword}
      />

      <InputField
        name="registerRepeatPassword"
        label="Gentag kodeord"
        required
        type="password"
        placeholder="Gentag dit kodeord"
        onChange={handleChange}
        value={values.registerRepeatPassword}
        error={errors.registerRepeatPassword || repeatPasswordErrorMessage}
      />
      <Button type="submit">Gem nyt kodeord</Button>
    </form>
  );
}
