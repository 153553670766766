import './tabs.css';

export default function Tabs({ tabs, activeTab, setActiveTab }) {
  return (
    <div className="tabs">
      {tabs.map((tab, index) => {
        if (!tab.label || tab.hide) {
          return null;
        }

        return (
          <div
            key={index}
            className={`tabs-item ${activeTab === tab.label ? 'active' : ''} ${
              tab.placement === 'right' ? 'tabs-item--right' : ''
            }`}
            onClick={() => {
              setActiveTab(tab.label);
            }}
          >
            {tab.label}
            {typeof tab.count === 'number' && (
              <span className="tabs-item__count">{tab.count}</span>
            )}
          </div>
        );
      })}
    </div>
  );
}
