import { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase-config';
import Logo from '../../components/logo/logo';
import Button from '../../components/button/button';
import InputField from '../../components/input-field/input-field';

export default function ForgotPasswordView() {
  const [resetEmail, setResetEmail] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [attemptedReset, setAttemptedReset] = useState(false);

  // Function to send a reset password email
  const sendResetPasswordEmail = async (e) => {
    e.preventDefault();

    try {
      // Sending reset password email using Firebase authentication
      await sendPasswordResetEmail(auth, resetEmail).then(() => {
        setAttemptedReset(true);
      });
    } catch (error) {
      setEmailErrorMessage('');
      // Handling different error cases and setting appropriate error messages
      if (error.message.includes('invalid-email')) {
        setEmailErrorMessage('Din email er ugyldig. Prøv at indtaste en anden');
      } else if (error.message.includes('user-not-found')) {
        setEmailErrorMessage('Kan ikke finde en bruger med denne email');
      } else {
        setEmailErrorMessage(error.message);
      }
    }
  };

  return (
    <div className="centered-layout">
      <Logo />
      <div className="centered-layout__content">
        {!attemptedReset ? (
          <form
            className="centered-layout__form"
            onSubmit={sendResetPasswordEmail}
          >
            <h3>Glemt dit kodeord?</h3>
            <InputField
              name="email"
              label="Skriv din email"
              type="email"
              placeholder="Skriv din email"
              onChange={(event) => {
                setResetEmail(event.target.value);
              }}
              error={emailErrorMessage}
            />
            <Button type="submit">Nulstil kodeord</Button>
          </form>
        ) : (
          <div className="centered-layout__main">
            <h3>Vi har sendt dig et link</h3>
            <p>Tjek din email og følg linket for at nulstille dit password.</p>
            <p>Du kan lukke dette vindue nu.</p>
          </div>
        )}

        <div className="centered-layout__bottom">
          <Button buttonStyle="text-link" href="/">
            Tilbage til log ind
          </Button>
        </div>
      </div>
    </div>
  );
}
