import { useContext, useEffect, useState } from 'react';
import { db, storage } from '../../firebase-config';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { UserContext, UserInfoContext } from '../../context';
import Button from '../button/button';
import './profile-photo-upload.css';

const uploadProfilePhoto = async (file, companyId, userId) => {
  if (!file || !companyId || !userId) return;

  const filePath = `${companyId}/users/${userId}/profilePhoto/${file.name}`;
  const fileRef = ref(storage, filePath);
  await uploadBytes(fileRef, file);
  const profilePhotoUrl = await getDownloadURL(fileRef);

  const userDoc = doc(db, 'users', userId);
  return await updateDoc(userDoc, {
    profilePhotoUrl,
  }).then(() => profilePhotoUrl);
};

const deleteProfilePhoto = (profilePhoto, companyId, userId) => {
  if (!profilePhoto || !companyId || !userId) return;

  // Create a reference to the file to delete based on the file path
  const photoUrlName = profilePhoto.split('%2F')[4].split('?')[0];

  const filePath = `${companyId}/users/${userId}/profilePhoto/${photoUrlName}`;
  const fileRef = ref(storage, filePath);

  // Delete the file
  return deleteObject(fileRef)
    .then(() => {
      const userDoc = doc(db, 'users', userId);
      updateDoc(userDoc, {
        profilePhotoUrl: '',
      });

      return true;
    })
    .catch((error) => {
      console.error('Error deleting file:', error);
      return false;
    });
};

export default function ProfilePhotoUpload({
  firstName,
  lastName,
  profilePhotoUrl,
  userId,
}) {
  const [file, setFile] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState('');
  const user = useContext(UserContext);
  const userInfo = useContext(UserInfoContext);

  useEffect(() => {
    setProfilePhoto(profilePhotoUrl);
  }, [profilePhotoUrl]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file) {
      const upload = await uploadProfilePhoto(
        file,
        userInfo.companyId,
        userId ? userId : user.uid
      );

      if (upload) {
        setProfilePhoto(upload);
        setFile(null);
      }
    }
  };

  const handleDeleteImage = (profilePhoto) => async () => {
    const deleted = await deleteProfilePhoto(
      profilePhoto,
      userInfo.companyId,
      userId ? userId : user.uid
    );
    // If the photo was deleted successfully, update the state
    if (deleted) {
      setProfilePhoto('');
    }
  };

  return (
    <div className="profile-photo-upload">
      <div
        className="profile-photo-upload__image"
        style={{
          backgroundImage: `url(${
            file ? URL.createObjectURL(file) : profilePhoto
          })`,
        }}
      >
        <input id="file" type="file" onChange={handleFileChange} />
        {file || profilePhoto ? null : (
          <span className="profile-photo-upload__image_placeholder">
            {`${firstName.charAt(0)}${lastName.charAt(0)}`}
          </span>
        )}
      </div>

      {file && (
        <Button
          className="small"
          buttonStyle="secondary"
          type="submit"
          disabled={!file}
          onClick={handleSubmit}
        >
          Gem billede
        </Button>
      )}
      {profilePhoto ? (
        <Button
          buttonStyle="text-link"
          type="button"
          onClick={handleDeleteImage(profilePhoto)}
        >
          Slet billede
        </Button>
      ) : null}
    </div>
  );
}
