import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, collection, onSnapshot, where, query } from 'firebase/firestore';
import { db, auth } from './firebase-config';
import PreLoader from './components/preloader/preloader';
import Sidebar from './components/sidebar/sidebar';
import TopBar from './components/topbar/topbar.jsx';
import PreviewBar from './components/previewbar/previewbar.jsx';
import ZendeskChatWidget from './components/zendesk-chat-widget.jsx';
import {
  UserContext,
  UserInfoContext,
  SubscriptionContext,
  EmployeesContext,
  EquipmentContext,
} from './context';
import { LogsProvider } from './logsContext';

import '@fontsource-variable/plus-jakarta-sans';
import '@fontsource/material-icons-two-tone';
import { PageTracking, initializeTagManager } from './modules/gtm.js';
import './styles/app.css';
import * as Sentry from '@sentry/react';

// views
import LoginView from './views/login/login-view';
import ContractsView from './views/contracts/contracts-view';
import ProfileView from './views/profile/profile-view';
import DashboardView from './views/dashboard/dashboard-view';
import SignupView from './views/signup/signup-view';
import VerifyEmailView from './views/signup/verify-email-view';
import ForgotPasswordView from './views/login/forgot-password-view';
import CompleteSignupView from './views/signup/complete-signup-view';
import AdminView from './views/admin/admin-view';
import CompanyView from './views/company/company-view';
import EmployeesView from './views/employees/employees-view.jsx';
import EmployeeProfileView from './views/employees/employee-profile-view.jsx';
import AgreementsView from './views/agreements/agreements-view.jsx';
import AgreementsDetailView from './views/agreements/agreements-detail-view.jsx';
import EmailActionHandlerView from './views/signup/email-action-handler-view.jsx';
import EquipmentView from './views/equipment/equipment-view.jsx';
import EquipmentDetailView from './views/equipment/equipment-detail-view.jsx';
import EquipmentTypeView from './views/equipment/equipment-type-view.jsx';
import OnboardingOffboardingView from './views/onboarding-offboarding/onboarding-offboarding-view.jsx';
import OnboardingOffboardingDetailView from './views/onboarding-offboarding/onboarding-offboarding-detail-view.jsx';
import RecruitmentView from './views/recruitment/recruitment-view.jsx';
import SubscriptionView from './views/subscription/subscription-view.jsx';
import MobileAppView from './views/mobileapp/mobileapp-view.jsx';
import TimeRegistrationView from './views/timeregistration/timeregistration-view.jsx';
import AdminCompanyDetailView from './views/admin/admin-company-detail-view.jsx';
import NoActiveSubscriptionView from './views/noactivesubscription/noactivesubscription-view.jsx';

function App() {
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [isUserSetupComplete, setIsUserSetupComplete] = useState(false);
  const [userIsEmployee, setUserIsEmployee] = useState(false);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [userIsOwner, setUserIsOwner] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState({
    onPaySubscription: null,
    subscription: null,
  });
  const [employees, setEmployees] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [equipmentVariants, setEquipmentVariants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectToCompleteSignup, setRedirectToCompleteSignup] =
    useState(false);
  const [redirectToVerifyEmail, setRedirectToVerifyEmail] = useState(false);

  const fetchEmployees = (companyId) => {
    if (!companyId) return () => {};

    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('companyId', '==', companyId));

    return onSnapshot(q, (querySnapshot) => {
      const users = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });

      setEmployees(users);
    });
  };

  const fetchCompanyInformation = (companyId) => {
    if (!companyId) return () => {};
    const companyDocRef = doc(db, 'companies', companyId);
    const companySnapshot = onSnapshot(companyDocRef, (snapshot) => {
      if (snapshot.exists()) {
        const companyData = {
          ...snapshot.data(),
          id: snapshot.id,
        };

        setCompanyInfo(companyData);
      }
    });

    return () => companySnapshot();
  };

  const fetchSubscriptionData = (companyId) => {
    if (!companyId)
      return () => {
        setSubscriptionData({
          onPaySubscription: null,
          subscription: null,
        });
      };

    // Fetch both onPaySubscription and subscription
    const onPaySubscriptionRef = collection(db, 'onPaySubscriptions');
    const qOnPaySubscription = query(
      onPaySubscriptionRef,
      where('companyId', '==', companyId)
    );

    const onPaySubscriptionSnapshot = onSnapshot(
      qOnPaySubscription,
      (querySnapshot) => {
        const onPaySubscriptions = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        // Assume only one onPaySubscription per company
        if (onPaySubscriptions.length > 0) {
          const onPaySubscription = onPaySubscriptions[0];
          setSubscriptionData((prevData) => ({
            ...prevData,
            onPaySubscription: {
              ...onPaySubscription.data,
              id: onPaySubscription.id,
            },
          }));
        }
      }
    );

    const subscriptionRef = collection(db, 'subscriptions');
    const qSubscription = query(
      subscriptionRef,
      where('companyId', '==', companyId)
    );

    const subscriptionSnapshot = onSnapshot(qSubscription, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (!doc.exists) return;
        // Assume only one subscription per company, set subscription data in state
        setSubscriptionData((prevData) => ({
          ...prevData,
          subscription: {
            ...doc.data(),
            id: doc.id,
          },
        }));
      });
    });

    return () => {
      onPaySubscriptionSnapshot();
      subscriptionSnapshot();
    };
  };

  const fetchEquipmentTypesAndVariants = (companyId) => {
    if (!companyId) return () => {};

    const equipmentTypesRef = collection(db, 'equipmentTypes');
    const qTypes = query(
      equipmentTypesRef,
      where('companyId', '==', companyId)
    );

    const equipmentTypesSnapshot = onSnapshot(qTypes, (querySnapshot) => {
      const types = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setEquipmentTypes(types);
    });

    const equipmentVariantsRef = collection(db, 'equipmentVariants');
    const qVariants = query(
      equipmentVariantsRef,
      where('companyId', '==', companyId)
    );

    const equipmentVariantsSnapshot = onSnapshot(qVariants, (querySnapshot) => {
      const variants = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setEquipmentVariants(variants);
    });

    return () => {
      equipmentTypesSnapshot();
      equipmentVariantsSnapshot();
    };
  };

  const fetchUserInfo = (userId) => {
    const userDocRef = doc(db, 'users', userId);
    return onSnapshot(userDocRef, (snapshot) => {
      if (snapshot.exists()) {
        // Get user data from the database
        const userData = {
          ...snapshot.data(),
          id: snapshot.id,
        };

        // Set user data to the context
        setUserInfo(userData);

        // Update Sentry user context
        Sentry.setUser({
          id: userData.id,
          companyId: userData.companyId,
          email: userData.email,
        });

        // Fetch company information from the database
        fetchCompanyInformation(userData.companyId);

        // Fetch subscription data from the database
        fetchSubscriptionData(userData.companyId);

        // Fetch employees from the database
        fetchEmployees(userData.companyId);

        // Fetch equipment types and variants from the database
        fetchEquipmentTypesAndVariants(userData.companyId);

        setIsLoading(false);
      } else {
        // If user data does not exist, redirect to complete signup view
        setRedirectToCompleteSignup(true);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (user && companyInfo) {
      if (user.uid === companyInfo.ownerUserId) {
        setUserIsOwner(true);
      }
    }
  }, [user, companyInfo]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);

      if (!currentUser) {
        // User not logged in
        setUserInfo(null);
        setIsLoading(false);
      } else if (!currentUser.emailVerified) {
        // Handle unverified emails
        setRedirectToVerifyEmail(true);
        setIsLoading(false);
      } else {
        // Fetch user details only if email is verified
        currentUser.getIdTokenResult(true).then((idTokenResult) => {
          if (
            idTokenResult.claims.companyId &&
            idTokenResult.claims.role === 'admin'
          ) {
            // Check if custom claims are already set
            setUserIsAdmin(true);
            fetchUserInfo(currentUser.uid);
            setIsUserSetupComplete(true);
          } else if (
            idTokenResult.claims.companyId &&
            idTokenResult.claims.role === 'employee'
          ) {
            // User is an employee, set userIsEmployee to true so we can route them to mobileapp view
            setUserIsEmployee(true);
            setIsLoading(false);
          } else {
            // Wait for claims to be set
            setIsLoading(false);
            setRedirectToCompleteSignup(true);
          }
        });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    initializeTagManager();
  }, []);

  if (isLoading) {
    // Show loading spinner or blank screen while the app is loading
    return <PreLoader />;
  }

  return (
    <>
      <UserContext.Provider value={user}>
        <UserInfoContext.Provider
          value={{ companyData: companyInfo, ...userInfo }}
        >
          <SubscriptionContext.Provider value={subscriptionData}>
            <EmployeesContext.Provider value={employees}>
              <EquipmentContext.Provider
                value={{ equipmentTypes, equipmentVariants }}
              >
                <LogsProvider>
                  <Router>
                    <PreviewBar />
                    {(userIsOwner ||
                      ((subscriptionData?.subscription?.status === 'active' ||
                        subscriptionData?.subscription?.status ===
                          'cancelled') &&
                        userIsAdmin)) && (
                      <>
                        <TopBar />
                        <Sidebar />
                        <ZendeskChatWidget />
                      </>
                    )}
                    <PageTracking />
                    <Routes>
                      <Route
                        path="/email-action-handler"
                        element={<EmailActionHandlerView />}
                      />
                      {redirectToVerifyEmail ? (
                        <Route path="*" element={<VerifyEmailView />} />
                      ) : redirectToCompleteSignup ? (
                        <Route
                          path="*"
                          element={
                            <CompleteSignupView
                              isUserSetupComplete={isUserSetupComplete}
                              setIsUserSetupComplete={setIsUserSetupComplete}
                            />
                          }
                        />
                      ) : userIsEmployee ? (
                        <Route path="*" element={<MobileAppView />} />
                      ) : userIsOwner ||
                        ((subscriptionData?.subscription?.status === 'active' ||
                          subscriptionData?.subscription?.status ===
                            'cancelled') &&
                          userIsAdmin) ? (
                        <>
                          <Route path="/" element={<DashboardView />} />
                          <Route
                            path="/medarbejdere"
                            element={<EmployeesView />}
                          />
                          <Route
                            path="/medarbejdere/:employeeId/:tab?"
                            element={<EmployeeProfileView />}
                          />
                          <Route
                            path="/tidsregistrering/:tab?"
                            element={<TimeRegistrationView />}
                          />
                          <Route path="/aftaler" element={<AgreementsView />} />
                          <Route
                            path="/aftaler/:agreementId"
                            element={<AgreementsDetailView />}
                          />
                          <Route path="/udstyr" element={<EquipmentView />} />
                          <Route
                            path="/udstyr/:equipmentId"
                            element={<EquipmentDetailView />}
                          />
                          <Route
                            path="/udstyr/kategori/:equipmentTypeId"
                            element={<EquipmentTypeView />}
                          />
                          <Route
                            path="/onboarding-offboarding"
                            element={<OnboardingOffboardingView />}
                          />
                          <Route
                            path="/onboarding-offboarding/:pageId"
                            element={<OnboardingOffboardingDetailView />}
                          />
                          <Route
                            path="/onboarding-offboarding/:pageId/:isTemplate"
                            element={<OnboardingOffboardingDetailView />}
                          />
                          <Route
                            path="/rekruttering"
                            element={<RecruitmentView />}
                          />
                          <Route
                            path="/skabeloner"
                            element={<ContractsView />}
                          />
                          <Route path="/profile" element={<ProfileView />} />
                          <Route path="/company" element={<CompanyView />} />
                          <Route
                            path="/subscription"
                            element={<SubscriptionView />}
                          />

                          <Route path="/admin" element={<AdminView />}></Route>
                          <Route
                            path="/admin/virksomhed/:companyId"
                            element={<AdminCompanyDetailView />}
                          ></Route>
                          {/* If no route matches, redirect to dashboard */}
                          <Route path="*" element={<DashboardView />} />
                        </>
                      ) : (subscriptionData?.subscription?.status !==
                          'active' ||
                          subscriptionData?.subscription?.status !==
                            'cancelled') &&
                        userIsAdmin ? (
                        <Route
                          path="*"
                          element={<NoActiveSubscriptionView />}
                        />
                      ) : (
                        <>
                          <Route path="/" element={<LoginView />} />
                          <Route path="/signup" element={<SignupView />} />
                          <Route
                            path="/forgot-password"
                            element={<ForgotPasswordView />}
                          />
                          <Route path="*" element={<LoginView />} />
                        </>
                      )}
                    </Routes>
                  </Router>
                </LogsProvider>
              </EquipmentContext.Provider>
            </EmployeesContext.Provider>
          </SubscriptionContext.Provider>
        </UserInfoContext.Provider>
      </UserContext.Provider>
    </>
  );
}

export default App;
