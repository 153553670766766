import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import ProgressIndicator from '../../components/progress-indicator/progress-indicator';
import ChecklistItem from './checklist-item';
import ChecklistAddItem from './checklist-add-item';
import Icon from '../../components/icon/icon';
import Button from '../../components/button/button';
import './checklist-group.css';
import Modal from '../modal/modal';
import InputField from '../input-field/input-field';

export default function ChecklistGroup({
  groupId,
  group,
  updateGroup,
  deleteGroup,
  handleCheck,
  addTask,
  updateTask,
  deleteTask,
  className,
  isClone = false,
  dragHandle,
}) {
  const tasksCompleted = group?.tasks?.filter((task) => task.checked) || [];
  const [expanded, setExpanded] = useState(false);
  const [addTaskMode, setAddTaskMode] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [groupTitle, setGroupTitle] = useState(group.title);
  const [localTasks, setLocalTasks] = useState(group.tasks);

  const handleGroupTitleChange = (e) => {
    setGroupTitle(e.target.value);
  };

  const handleEditGroup = () => {
    updateGroup(groupId, {
      ...group,
      title: groupTitle,
    });
    setIsEditModalOpen(false);
  };

  const handleDeleteGroup = () => {
    deleteGroup(groupId);
    setIsDeleteModalOpen(false);
  };

  // Function to help reorder the subtasks after drag ends
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // Handle drag end
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedTasks = reorder(
      localTasks,
      result.source.index,
      result.destination.index
    );

    setLocalTasks(reorderedTasks);

    updateGroup(groupId, {
      ...group,
      tasks: reorderedTasks,
    });
  };

  if (isClone) {
    return (
      <div
        className={`checklist-group checklist-group--collapsed' ${
          className || ''
        }`}
      >
        <div className="checklist-group-header drag-handle">
          <svg
            className="checklist-group-header__icon"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L4.29289 4.29289C4.68342 4.68342 5.31658 4.68342 5.70711 4.29289L9 1"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
          <h4>{group.title}</h4>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={`checklist-group ${
          expanded ? 'checklist-group--expanded' : ''
        }
        ${className || ''}
        `}
      >
        <div
          className="checklist-group-header"
          onClick={() => setExpanded(!expanded)}
          {...dragHandle}
        >
          <svg
            className="checklist-group-header__icon"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L4.29289 4.29289C4.68342 4.68342 5.31658 4.68342 5.70711 4.29289L9 1"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
          <h4>{group.title}</h4>
          {localTasks.length > 0 && (
            <ProgressIndicator
              current={tasksCompleted.length}
              total={localTasks.length}
            />
          )}
        </div>
        {expanded && (
          <div className="checklist-group-items-wrapper">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId={`tasks-${group.id}`}
                renderClone={(provided, snapshot, rubric) => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    style={{
                      userSelect: 'none',
                      backgroundColor: snapshot.isDragging ? '#f5f7f3' : '#fff',
                      ...provided.draggableProps.style,
                    }}
                  >
                    <ChecklistItem
                      task={localTasks[rubric.source.index]}
                      checked={localTasks[rubric.source.index].checked}
                    />
                  </div>
                )}
              >
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="checklist-group-items"
                  >
                    {localTasks.map((task, index) => (
                      <Draggable
                        key={task.id}
                        draggableId={task.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <ChecklistItem
                              key={index}
                              id={task.id}
                              handleCheck={handleCheck}
                              addTask={addTask}
                              updateTask={updateTask}
                              deleteTask={deleteTask}
                              groupTitle={group.title}
                              groupId={groupId}
                              taskId={task.id}
                              task={task}
                              dragHandle={provided.dragHandleProps}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div className="checklist-group-add-item">
              {!addTaskMode ? (
                <div className="checklist-group-buttons">
                  <Button
                    buttonStyle="text-link"
                    className="checklist-group-buttons__add-task-button"
                    onClick={() => setAddTaskMode(true)}
                  >
                    <Icon name={'add'} /> Tilføj opgave
                  </Button>
                  <Button
                    className="small"
                    buttonStyle="text-link"
                    onClick={() => setIsDeleteModalOpen(true)}
                  >
                    Slet gruppe
                  </Button>
                  <Button
                    className="small"
                    buttonStyle="text-link"
                    onClick={() => setIsEditModalOpen(true)}
                  >
                    Ret gruppe
                  </Button>
                </div>
              ) : (
                <ChecklistAddItem
                  key={'checklist-add-item'}
                  addTask={addTask}
                  groupId={groupId}
                  taskId={undefined}
                  handleClose={() => setAddTaskMode(false)}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        header="Slet gruppe"
        content={
          <div className="delete-modal-content">
            <Icon name="warning" />
            <p>Er du sikker på at du vil slette gruppen?</p>
          </div>
        }
        bottom={
          <Button buttonStyle="danger" onClick={handleDeleteGroup}>
            Slet
          </Button>
        }
      />
      <Modal
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
        header="Ret gruppe"
        content={
          <div>
            <InputField
              label="Gruppenavn"
              type="text"
              value={groupTitle}
              required
              onChange={handleGroupTitleChange}
            />
          </div>
        }
        bottom={
          <Button
            disabled={groupTitle.trim() === ''}
            buttonStyle="primary"
            onClick={handleEditGroup}
          >
            Gem
          </Button>
        }
      />
    </>
  );
}
