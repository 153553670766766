import './timeregistration-user-registrations-modal-entry-item.css';

import { useLogs } from '../../../logsContext';
import TIME_LOG_ENTRY_TYPES from '../../../constants/time/timeLogEntryTypes';
import { useState, useCallback } from 'react';
import { convertTimeOffTypeUUIDToName } from '../../../views/timeregistration/timeregistration-utils';
import InputField from '../../input-field/input-field';
import { Timestamp } from 'firebase/firestore';
import Button from '../../button/button';

function EntryItem({ modalEmployeeId, modalDate, entry, timeOffTypes }) {
  const [editMode, setEditMode] = useState(false);
  const [formValues, setFormValues] = useState({
    hours: entry.hours,
    type: entry.type,
    note: entry?.note || '',
    startTime: entry.startTime,
    timeOffType: entry?.timeOffType || '',
    endTime: entry.endTime,
  });
  const [valuesChanged, setValuesChanged] = useState(false);

  const { updateLogEntry, deleteLogEntry } = useLogs();

  const isNegative =
    entry.type === 'sickLeave' ||
    entry.type === 'holiday' ||
    entry.type === 'timeOff';

  // Find out type for entry.startTime
  const startTime = new Date(entry.startTime.seconds * 1000).toLocaleTimeString(
    'da-DK',
    {
      timeStyle: 'short',
    }
  );
  const endTime = new Date(entry.endTime.seconds * 1000).toLocaleTimeString(
    'da-DK',
    {
      timeStyle: 'short',
    }
  );

  const handleEditMode = () => {
    if (editMode) {
      return;
    }
    setEditMode(!editMode);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if value has changed
    if (formValues[name] === value) {
      setValuesChanged(false);
    } else {
      setValuesChanged(true);
    }

    if (name === 'endTime' || name === 'startTime') {
      // Keep the date the same
      const date = new Date(formValues[name].seconds * 1000);

      // Set the hours and minutes from 'value'
      const newTime = new Date(value);
      date.setHours(newTime.getHours());
      date.setMinutes(newTime.getMinutes());

      // Move to nearest 15 minutes, e.g. 12:07 -> 12:15 or 12:23 -> 12:30
      const minutesRounded = Math.ceil(date.getMinutes() / 15) * 15;
      date.setMinutes(minutesRounded);

      // Calculate hours
      if (name === 'endTime' && formValues.startTime) {
        const start = new Date(formValues.startTime.seconds * 1000).getTime();
        const end = date.getTime();
        const calculatedHours = (end - start) / 36e5;

        setFormValues({
          ...formValues,
          [name]: Timestamp.fromDate(date),
          hours: calculatedHours,
        });
      } else if (name === 'startTime' && formValues.endTime) {
        const start = date.getTime();
        const end = new Date(formValues.endTime.seconds * 1000).getTime();
        const calculatedHours = (end - start) / 36e5;
        setFormValues({
          ...formValues,
          [name]: Timestamp.fromDate(date),
          hours: calculatedHours,
        });
      } else {
        setFormValues({ ...formValues, [name]: Timestamp.fromDate(date) });
      }
    } else {
      // Save as string
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleDelete = useCallback(async () => {
    if (!window.confirm('Er du sikker på, at du vil slette registreringen?')) {
      return;
    }

    try {
      // Call deleteLogEntry with userId, date, and entryId
      await deleteLogEntry(modalEmployeeId, modalDate, entry.id);

      // Exit edit mode or handle UI changes accordingly
      setEditMode(false);
    } catch (error) {
      console.error('Error deleting log entry:', error);
      alert('Failed to delete log entry. Please try again.');
    }
  }, [deleteLogEntry, modalEmployeeId, modalDate, entry.id, setEditMode]);

  const handleSubmit = useCallback(async () => {
    const valuesToSave = {
      ...formValues,
      startTime: Timestamp.fromDate(
        new Date(formValues.startTime.seconds * 1000)
      ),
      endTime: Timestamp.fromDate(new Date(formValues.endTime.seconds * 1000)),
    };

    await updateLogEntry(modalEmployeeId, modalDate, entry.id, valuesToSave);

    setEditMode(false);
  }, [formValues, modalEmployeeId, modalDate, updateLogEntry, entry.id]);
  return (
    <div
      className="timeregistration-user-registrations-modal-entry-item"
      onClick={handleEditMode}
    >
      {editMode ? (
        <>
          <div className="timeregistration-user-registrations-modal-entry-item-form-row">
            <InputField
              name="type"
              type="select"
              label="Type"
              placeholder="- Vælg -"
              options={TIME_LOG_ENTRY_TYPES.map((type) => ({
                value: type.value,
                label: type.label,
              }))}
              value={formValues.type}
              onChange={handleChange}
              className={`timeregistration-user-registrations-modal-entry-item-form__type ${
                formValues.type === 'timeOff'
                  ? 'timeregistration-user-registrations-modal-entry-item-form__type--timeOff'
                  : ''
              }`}
            />
            {formValues.type === 'timeOff' && (
              <InputField
                name="timeOffType"
                type="select"
                label="Årsag"
                placeholder="- Vælg -"
                options={timeOffTypes}
                value={formValues.timeOffType}
                onChange={handleChange}
                className={`timeregistration-user-registrations-modal-entry-item-form__type ${
                  formValues.type === 'timeOff'
                    ? 'timeregistration-user-registrations-modal-entry-item-form__type--timeOff'
                    : ''
                }`}
              />
            )}
          </div>
          <div className="timeregistration-user-registrations-modal-entry-item-form-row">
            <InputField
              name="startTime"
              type="time"
              label="Fra"
              placeholder="Fra"
              value={formValues.startTime.toDate()}
              onChange={handleChange}
              className="timeregistration-user-registrations-modal-entry-item-form__time"
            />
            <InputField
              name="endTime"
              type="time"
              label="Til"
              placeholder="Til"
              value={formValues.endTime.toDate()}
              onChange={handleChange}
              className="timeregistration-user-registrations-modal-entry-item-form__time"
            />
            <InputField
              name="hours"
              label="Timer"
              type="number"
              key={`hours-${formValues.hours}`}
              value={formValues.hours?.toFixed(2) || ''}
              disabled
              className="timeregistration-user-registrations-modal-entry-item-form__hours"
            />
          </div>
          <InputField
            name="note"
            label="Note"
            type="text"
            onChange={handleChange}
            value={formValues.note || ''}
          />
          <div className="timeregistration-user-registrations-modal-entry-item-form__buttons">
            <Button
              buttonStyle="secondary"
              onClick={handleDelete}
              className="timeregistration-user-registrations-modal-entry-item-form__buttons--delete"
            >
              Slet
            </Button>
            <Button
              buttonStyle="text-link"
              onClick={() => {
                setEditMode(false);
              }}
            >
              Luk
            </Button>
            <Button onClick={handleSubmit} disabled={!valuesChanged}>
              Gem
            </Button>
          </div>
        </>
      ) : (
        <>
          <span className="timeregistration-user-registrations-modal-entry-item__type">
            {
              TIME_LOG_ENTRY_TYPES.find((type) => type.value === entry.type)
                ?.label
            }
            {entry.timeOffType &&
              ` · ${convertTimeOffTypeUUIDToName(
                entry.timeOffType,
                timeOffTypes
              )}`}
          </span>
          <div className="timeregistration-user-registrations-modal-entry-item-inner">
            <span className="timeregistration-user-registrations-modal-entry-item__time">
              {startTime}
              {' - '}
              {endTime}
            </span>
            <span
              className={`timeregistration-user-registrations-modal-entry-item__hours ${
                isNegative
                  ? 'timeregistration-user-registrations-modal-entry-item__hours--negative'
                  : ''
              }`}
            >
              {isNegative ? '-' : '+'}
              {entry.hours.toLocaleString('da-DK', {
                minimumFractionDigits: 1,
              })}{' '}
              timer
            </span>
          </div>
          {entry.note && (
            <span className="timeregistration-user-registrations-modal-entry-item__note">
              {entry.note}
            </span>
          )}
        </>
      )}
    </div>
  );
}

export default EntryItem;
