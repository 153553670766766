import { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import './company-view.css';
import { UserInfoContext } from '../../context';
import { useForm } from '../../hooks/useForm';
import Button from '../../components/button/button';
import InputField from '../../components/input-field/input-field';
import { Panels, Panel } from '../../components/panel/panel';
import PageTop from '../../components/page-top/page-top';
import AdminList from '../../components/admin/admin-list/admin-list';

export default function CompanyView() {
  const isInitialLoad = useRef(true);
  const userInfo = useContext(UserInfoContext);
  const [formFeedback, setFormFeedback] = useState('');

  const validateCompanyForm = (values) => {
    let errors = {};

    if (!values.name.trim()) errors.name = 'Firmanavn mangler';
    if (!values.address.trim()) errors.address = 'Adresse mangler';
    if (!values.city.trim()) errors.city = 'By mangler';
    if (!values.zipcode.trim()) errors.zipcode = 'Postnr. mangler';
    if (!values.vat) errors.vat = 'CVR-nr. mangler';

    return errors;
  };

  // Handle form submission
  const onSubmit = useCallback(
    async (values) => {
      if (userInfo) {
        const companyDocRef = doc(db, 'companies', userInfo.companyId);
        try {
          await setDoc(companyDocRef, values, { merge: true });
          setFormFeedback('Ændringerne er gemt.');
        } catch (error) {
          setFormFeedback('Der skete en fejl. Prøv igen senere.');
          console.error('Error updating company:', error);
        }
      }
    },
    [userInfo]
  );

  // Custom hook for handling form state
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    {
      name: '',
      address: '',
      city: '',
      zipcode: '',
      vat: '',
    },
    validateCompanyForm,
    onSubmit
  );

  // Fetch the user's data from UserInfoContext
  useEffect(() => {
    if (userInfo && userInfo.companyData && isInitialLoad.current) {
      setValues(userInfo.companyData);
      isInitialLoad.current = false;
    }
  }, [userInfo, setValues]);

  return (
    <div className="main-layout">
      <PageTop title="Virksomhed" />
      <Panels>
        <Panel header={<h4>Informationer</h4>}>
          <form onSubmit={handleSubmit}>
            <InputField
              required
              label="Firmanavn"
              name="name"
              value={values.name}
              onChange={handleChange}
              placeholder="Indtast firmanavn"
              error={errors.name}
            />

            <InputField
              required
              label="Adresse"
              name="address"
              value={values.address}
              onChange={handleChange}
              placeholder="Indtast adresse"
              error={errors.address}
            />
            <div className="company-address-fields">
              <InputField
                required
                className="zipcode"
                label="Postnr."
                name="zipcode"
                value={values.zipcode}
                onChange={handleChange}
                placeholder="Indtast ####"
                error={errors.zipcode}
              />

              <InputField
                required
                className="city"
                label="By"
                name="city"
                value={values.city}
                onChange={handleChange}
                placeholder="Indtast by"
                error={errors.city}
              />
            </div>
            <InputField
              label="CVR-nr."
              name="vat"
              value={values.vat}
              disabled
              readOnly
              help="CVR-nr. kan ikke ændres"
            />
            <div className="form-actions">
              <Button type="submit">Gem ændringer</Button>
              {formFeedback && (
                <span className="form-actions__feedback">{formFeedback}</span>
              )}
            </div>
          </form>
        </Panel>
        <Panel
          header={
            <>
              <h4>Admins</h4>
              <p>
                Brugere der har adgang til at redigere og se informationer i
                hele HRfamly systemet.
              </p>
            </>
          }
        >
          <AdminList />
        </Panel>
      </Panels>
    </div>
  );
}
