import { useEffect, useRef } from 'react';
import Icon from '../icon/icon';
import Button from '../button/button';
import './modal.css';

export default function Modal({
  header,
  content,
  bottom,
  isModalOpen,
  setIsModalOpen,
  customWidth,
  className = '',
  ...props
}) {
  const modalRef = useRef(null);

  const handleCloseOnBackdrop = (e) => {
    if (e.target !== modalRef.current) {
      return;
    }

    if (setIsModalOpen) {
      setIsModalOpen(false);
    }
  };

  const handleClose = () => {
    if (setIsModalOpen) {
      setIsModalOpen(false);
    }
  };

  // Lock scroll when modal is open
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }

    return () => {
      document.body.style.overflowY = 'scroll';
    };
  }, [isModalOpen]);

  return (
    <div
      className={`modal ${isModalOpen ? 'modal--open' : ''} ${className}`}
      ref={modalRef}
      onClick={handleCloseOnBackdrop}
      {...props}
    >
      <div
        className="modal__inner"
        style={customWidth ? { maxWidth: customWidth } : {}}
      >
        <div
          className={`modal__header ${
            header === null && 'modal__header--no-border'
          }`}
        >
          <h4>{header}</h4>
          <Button
            buttonStyle="square"
            className="modal__header__close-button"
            onClick={handleClose}
          >
            <Icon name="close" />
          </Button>
        </div>
        <div className="modal__content">{content}</div>
        {bottom && <div className="modal__bottom">{bottom}</div>}
      </div>
    </div>
  );
}
