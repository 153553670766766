import './timeregistration-user-exceedings.css';
import List from '../../list/list';
import Avatar from '../../avatar/avatar';
import Icon from '../../icon/icon';
import Button from '../../button/button';
import Modal from '../../modal/modal';
import InputField from '../../input-field/input-field';
import { db } from '../../../firebase-config';
import { doc, setDoc } from 'firebase/firestore';
import { useState } from 'react';
import { convertToLocalTime } from '../../../utils/formatDates';

const mapWarningType = (type) => {
  switch (type) {
    case '11HourWarning':
      return '11 timers regel';
    case '48HourWarning':
      return '48 timers regel';
    default:
      'Ukendt type';
  }
};

function TimeRegistrationUserExceedings({ employees }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [warningId, setWarningId] = useState('');

  const handleMarkAsRead = (employeeId, id) => {
    if (!employeeId || !id) {
      return;
    }

    const employeeDocRef = doc(db, 'users', employeeId);

    const timeLogWarnings = employees.find((employee) => {
      return employee.id === employeeId;
    })?.data?.timeLogWarnings;

    try {
      // Update the document in Firestore
      setDoc(
        employeeDocRef,
        {
          timeLogWarnings: timeLogWarnings.map((timeLogWarning) => {
            if (timeLogWarning.id === id) {
              return {
                ...timeLogWarning,
                read: true,
              };
            }
            return timeLogWarning;
          }),
        },
        { merge: true }
      );
    } catch (error) {
      // Handle any errors
      console.error('Error updating read status:', error);
    }
  };

  const handleAddComment = (employeeId, id) => {
    // If comment already exists, open modal with comment
    const doesCommentExist = employees.find((employee) => {
      return employee?.data?.timeLogWarnings?.find(
        (timeLogWarning) => timeLogWarning.id === id
      );
    });

    const existingComment = doesCommentExist?.data?.timeLogWarnings.find(
      (timeLogWarning) => timeLogWarning.id === id
    )?.comment;

    if (existingComment) {
      setComment(existingComment);
    }

    setEmployeeId(employeeId);
    setWarningId(id);
    setIsModalOpen(true);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const commentSubmit = async () => {
    if (!employeeId || !warningId) {
      return;
    }

    const employeeDocRef = doc(db, 'users', employeeId);

    const timeLogWarnings = employees.find((employee) => {
      return employee.id === employeeId;
    })?.data?.timeLogWarnings;

    try {
      // Update the document in Firestore
      await setDoc(
        employeeDocRef,
        {
          timeLogWarnings: timeLogWarnings.map((timeLogWarning) => {
            if (timeLogWarning.id === warningId) {
              return {
                ...timeLogWarning,
                comment: comment,
              };
            }
            return timeLogWarning;
          }),
        },
        { merge: true }
      );

      // Reset states
      setEmployeeId('');
      setWarningId('');
      setComment('');

      // Close modal
      setIsModalOpen(false);
    } catch (error) {
      // Handle any errors
      console.error('Error updating comment:', error);
    }
  };

  const warnings = [];

  employees
    .map((employee) => {
      const timeLogWarnings = employee?.data?.timeLogWarnings || [];

      if (timeLogWarnings.length === 0) {
        return null;
      }

      timeLogWarnings
        .sort((a, b) => {
          return new Date(b.toDate.toDate()) - new Date(a.toDate.toDate());
        })
        .map((timeLogWarning) => {
          warnings.push({
            id: employee.id,
            fields: [
              {
                flex: 20,
                label: 'Medarbejder',
                value: (
                  <div className="timeregistration-user-exceedings-row-profile">
                    <Avatar
                      firstName={employee.data.firstName}
                      lastName={employee.data.lastName}
                      profilePhotoUrl={employee.data.profilePhotoUrl}
                      size="small"
                    />
                    <div className="employees-list-row-name-wrapper">
                      <span className="employees-list-row-name">
                        {employee.data.firstName} {employee.data.lastName}
                      </span>
                    </div>
                  </div>
                ),
                sortBy: `${employee.data.firstName} ${employee.data.lastName}`,
                searchBy: `${employee.data.firstName} ${employee.data.lastName}`,
              },
              {
                flex: 15,
                label: 'Periode',
                value: `${convertToLocalTime(timeLogWarning.fromDate, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })} - ${convertToLocalTime(timeLogWarning.toDate, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}`,
              },
              {
                flex: 5,
                label: 'Type',
                value: mapWarningType(timeLogWarning.type),
              },
              {
                flex: 10,
                label: 'Årsag',
                value: (
                  <div className="timeregistration-user-exceedings-row-reason">
                    {timeLogWarning.type === '11HourWarning'
                      ? `${timeLogWarning.value}t hviletid`
                      : `${timeLogWarning.value}t om ugen`}
                  </div>
                ),
              },
              {
                flex: 20,
                align: 'right',
                value: (
                  <div className="timeregistration-user-exceedings-row-actions">
                    {!timeLogWarning.read && (
                      <div
                        className="timeregistration-user-exceedings-row-actions__link"
                        onClick={() =>
                          handleMarkAsRead(employee.id, timeLogWarning.id)
                        }
                      >
                        <Icon name="visibility" />
                        <span>Markér som set</span>
                      </div>
                    )}
                    <div
                      className="timeregistration-user-exceedings-row-actions__link"
                      onClick={() =>
                        handleAddComment(employee.id, timeLogWarning.id)
                      }
                    >
                      <Icon name="add_comment" />
                      <span>
                        {timeLogWarning.comment
                          ? 'Se kommentar'
                          : 'Tilknyt kommentar'}
                      </span>
                    </div>
                  </div>
                ),
              },
            ],
          });
        });
    })
    .filter((item) => item);

  return (
    <div className="timeregistration-user-exceedings">
      <List
        hideListActions
        head={[
          { label: 'Medarbejder', sortable: false, flex: 20 },
          { label: 'Periode', sortable: false, flex: 15 },
          { label: 'Type', sortable: false, flex: 5 },
          { label: 'Årsag', sortable: false, flex: 10 },
          { label: 'Handlinger', sortable: false, flex: 20, align: 'right' },
        ]}
        items={warnings}
      />
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        header={'Tilknyt kommentar'}
        content={
          <div>
            <InputField
              label="Kommentar"
              type="textarea"
              value={comment}
              onChange={handleCommentChange}
            />
          </div>
        }
        bottom={
          <Button buttonStyle="primary" onClick={commentSubmit}>
            Gem
          </Button>
        }
      />
    </div>
  );
}

export default TimeRegistrationUserExceedings;
