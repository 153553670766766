import './timeregistration-user-registrations-modal-entry-logs.css';
import TIME_LOG_ENTRY_TYPES from '../../../constants/time/timeLogEntryTypes';
import TIME_LOG_ACTION_TYPES from '../../../constants/time/timeLogActionTypes';
import { EmployeesContext, UserInfoContext } from '../../../context';
import { useContext } from 'react';

function EntryLogs({ logs }) {
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);

  return (
    <div className="timeregistration-user-registrations-modal-entry-logs-wrapper">
      <b>Historik</b>
      <div className="timeregistration-user-registrations-modal-entry-logs">
        {logs?.map((log) => {
          const authorUser =
            employees.find((user) => user.id === log.author) || null;

          const isCurrentUser = userInfo?.id === log.author;

          return (
            <div
              className="timeregistration-user-registrations-modal-entry-logs__entry"
              key={log.id}
            >
              {isCurrentUser
                ? 'Du'
                : authorUser
                ? `${authorUser.data.firstName} ${authorUser.data.lastName}`
                : 'Du'}{' '}
              {log.action === 'create_entry' ? (
                <>
                  {
                    TIME_LOG_ACTION_TYPES.find(
                      (action) => action.value === log.action
                    )?.label
                  }{' '}
                  {log.details.hours} time{log.details.hours > 1 ? 'r' : ''} på{' '}
                  {TIME_LOG_ENTRY_TYPES.find(
                    (entry) => entry.value === log.details.type
                  )?.label.toLowerCase()}
                </>
              ) : log.action === 'update_entry' ? (
                <>
                  {
                    TIME_LOG_ACTION_TYPES.find(
                      (action) => action.value === log.action
                    )?.label
                  }{' '}
                  {log.details.hours === log.details.previousHours
                    ? 'noten'
                    : `
                  fra ${log.details.previousHours} til ${log.details.hours} time`}
                  {log.details.hours !== log.details.previousHours &&
                  log.details.hours > 1
                    ? 'r'
                    : ''}{' '}
                  på{' '}
                  {TIME_LOG_ENTRY_TYPES.find(
                    (entry) => entry.value === log.details.type
                  )?.label.toLowerCase()}
                </>
              ) : (
                <>
                  {
                    TIME_LOG_ACTION_TYPES.find(
                      (action) => action.value === log.action
                    )?.label
                  }{' '}
                  en tid på{' '}
                  {TIME_LOG_ENTRY_TYPES.find(
                    (entry) => entry.value === log.details.type
                  )?.label.toLowerCase()}
                </>
              )}
              .
              <div className="timeregistration-user-registrations-modal-entry-logs__timestamp">
                {new Date(log.timestamp.toDate()).toLocaleString('da-DK', {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default EntryLogs;
