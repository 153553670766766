import { signOut } from 'firebase/auth';
import { auth } from '../firebase-config';

export const logout = async () => {
  // Log the user out
  await signOut(auth);

  // Redirect the user to the home page
  window.location.href = '/';
};
