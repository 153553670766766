import './admin-company-detail-view.css';
import { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { signInWithCustomToken, getIdTokenResult } from 'firebase/auth';
import { UserContext } from '../../context';
import Button from '../../components/button/button';
import Avatar from '../../components/avatar/avatar';
import { Panel, Panels } from '../../components/panel/panel';
import PageTop from '../../components/page-top/page-top';
import List from '../../components/list/list';
import { auth } from '../../firebase-config';
import InputField from '../../components/input-field/input-field';
import PLANS from '../../constants/payments/plans';
import { Timestamp } from 'firebase/firestore';
import SubscriptionTransactions from '../subscription/subscription-transactions';

const hrfamlyContactPersons = [
  {
    email: 'rlb@betterpeople.dk',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/hrfamly-3521c.appspot.com/o/hrfamly%2Fcontacts%2Frasmus.webp?alt=media&token=495bbded-1ebe-4b34-9605-3a9cad37f24a',
    name: 'Rasmus Lahn Bak',
    phone: '28 55 86 58',
    title: 'Projektleder',
  },
  {
    email: 'sh@betterpeople.dk',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/hrfamly-3521c.appspot.com/o/hrfamly%2Fcontacts%2Fstine.webp?alt=media&token=ed7fe503-8d9c-4c31-9558-e40b2dd47916',
    name: 'Stine Halkjær',
    phone: '61 70 06 93',
    title: 'HR business partner',
  },
  {
    email: 'cb@betterpeople.dk',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/hrfamly-3521c.appspot.com/o/hrfamly%2Fcontacts%2Fclaus.webp?alt=media&token=9fc4a005-e74d-481d-94da-1d7d06a7c498',
    name: 'Claus Bak',
    phone: '53 83 49 94',
    title: 'CEO & partner',
  },
  {
    email: 'sfm@betterpeople.dk',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/hrfamly-3521c.appspot.com/o/hrfamly%2Fcontacts%2Fsine.webp?alt=media&token=23cb1850-4c2d-49fb-af11-1842be225f7b',
    name: 'Sine Fæste',
    phone: '26 85 17 60',
    title: 'HR Business Manager',
  },
  {
    email: 'bs@betterpeople.dk',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/hrfamly-3521c.appspot.com/o/hrfamly%2Fcontacts%2Fbrit.webp?alt=media&token=c4159c3d-5a49-47e9-8192-f6f1093b8178',
    name: 'Brit Sømose',
    phone: '28 74 63 64',
    title: 'HR- & kundekonsulent',
  },
  {
    email: 'ce@betterpeople.dk',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/hrfamly-3521c.appspot.com/o/hrfamly%2Fcontacts%2Fcecilie.webp?alt=media&token=29bf6251-ee9d-4cd3-909b-3abf2fd45200',
    name: 'Cecilie Orris Eriksen',
    phone: '20 88 50 52',
    title: 'HR- & kundekonsulent',
  },
  {
    email: 'jkw@betterpeople.dk',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/hrfamly-3521c.appspot.com/o/hrfamly%2Fcontacts%2Fjonas.webp?alt=media&token=10351710-f5db-484d-938e-0b69723c1099',
    name: 'Jonas Kjær Wieland',
    phone: '53 82 44 66',
    title: 'HR- & kundekonsulent',
  },
];

function Field({ label, value }) {
  return (
    <div className="admin-company-detail-view-entry">
      <div className="admin-company-detail-view-entry__label">{label}</div>
      <div className="admin-company-detail-view-entry__value">{value}</div>
    </div>
  );
}

async function signInAsUser(customToken) {
  try {
    const userCredential = await signInWithCustomToken(auth, customToken);
    console.log('Successfully authenticated as user:', userCredential.user);
  } catch (error) {
    console.error('Error signing in with custom token:', error);
  }
}

export default function AdminCompanyDetailView() {
  const isInitialLoad = useRef(true);
  const { companyId } = useParams();
  const userContext = useContext(UserContext);
  const [contactPersonFormFeedback, setContactPersonFormFeedback] =
    useState('');
  const [formFeedback, setFormFeedback] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [values, setValues] = useState({
    id: '',
    data: {},
    users: [],
    transactions: [],
    createTime: '',
    createdBy: {},
    hasEquipment: false,
    subscriptionData: {},
    onPaySubscriptionData: {},
  });

  const emulateUser = async (userUUIDToEmulate) => {
    try {
      // Store the super admin UUID in local storage
      localStorage.setItem('superAdminUUID', auth.currentUser.uid);

      // API call to emulate another user
      const response = await fetch('/api/admin/emulateuser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-uuid': userContext.uid,
        },
        body: JSON.stringify({ userUUID: userUUIDToEmulate }),
      });

      const data = await response.json();

      if (response.ok && data.customToken) {
        signInAsUser(data.customToken);
      } else {
        console.log('Error:', data);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      // Set loading to false
      isInitialLoad.current = false;
    }
  };

  const handleResetPassword = async (userId) => {
    try {
      const response = await fetch('/api/user/resetpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-uuid': userContext.uid,
        },
        body: JSON.stringify({ userId }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Password reset link sent:', data);
        alert(data.message);
      } else {
        console.error('Error sending password reset link:', data);
      }
    } catch (error) {
      console.error('Error sending password reset link:', error);
    }
  };

  const handleHrfamlyContactChange = (e) => {
    const { value } = e.target;
    const selectedContact = hrfamlyContactPersons.find(
      (contact) => contact.email === value
    );

    setValues({
      ...values,
      data: {
        ...values.data,
        hrfamlyContact: selectedContact,
      },
    });
  };

  const handlePlanChange = (e) => {
    const { value } = e.target;
    const selectedPlan = PLANS[value];

    setValues({
      ...values,
      subscriptionData: {
        ...values.subscriptionData,
        name: selectedPlan.name,
        price: selectedPlan.price,
      },
    });
  };

  const handleTrialChange = (e) => {
    const { value } = e.target;

    setValues({
      ...values,
      subscriptionData: {
        ...values.subscriptionData,
        trial: value,
      },
    });
  };

  const handleNextBillingDateChange = (e) => {
    const { value } = e.target;

    setValues({
      ...values,
      subscriptionData: {
        ...values.subscriptionData,
        nextBillingDate: value,
      },
    });
  };

  // Update HRfamly contact
  const onSaveHrfamlyContactButton = async () => {
    setContactPersonFormFeedback('');
    setDisabled(true);

    try {
      const response = await fetch('/api/company/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-uuid': userContext.uid,
        },
        body: JSON.stringify({
          companyId,
          hrfamlyContact: values.data.hrfamlyContact,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setContactPersonFormFeedback('Gemt');
      } else {
        console.error('Error updating HRfamly contact:', data);
      }
    } catch (error) {
      console.error('Error updating HRfamly contact:', error);
    } finally {
      setDisabled(false);
    }
  };

  // Handle form submission
  const handleOnSubmitClick = () => {
    confirm(
      'Er du sikker på, at du vil gemme ændringerne? Dette får en konsekvens for kunden.'
    ) && onSubmit();
  };

  // Handle form submission
  const onSubmit = async () => {
    setFormFeedback('');
    setDisabled(true);
    try {
      const response = await fetch('/api/company/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-uuid': userContext.uid,
        },
        body: JSON.stringify({
          companyId,
          subscriptionData: {
            ...values.subscriptionData,
          },
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setFormFeedback('Gemt');
      } else {
        console.error('Error updating company:', data);
      }
    } catch (error) {
      console.error('Error updating company:', error);
    } finally {
      setDisabled(false);
    }
  };

  // Check if user is superadmin, if not, redirect to home
  useEffect(() => {
    const checkSuperadmin = async () => {
      const idTokenResult = await getIdTokenResult(auth.currentUser);
      if (!idTokenResult.claims.superadmin) {
        window.location.href = '/';
      }
    };

    if (userContext.uid) {
      checkSuperadmin();
    }
  }, [userContext.uid]);

  // Fetch data for the company
  useEffect(() => {
    const fetchCompanyData = async () => {
      const response = await fetch(
        `/api/admin/companies?companyId=${companyId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-user-uuid': userContext.uid,
          },
        }
      );
      if (!response.ok) {
        alert('Error fetching company data');
        console.error('Error fetching company:', response);
        return;
      }

      const data = await response.json();

      if (data && data.companies && data.companies.length) {
        // Set the company data. Go through transactions date_created and convert {seconds, nanoseconds} to a Date object
        const companyData = data.companies[0];
        const transactions = companyData.transactions.map((transaction) => ({
          ...transaction,
          data: {
            ...transaction.data,
            date_created: new Timestamp(
              transaction.data.date_created._seconds,
              transaction.data.date_created._nanoseconds
            ),
          },
        }));

        setValues({
          id: companyData.id,
          data: companyData.data,
          users: companyData.users,
          transactions,
          createTime: companyData.createTime,
          createdBy: companyData.createdBy,
          hasEquipment: companyData.hasEquipment,
          subscriptionData: companyData.subscriptionData,
          onPaySubscriptionData: companyData.onPaySubscriptionData,
        });
      }

      // Set loading to false
      isInitialLoad.current = false;
    };

    fetchCompanyData();
  }, []);

  const users =
    values?.users?.map((user) => ({
      id: user.id,
      fields: [
        {
          flex: 20,
          label: 'Medarbejder',
          value: (
            <div
              className={`employees-list-row-profile
        ${
          user.employmentStatus === 'inactive'
            ? 'employees-list-row-profile--inactive'
            : ''
        }
        `}
            >
              <Avatar
                firstName={user.firstName}
                lastName={user.lastName}
                profilePhotoUrl={user.profilePhotoUrl}
              />
              <div className="employees-list-row-name-wrapper">
                <span className="employees-list-row-name">
                  {user.firstName} {user.lastName}
                </span>
                <span className="employees-list-row-job">{user.email}</span>
              </div>
            </div>
          ),
          sortBy: `${user.firstName} ${user.lastName}`,
          searchBy: `${user.firstName} ${user.lastName} ${user.email}`,
        },
        {
          label: 'Oprettet d.',
          value: user.date_created
            ? new Date(user.date_created)?.toLocaleDateString('da-DK')
            : '-',
        },
        {
          label: 'Tidsregistrering',
          value: user.hasMobileAccess ? 'Ja' : 'Nej',
        },
        {
          label: 'Admin',
          value: user.isAdmin ? 'Ja' : 'Nej',
        },
        {
          label: 'Handlinger',
          value: (
            <Button
              className="small"
              buttonStyle="text-link"
              onClick={() => handleResetPassword(user.id)}
            >
              Nulstil kodeord
            </Button>
          ),
        },
      ],
      filterData: {
        Medarbejder: `${user.firstName} ${user.lastName}  ${user.email}`,
      },
    })) || [];

  return isInitialLoad.current ? (
    <div>Loading...</div>
  ) : (
    <div className="main-layout">
      <PageTop
        title={`Virksomhed: ${values?.data?.name}`}
        actions={
          <Button
            onClick={() => {
              emulateUser(values?.createdBy?.id);
            }}
          >
            Log på som bruger
          </Button>
        }
      />

      <Panels>
        <Panel columns={50} header={<h4>Data</h4>} headerTop>
          <Field
            label="Oprettet af"
            value={
              <>
                {values?.createdBy?.firstName} {values?.createdBy?.lastName}
                <br />({values?.createdBy?.email})
              </>
            }
          />
          <Field
            label="Oprettelsesdato"
            value={new Date(values?.createTime)?.toLocaleDateString('da-DK')}
          />
          <Field
            label="Antal medarbejdere"
            value={
              <>
                {values?.users?.length} (Med tidsregistrering:{' '}
                {values?.users?.filter((user) => user.hasMobileAccess).length})
              </>
            }
          />
          <Field label="Udstyr" value={values?.hasEquipment ? 'Ja' : 'Nej'} />
          <Field
            label="HRfamly kontaktperson"
            value={
              <InputField
                required
                label="Kontaktperson"
                name="hrfamlyContact"
                type="select2"
                value={
                  hrfamlyContactPersons.find(
                    (contact) =>
                      contact.email === values?.data?.hrfamlyContact?.email
                  )
                    ? {
                        value: values?.data?.hrfamlyContact?.email,
                        label: values?.data?.hrfamlyContact?.name,
                        target: {
                          value: values?.data?.hrfamlyContact?.email,
                          name: 'hrfamlyContact',
                        },
                      }
                    : null
                }
                onChange={handleHrfamlyContactChange}
                options={
                  hrfamlyContactPersons.map((contact) => ({
                    value: contact.email,
                    label: contact.name,
                    target: {
                      value: contact.email,
                      name: 'hrfamlyContact',
                    },
                  })) || []
                }
              />
            }
          />
          <div
            style={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
              gap: 16,
              fontSize: 13,
            }}
          >
            <div>{contactPersonFormFeedback}</div>
            <Button
              className="small"
              buttonStyle="secondary"
              onClick={onSaveHrfamlyContactButton}
              disabled={disabled}
            >
              Gem kontaktperson
            </Button>
          </div>
        </Panel>
        <Panel columns={50} header={<h4>Abonnement</h4>} headerTop>
          <Field
            label="Plan"
            value={
              <InputField
                required
                label="Plan"
                name="plan"
                type="select2"
                value={
                  PLANS[values?.subscriptionData?.name.toUpperCase()] && {
                    value: values?.subscriptionData?.name.toUpperCase(),
                    label: `${
                      PLANS[values?.subscriptionData?.name.toUpperCase()].label
                    } (${
                      PLANS[values?.subscriptionData?.name.toUpperCase()]
                        .price / 100
                    } kr. ekskl. moms)`,
                    target: {
                      value: values?.subscriptionData?.name.toUpperCase(),
                      name: 'plan',
                    },
                  }
                }
                onChange={handlePlanChange}
                options={Object.keys(PLANS).map((plan) => ({
                  value: plan,
                  label: `${PLANS[plan].label} (${
                    PLANS[plan].price / 100
                  } kr. ekskl. moms)`,
                  target: {
                    value: plan,
                    name: 'plan',
                  },
                }))}
              />
            }
          />
          <Field
            label="Næste betalingsdato"
            value={
              <InputField
                required
                label="Næste betalingsdato"
                name="nextBillingDate"
                type="date"
                value={values?.subscriptionData?.nextBillingDate}
                onChange={handleNextBillingDateChange}
              />
            }
          />
          <Field
            label="Næste betaling"
            value={
              <>
                {values?.users?.filter((user) => user.hasMobileAccess).length *
                  10 +
                  (values?.subscriptionData?.status === 'active' &&
                    values?.subscriptionData?.price / 100)}{' '}
                kr. ekskl. moms
              </>
            }
          />
          <Field
            label="Fejlet betaling"
            value={values?.subscriptionData?.lastPaymentFailed ? 'Ja' : 'Nej'}
          />
          <Field
            label="Prøveperiode"
            value={
              <InputField
                required
                label="Prøveperiode"
                name="trial"
                type="select2"
                value={
                  values?.subscriptionData?.trial
                    ? {
                        value: true,
                        label: `Ja (Udløber d. ${new Date(
                          values?.subscriptionData?.nextBillingDate
                        )?.toLocaleDateString('da-DK')})`,
                        target: {
                          value: true,
                          name: 'trial',
                        },
                      }
                    : {
                        value: false,
                        label: 'Nej',
                        target: {
                          value: false,
                          name: 'trial',
                        },
                      }
                }
                onChange={handleTrialChange}
                options={[
                  {
                    value: true,
                    label: 'Ja',
                    target: {
                      value: true,
                      name: 'trial',
                    },
                  },
                  {
                    value: false,
                    label: 'Nej',
                    target: {
                      value: false,
                      name: 'trial',
                    },
                  },
                ]}
              />
            }
          />
          <Field
            label="Betalingskort tilknyttet"
            value={
              values?.onPaySubscriptionData?.onPayData?.data?.order_id &&
              values?.onPaySubscriptionData?.onPayData?.data?.order_id
            }
          />
          <div
            style={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
              gap: 16,
              fontSize: 13,
            }}
          >
            <div>{formFeedback}</div>
            <Button
              className="small"
              buttonStyle="secondary"
              onClick={handleOnSubmitClick}
              disabled={disabled}
            >
              Gem ændringer
            </Button>
          </div>
        </Panel>
        <Panel header={<h4>Medarbejdere</h4>} noPadding>
          <List
            head={[
              { label: 'Medarbejder', flex: 20 },
              { label: 'Oprettet d.' },
              { label: 'Tidsregistrering' },
              { label: 'Admin' },
              { label: 'Handlinger' },
            ]}
            showSearch
            items={users}
          />
        </Panel>
        <SubscriptionTransactions transactions={values?.transactions} />
      </Panels>
    </div>
  );
}
