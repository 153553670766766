import { createContext, useContext, useState, useCallback } from 'react';
import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { db } from './firebase-config';
import { UserInfoContext } from './context';

const LogsContext = createContext();

export const LogsProvider = ({ children }) => {
  const [logs, setLogs] = useState([]);
  const userInfo = useContext(UserInfoContext);

  // Utility function for consistent date formatting
  const formatDate = (date) => {
    return new Date(date).toISOString().split('T')[0];
  };

  const fetchLogs = useCallback(async (userId, dates) => {
    const promises = dates.map(async (date) => {
      const dateString = formatDate(date);
      const logRef = doc(db, `users/${userId}/logs/${dateString}`);
      const logDoc = await getDoc(logRef);

      if (logDoc.exists()) {
        const logData = { ...logDoc.data(), userId, date: dateString };
        return logData;
      }
      return null;
    });

    const fetchedLogs = await Promise.all(promises);
    const validLogs = fetchedLogs.filter((log) => log);
    return validLogs;
  }, []);

  const getFilteredLogs = useCallback(
    async ({ userId, startDate, endDate }) => {
      const datesToFetch = getDatesInRange(startDate, endDate);
      const fetchedLogs = await fetchLogs(userId, datesToFetch);
      setLogs((prevLogs) => mergeLogs(prevLogs, fetchedLogs));
    },
    [fetchLogs]
  );

  const getDatesInRange = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);
    while (currentDate <= new Date(end)) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const mergeLogs = (prevLogs, newLogs) => {
    const logMap = {};

    prevLogs.forEach((log) => {
      const key = `${log.userId}-${log.date}`;
      logMap[key] = log;
    });

    newLogs.forEach((log) => {
      const key = `${log.userId}-${log.date}`;
      logMap[key] = log;
    });

    return Object.values(logMap);
  };

  const updateStateLog = useCallback((updatedLog) => {
    setLogs((prevLogs) => {
      const logExists = prevLogs.some(
        (log) =>
          log.userId === updatedLog.userId && log.date === updatedLog.date
      );

      if (logExists) {
        return prevLogs.map((log) =>
          log.userId === updatedLog.userId && log.date === updatedLog.date
            ? updatedLog
            : log
        );
      } else {
        return [...prevLogs, updatedLog];
      }
    });
  }, []);

  const updateLogEntry = useCallback(
    async (userId, date, entryId, newEntryData) => {
      try {
        const dateString = formatDate(date);
        const logRef = doc(db, `users/${userId}/logs/${dateString}`);
        const logDoc = await getDoc(logRef);

        if (logDoc.exists()) {
          const logData = logDoc.data();

          // Create updated entries array
          const entries = logData.entries.map((entry) =>
            entry.id === entryId ? { ...entry, ...newEntryData } : entry
          );

          // Find the previous entry for logging
          const previousEntry = logData.entries.find(
            (entry) => entry.id === entryId
          );

          // Create a new log action for this update
          const newLogEntry = {
            id: crypto.randomUUID(),
            author: userInfo.id,
            action: 'update_entry',
            timestamp: Timestamp.now(),
            details: {
              ...newEntryData,
              previousHours: previousEntry ? previousEntry.hours : null,
            },
          };

          // Append the new log action
          const logDataLogs = logData.logs || [];

          const updatedLog = {
            ...logData,
            entries,
            logs: [...logDataLogs, newLogEntry],
          };

          // Update Firestore
          await setDoc(logRef, updatedLog);

          // Pass the complete updatedLog to updateStateLog
          updateStateLog({ ...updatedLog, userId, date: dateString });
        } else {
          console.warn('Log document does not exist.');
        }
      } catch (error) {
        console.error('Error updating log entry:', error);
      }
    },
    [updateStateLog, userInfo.id]
  );

  const addLogEntry = useCallback(
    async (userId, date, newEntryData) => {
      try {
        const dateString = formatDate(date);
        const logRef = doc(db, `users/${userId}/logs/${dateString}`);
        const logDoc = await getDoc(logRef);

        if (logDoc.exists()) {
          const logData = logDoc.data();

          // Ensure entries array exists
          const entries = logData.entries ? [...logData.entries] : [];

          // Create a new entry with a unique ID
          const newEntry = {
            id: crypto.randomUUID(),
            ...newEntryData,
          };

          // Append the new entry
          entries.push(newEntry);

          // Create a new log action for adding an entry
          const newLogAction = {
            id: crypto.randomUUID(),
            author: userInfo.id,
            action: 'add_entry',
            timestamp: Timestamp.now(),
            details: {
              ...newEntryData,
            },
          };

          // Append the new log action
          const logDataLogs = logData.logs ? [...logData.logs] : [];
          logDataLogs.push(newLogAction);

          // Prepare the updated log object
          const updatedLog = {
            ...logData,
            entries,
            logs: logDataLogs,
          };

          // Update Firestore with the new log
          await setDoc(logRef, updatedLog);

          // Update local state
          updateStateLog({ ...updatedLog, userId, date: dateString });
        } else {
          console.warn('Log document does not exist. Creating a new one.');

          // If the log doesn't exist, create a new one with the new entry
          const newEntry = {
            id: crypto.randomUUID(),
            ...newEntryData,
          };

          const newLogAction = {
            id: crypto.randomUUID(),
            author: userInfo.id,
            action: 'add_entry',
            timestamp: Timestamp.now(),
            details: {
              ...newEntryData,
            },
          };

          const newLog = {
            userId,
            date: dateString,
            entries: [newEntry],
            logs: [newLogAction],
          };

          // Set the new log in Firestore
          await setDoc(logRef, newLog);

          // Update local state
          updateStateLog({ ...newLog, userId, date: dateString });
        }
      } catch (error) {
        console.error('Error adding new log entry:', error);
      }
    },
    [updateStateLog, userInfo.id]
  );

  /**
   * **deleteLogEntry**
   *
   * Deletes an entry from a user's log and records the deletion action.
   *
   * @param {string} userId - The ID of the user.
   * @param {Date|string} date - The date of the log.
   * @param {string} entryId - The ID of the entry to delete.
   */
  const deleteLogEntry = useCallback(
    async (userId, date, entryId) => {
      try {
        const dateString = formatDate(date);
        const logRef = doc(db, `users/${userId}/logs/${dateString}`);
        const logDoc = await getDoc(logRef);

        if (logDoc.exists()) {
          const logData = logDoc.data();

          // Find the entry to delete
          const entryToDelete = logData.entries.find(
            (entry) => entry.id === entryId
          );

          if (!entryToDelete) {
            console.warn(`Entry with ID ${entryId} does not exist.`);
            return;
          }

          // Remove the entry from entries array
          const updatedEntries = logData.entries.filter(
            (entry) => entry.id !== entryId
          );

          // Create a new log action for deletion
          const deleteLogAction = {
            id: crypto.randomUUID(),
            author: userInfo.id,
            action: 'delete_entry',
            timestamp: Timestamp.now(),
            details: {
              ...entryToDelete,
            },
          };

          // Append the delete log action
          const updatedLogs = logData.logs
            ? [...logData.logs, deleteLogAction]
            : [deleteLogAction];

          // Prepare the updated log object
          const updatedLog = {
            ...logData,
            entries: updatedEntries,
            logs: updatedLogs,
          };

          // Update Firestore
          await setDoc(logRef, updatedLog);

          // Update local state
          updateStateLog({ ...updatedLog, userId, date: dateString });
        } else {
          console.warn('Log document does not exist.');
        }
      } catch (error) {
        console.error('Error deleting log entry:', error);
      }
    },
    [updateStateLog, userInfo.id]
  );

  return (
    <LogsContext.Provider
      value={{
        logs,
        getFilteredLogs,
        updateLogEntry,
        addLogEntry,
        deleteLogEntry,
      }}
    >
      {children}
    </LogsContext.Provider>
  );
};

export const useLogs = () => {
  return useContext(LogsContext);
};
