import './timeregistration-user-registrations-modal.css';

import Modal from '../../modal/modal';
import { UserInfoContext, EmployeesContext } from '../../../context';
import { useMemo, useContext, useState, useCallback } from 'react';
import { currentTimeLogSetting } from '../../../utils/currentTimeLogSetting';
import DANISH_HOLIDAYS from '../../../constants/time/danishHolidays';
import { useLogs } from '../../../logsContext';
import TimeLogEntryWorkHours from './timeregistration-user-registrations-modal-entry-work-hours';
import { isDateLocked } from '../../../utils/isDateLocked';
import { getCompanyStartDate } from '../../../utils/getCompanyStartDate';
import EntryItem from './timeregistration-user-registrations-modal-entry-item';
import EntryLogs from './timeregistration-user-registrations-modal-entry-logs';
import Button from '../../button/button';
import EntryItemAdd from './timeregistration-user-registrations-modal-entry-item-add';
import { currentWeek } from '../../../utils/currentWeek';
import { arrayUnion, doc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase-config';

function TimeRegistrationUserRegistrationsModal({
  isModalOpen,
  setIsModalOpen,
  modalEmployeeId,
  modalDate,
  metrics,
}) {
  const { logs } = useLogs();
  const employees = useContext(EmployeesContext);
  const userInfo = useContext(UserInfoContext);
  const [submitting, setSubmitting] = useState(false);
  const [addMode, setAddMode] = useState(false);

  const dateObj = useMemo(() => new Date(modalDate), [modalDate]);

  const employee = employees.find(
    (employee) => employee.id === modalEmployeeId
  );

  const entries = useMemo(() => {
    const log = logs.find(
      (log) => log.userId === modalEmployeeId && log.date === modalDate
    );
    return log?.entries || [];
  }, [logs, modalEmployeeId, modalDate]);

  const timeLogLogs = useMemo(() => {
    const log = logs.find(
      (log) => log.userId === modalEmployeeId && log.date === modalDate
    );

    // Sorted based on time of day, e.g. 08:00 - 16:00
    return log?.logs?.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
  }, [logs, modalEmployeeId, modalDate]);

  // Lock or unlock the date by adding or removing it from the employee's timeLogApprovedWeeks
  const handleLockUnlock = useCallback(async () => {
    setSubmitting(true);

    const weekNumber = currentWeek(dateObj);
    const year = dateObj.getFullYear();
    const weekKey = `${weekNumber}-${year}`;

    const addOrRemoveWeekKey =
      employee?.data?.timeLogApprovedWeeks?.includes(weekKey) === true;

    const employeeDocRef = doc(db, 'users', employee.id);

    try {
      await setDoc(
        employeeDocRef,
        {
          timeLogApprovedWeeks: addOrRemoveWeekKey
            ? employee?.data?.timeLogApprovedWeeks.filter(
                (week) => week !== weekKey
              )
            : arrayUnion(weekKey),
        },
        { merge: true }
      );
    } catch (error) {
      console.error('Error setting document:', error);
    } finally {
      setSubmitting(false);
    }
  }, [dateObj, employee]);

  if (!employee) {
    return;
  }

  if (!modalDate) {
    return null;
  }

  const timeLogSetting = currentTimeLogSetting(
    userInfo.companyData,
    employee?.data,
    dateObj
  );

  const date = dateObj.toLocaleDateString('da-DK', {
    weekday: 'long',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  // Check if the day is a Danish holiday
  const isHoliday = DANISH_HOLIDAYS.filter(
    (holiday) => holiday.value === date
  )[0];

  const modalEmployeeMetrics = metrics[modalDate]?.[modalEmployeeId];

  const locked = isDateLocked(
    dateObj,
    employee?.data?.timeLogApprovedWeeks || [],
    employee?.data?.startDate,
    getCompanyStartDate(userInfo.companyData)
  );

  return (
    <>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        header={`${date}`}
        className="timeregistration-user-registrations-modal"
        customWidth={'600px'}
        content={
          <>
            <div className="timeregistration-user-registrations-modal-overview">
              <div className="timeregistration-user-registrations-modal-overview-row">
                <div className="timeregistration-user-registrations-modal-overview-row-label">
                  Status
                </div>
                <div className="timeregistration-user-registrations-modal-overview-row-value">
                  {locked ? 'Låst' : 'Åben'}
                </div>
                <div className="timeregistration-user-registrations-modal-overview-row-value timeregistration-user-registrations-modal-overview-row-value--options">
                  {locked ? (
                    <Button
                      className="small"
                      buttonStyle="text-link"
                      onClick={handleLockUnlock}
                      disabled={submitting}
                    >
                      {submitting
                        ? 'Låser ugen op...'
                        : `Lås uge ${currentWeek(dateObj)} op`}
                    </Button>
                  ) : (
                    <Button
                      className="small"
                      buttonStyle="text-link"
                      onClick={handleLockUnlock}
                      disabled={submitting}
                    >
                      {submitting
                        ? 'Låser ugen...'
                        : `Lås uge ${currentWeek(dateObj)}`}
                    </Button>
                  )}
                </div>
              </div>
              <div className="timeregistration-user-registrations-modal-overview-row">
                <div className="timeregistration-user-registrations-modal-overview-row-label">
                  Dato
                </div>
                <div className="timeregistration-user-registrations-modal-overview-row-value">
                  {date}
                </div>
              </div>
              <div className="timeregistration-user-registrations-modal-overview-row">
                <div className="timeregistration-user-registrations-modal-overview-row-label">
                  Medarbejder
                </div>
                <div className="timeregistration-user-registrations-modal-overview-row-value">
                  {employee.data.firstName} {employee.data.lastName}
                </div>
              </div>
              <div className="timeregistration-user-registrations-modal-overview-row">
                <div className="timeregistration-user-registrations-modal-overview-row-label">
                  Normtid
                </div>
                <div className="timeregistration-user-registrations-modal-overview-row-value">
                  {timeLogSetting &&
                    !timeLogSetting.isPaidHourly &&
                    (isHoliday ? (
                      isHoliday.label
                    ) : timeLogSetting.notWorking ? (
                      'Fri'
                    ) : (
                      <>
                        {timeLogSetting.from
                          .toDate()
                          .toLocaleTimeString('da-DK', {
                            timeStyle: 'short',
                          })}{' '}
                        -{' '}
                        {timeLogSetting.end
                          .toDate()
                          .toLocaleTimeString('da-DK', {
                            timeStyle: 'short',
                          })}
                      </>
                    ))}
                </div>
              </div>
            </div>
            <div className="timeregistration-user-registrations-modal-entries">
              <TimeLogEntryWorkHours
                date={dateObj}
                timeLogSetting={timeLogSetting}
              />
              {entries.map((entry, index) => (
                <EntryItem
                  key={index}
                  entry={entry}
                  modalEmployeeId={modalEmployeeId}
                  modalDate={modalDate}
                  timeOffTypes={userInfo.companyData.timeOffTypes}
                />
              ))}
              {addMode && (
                <EntryItemAdd
                  modalEmployeeId={modalEmployeeId}
                  modalDate={modalDate}
                  timeOffTypes={userInfo.companyData.timeOffTypes}
                  setAddMode={setAddMode}
                />
              )}
              <div className="timeregistration-user-registrations-modal-entries-add">
                <Button
                  buttonStyle="text-link"
                  onClick={() => setAddMode(true)}
                >
                  Ny registrering
                </Button>
              </div>
              {timeLogLogs?.length > 0 && <EntryLogs logs={timeLogLogs} />}
            </div>
          </>
        }
        bottom={
          modalEmployeeMetrics?.actualWorkHours > 0 && (
            <div className="timeregistration-user-registrations-modal-bottom">
              <div>Total arbejdstid</div>
              <div>
                {modalEmployeeMetrics?.actualWorkHours.toLocaleString('da-DK', {
                  minimumFractionDigits: 1,
                })}{' '}
                timer
              </div>
            </div>
          )
        }
      />
    </>
  );
}

export default TimeRegistrationUserRegistrationsModal;
