export const convertValueToHoursAndMinutes = (value) => {
  if (value < 0 || value === 0) return '-';

  // Convert the value to hours and minutes
  let hours = Math.floor(value) || 0;
  let minutes = Math.round((value - hours) * 60) || 0;

  // Round minutes to the nearest quarter-hour
  if (minutes % 15 !== 0) {
    minutes = Math.round(minutes / 15) * 15;
  }

  // If rounding minutes resulted in 60, increment the hour
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  return `${hours ? `${hours}t` : ''} ${minutes ? `${minutes}m` : ''}`.trim();
};
