import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Panel } from '../../components/panel/panel';
import InputField from '../../components/input-field/input-field';
import TimeRegistrationTop from '../../components/timeregistration/top/timeregistration-top';
import TimeRegistrationDateRangeFilter from '../../components/timeregistration/date-range-filter/timeregistration-date-range-filter';
import { calculateAllUsersMetrics } from './timeregistration-utils';
import { useLogs } from '../../logsContext';
import TimeRegistrationUserRegistrations from '../../components/timeregistration/user-registrations/timeregistration-user-registrations';

const TimeRegistrationRegistrations = ({
  companyData,
  dateRange,
  setDateRange,
  employees,
  employeesToDisplay,
  setEmployeesToDisplay,
  valueToDisplay,
  setValueToDisplay,
  employeeToDisplayInFilter,
  setEmployeeToDisplayInFilter,
}) => {
  const { logs } = useLogs();
  const [metrics, setMetrics] = useState({});
  const locationState = useLocation().state;

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleEmployeeFilterChange = useCallback(
    (e) => {
      const selectedEmployeeId = e.target.value;
      const selectedEmployee = employees.find(
        (employee) => employee.id === selectedEmployeeId
      );

      if (selectedEmployee) {
        setEmployeesToDisplay([selectedEmployee]);
        setEmployeeToDisplayInFilter(selectedEmployee);
      } else {
        setEmployeesToDisplay(employees);
        setEmployeeToDisplayInFilter(null);
      }
    },
    [employees, setEmployeesToDisplay, setEmployeeToDisplayInFilter]
  );

  // Set employee filter if employeeId is passed in location state
  useEffect(() => {
    if (locationState?.employeeId) {
      const selectedEmployee = employees.find(
        (employee) => employee.id === locationState.employeeId
      );
      if (selectedEmployee) {
        handleEmployeeFilterChange({
          target: { value: selectedEmployee.id },
        });
      }
    }
  }, []);

  useEffect(() => {
    const logsByUser = logs.reduce((acc, log) => {
      const { userId } = log;
      if (!acc[userId]) {
        acc[userId] = [];
      }
      acc[userId].push(log);
      return acc;
    }, {});

    const allUsersMetrics = calculateAllUsersMetrics(
      logsByUser,
      employeesToDisplay,
      companyData,
      dateRange,
      true
    );
    setMetrics(allUsersMetrics);
  }, [logs, employeesToDisplay, companyData, dateRange]);

  return (
    <Panel
      noPadding
      inlineHeader
      header={
        <TimeRegistrationTop
          title="Registreringer"
          filters={
            <>
              <TimeRegistrationDateRangeFilter
                key={'filter-date'}
                name={'date'}
                value={dateRange}
                onChange={handleDateRangeChange}
                valueToDisplay={valueToDisplay}
                setValueToDisplay={setValueToDisplay}
                disableYear={true}
              />

              <InputField
                key={'filter-employee'}
                label={'Medarbejder'}
                type="select"
                name={'employee'}
                options={employees.map((employee) => ({
                  value: employee.id,
                  label: `${employee.data.firstName} ${employee.data.lastName}`,
                }))}
                placeholder="- Vælg -"
                value={employeeToDisplayInFilter?.id}
                onChange={handleEmployeeFilterChange}
              />
            </>
          }
        />
      }
    >
      <TimeRegistrationUserRegistrations
        employees={employeesToDisplay}
        metrics={metrics}
      />
    </Panel>
  );
};

export default TimeRegistrationRegistrations;
