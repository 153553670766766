import './timeregistration-user-registrations-modal-entry-item.css';
import DANISH_HOLIDAYS from '../../../constants/time/danishHolidays';

function TimeLogEntryWorkHours({ date, timeLogSetting }) {
  const workingHours =
    (timeLogSetting.end.toMillis() - timeLogSetting.from.toMillis()) /
    1000 /
    60 /
    60;

  // If paid break is enabled, break duration is 0
  let breakDuration = timeLogSetting.paidBreak
    ? 0
    : timeLogSetting.notWorking
    ? 0
    : workingHours === 0
    ? 0
    : timeLogSetting.breakDuration;

  // Check if the day is a Danish holiday
  const isHoliday = DANISH_HOLIDAYS.filter(
    (holiday) => holiday.value === date.toISOString().split('T')[0]
  )[0];

  if (isHoliday) {
    return null;
  }

  if (timeLogSetting.notWorking) {
    return null;
  }

  return (
    <div className="timeregistration-user-registrations-modal-entry-item timeregistration-user-registrations-modal-entry-item--working-hours">
      <div className="timeregistration-user-registrations-modal-entry-item-inner">
        <span className="timeregistration-user-registrations-modal-entry-item__type">
          Normal arbejdstid
        </span>
        <span className="timeregistration-user-registrations-modal-entry-item__hours timeregistration-user-registrations-modal-entry-item__hours--neutral">
          {(workingHours - breakDuration / 60).toLocaleString('da-DK', {
            minimumFractionDigits: 1,
          })}{' '}
          timer
        </span>
      </div>
      <span className="timeregistration-user-registrations-modal-entry-item__time">
        {timeLogSetting.paidBreak ? 'Inkl.' : 'Eksl.'}{' '}
        {!timeLogSetting.paidBreak ? `${breakDuration} minutters` : ''} pause
      </span>
    </div>
  );
}

export default TimeLogEntryWorkHours;
