import Icon from '../icon/icon';
import './progress-indicator.css';

export default function ProgressIndicator({
  current,
  total,
  completed,
  small,
  label = '',
}) {
  // Calculate the fraction of the circle to fill
  const fraction = current / total;

  // Calculate the end position of the arc
  const endX = 50 + 25 * Math.sin(Math.PI * 2 * fraction);
  const endY = 50 - 25 * Math.cos(Math.PI * 2 * fraction);

  // Determine if the arc should be drawn as a large arc (greater than 180 degrees)
  const largeArcFlag = fraction > 0.5 ? 1 : 0;

  // Construct the path description
  // M 50 25: Move to the starting point (top of the circle)
  // A 25 25 0 {largeArcFlag} 1 {endX} {endY}: Draw an arc to the end position
  const pathDescription = `M 50 25 A 25 25 0 ${largeArcFlag} 1 ${endX} ${endY}`;

  if (current === total && total === 0) {
    return <span className="progress-indicator__label">Ingen opgaver</span>;
  }

  return (
    <div
      className={`progress-indicator ${
        completed ? 'progress-indicator--completed' : ''
      }
      ${small ? 'progress-indicator--small' : ''}
    }`}
    >
      <span className="progress-indicator__number">
        {`${current} / ${total}`}
      </span>
      {label && <span className="progress-indicator__label">{label}</span>}
      {current >= total ? (
        <Icon name="check" />
      ) : (
        <svg
          className="progress-indicator__circle"
          viewBox="0 0 100 100"
          width="100%"
          height="100%"
        >
          <path d={pathDescription} />
        </svg>
      )}
    </div>
  );
}
