const EMPLOYMENT_TYPE = [
  { value: 'fullTime', label: 'Fuldtid' },
  { value: 'partTime', label: 'Deltid' },
  { value: 'studentAssistant', label: 'Studentermedhjælper' },
  { value: 'trainee', label: 'Praktikant' },
  { value: 'pupil', label: 'Elev' },
  { value: 'substitute', label: 'Vikar' },
  { value: 'wageSubsidy', label: 'Løntilskud' },
  { value: 'freelance', label: 'Freelance' },
  { value: 'other', label: 'Andet' },
];

export default EMPLOYMENT_TYPE;
