// TimeRegistrationUserMetrics.jsx

import './timeregistration-user-metrics.css';
import { useNavigate } from 'react-router-dom';
import List from '../../list/list';
import Avatar from '../../avatar/avatar';
import TimeRegistrationWorkBar from '../work-bar/timeregistration-work-bar';
import { convertValueToHoursAndMinutes } from '../../../utils/convertValueToHoursAndMinutes';
import { currentWeek } from '../../../utils/currentWeek';
import { isDateLocked } from '../../../utils/isDateLocked';
import { getCompanyStartDate } from '../../../utils/getCompanyStartDate';

function TimeRegistrationUserMetrics({ companyData, employees, metrics }) {
  const navigate = useNavigate();
  const currentDate = new Date();

  /**
   * Helper function to get the start date (Monday) of the week for a given date.
   * @param {Date} date - The date within the week.
   * @returns {Date} - The start date (Monday) of the week.
   */
  const getStartOfWeek = (date) => {
    const startOfWeek = new Date(date);
    startOfWeek.setHours(0, 0, 0, 0); // Normalize to midnight
    const day = startOfWeek.getDay();
    const diffToMonday = day === 0 ? -6 : 1 - day; // Adjust when Sunday (0) is encountered
    startOfWeek.setDate(startOfWeek.getDate() + diffToMonday);
    return startOfWeek;
  };

  /**
   * Helper function to get the end date (Sunday) of the week for a given start date.
   * @param {Date} startOfWeek - The start date (Monday) of the week.
   * @returns {Date} - The end date (Sunday) of the week.
   */
  const getEndOfWeek = (startOfWeek) => {
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999); // Normalize to end of day
    return endOfWeek;
  };

  /**
   * Helper function to construct the weekKey in the format "weekNumber-year".
   * @param {Date} date - The date within the week.
   * @returns {string} - The weekKey string.
   */
  const constructWeekKey = (date) => {
    const weekNum = currentWeek(date);
    const weekYear = date.getFullYear();
    return `${weekNum}-${weekYear}`;
  };

  /**
   * Generates all open weeks for approval based on the user's startDate and company's startDate.
   * Excludes weeks that are already approved, weeks before the lock date, and the current week.
   * @param {Object} employee - The employee object.
   * @returns {string|React.Element} - The formatted open weeks count.
   */
  const fetchOpenWeeks = (employee) => {
    if (!employee?.data?.startDate) {
      // If there's no startDate, assume no open weeks
      return '0 uger';
    }

    // Get the user's start date as Firebase Timestamp
    const userStartDate = employee.data.startDate.toDate();

    // Get the company's start date using the utility function
    const companyStartDate = getCompanyStartDate(companyData);

    // Determine the lock date as the later of userStartDate and companyStartDate
    let lockDate = null;
    if (userStartDate && companyStartDate) {
      lockDate =
        userStartDate > companyStartDate ? userStartDate : companyStartDate;
    } else if (companyStartDate) {
      lockDate = companyStartDate;
    } else if (userStartDate) {
      lockDate = userStartDate;
    }

    // Get the start of the current week (Monday)
    const currentWeekStart = getStartOfWeek(currentDate);

    // Set the end date to the previous Sunday (end of last completed week)
    const endDateForGenerateOpenWeeks = new Date(currentWeekStart);
    endDateForGenerateOpenWeeks.setDate(
      endDateForGenerateOpenWeeks.getDate() - 1
    );
    endDateForGenerateOpenWeeks.setHours(23, 59, 59, 999); // Normalize to end of day

    // Initialize openWeeks counter
    let openWeeks = 0;

    // Initialize the weekStart to the start of the first week to consider (lockDate)
    let weekStart = lockDate
      ? getStartOfWeek(lockDate)
      : getStartOfWeek(userStartDate);

    // Iterate through each week from lockDate to endDateForGenerateOpenWeeks
    while (weekStart <= endDateForGenerateOpenWeeks) {
      const weekKey = constructWeekKey(weekStart);

      // Check if the week is already approved
      const isApproved =
        employee?.data?.timeLogApprovedWeeks?.includes(weekKey);

      // Determine if the week is locked
      const locked = isDateLocked(
        weekStart,
        employee?.data?.timeLogApprovedWeeks || [],
        employee?.data?.startDate, // get the users start date as a timestamp
        companyStartDate
      );

      if (!isApproved && !locked) {
        openWeeks++;
      }

      // Move to the next week
      weekStart.setDate(weekStart.getDate() + 7);
    }

    // Format the openWeeks count
    if (openWeeks === 1) {
      return '1 uge';
    }

    if (openWeeks > 3) {
      return (
        <div className="timeregistration-user-metrics-row-openweeks">{`${openWeeks} uger`}</div>
      );
    }

    return `${openWeeks} uger`;
  };

  const items = employees
    .map((employee) => {
      // Exclude exempt employees
      if (employee?.data?.settings?.timeRegistration?.exempt) {
        return null;
      }

      // Get employee metrics
      const employeeMetrics = metrics[employee.id] || {};

      // Check if all metrics are zero
      const allMetricsZero =
        (employeeMetrics.actualWorkHours || 0) === 0 &&
        (employeeMetrics.normHours || 0) === 0 &&
        (employeeMetrics.overtime?.hours || 0) === 0 &&
        (employeeMetrics.sickLeave?.hours || 0) === 0 &&
        (employeeMetrics.timeOff?.hours || 0) === 0 &&
        (employeeMetrics.holidays?.hours || 0) === 0;

      if (allMetricsZero) {
        return null;
      }

      return {
        id: employee.id,
        onClick: () => {
          navigate(`/tidsregistrering/registreringer`, {
            state: {
              employeeId: employee.id,
            },
          });
        },
        fields: [
          {
            flex: 20,
            label: 'Medarbejder',
            value: (
              <div className="timeregistration-user-metrics-row-profile">
                <Avatar
                  firstName={employee.data.firstName}
                  lastName={employee.data.lastName}
                  profilePhotoUrl={employee.data.profilePhotoUrl}
                  size="small"
                />
                <div className="employees-list-row-name-wrapper">
                  <span className="employees-list-row-name">
                    {employee.data.firstName} {employee.data.lastName}
                  </span>
                </div>
              </div>
            ),
            sortBy: `${employee.data.firstName} ${employee.data.lastName}`,
            searchBy: `${employee.data.firstName} ${employee.data.lastName}`,
          },
          {
            label: 'Åbne uger',
            value: fetchOpenWeeks(employee), // Updated function call
            sortBy: metrics[employee.id]?.openWeeks || 0,
            align: 'right',
          },
          {
            label: 'Arbejdstid',
            flex: 15,
            value: (
              <TimeRegistrationWorkBar
                hours={metrics[employee.id]?.actualWorkHours || 0}
                total={metrics[employee.id]?.normHours || 0}
              />
            ),
            sortBy: metrics[employee.id]?.actualWorkHours || 0,
            align: 'right',
          },
          {
            label: 'Sygdom',
            value: convertValueToHoursAndMinutes(
              metrics[employee.id]?.sickLeave?.hours || 0
            ),
            sortBy: metrics[employee.id]?.sickLeave?.hours || 0,
            align: 'right',
          },
          {
            label: 'Fri',
            flex: 5,
            value: convertValueToHoursAndMinutes(
              metrics[employee.id]?.timeOff?.hours || 0
            ),
            sortBy: metrics[employee.id]?.timeOff?.hours || 0,
            align: 'right',
          },
          {
            label: 'Ferie',
            flex: 5,
            value: convertValueToHoursAndMinutes(
              metrics[employee.id]?.holidays?.hours || 0
            ),
            sortBy: metrics[employee.id]?.holidays?.hours || 0,
            align: 'right',
          },
          {
            label: 'U.f. normtid',
            value: convertValueToHoursAndMinutes(
              metrics[employee.id]?.overtime?.hours || 0
            ),
            sortBy: metrics[employee.id]?.overtime?.hours || 0,
            align: 'right',
          },
          {
            label: 'Gns. Uge',
            value: (
              <div
                className={`timeregistration-user-metrics-row-average-week ${
                  (metrics[employee.id]?.avgHoursPerWeek || 0) >= 48
                    ? 'timeregistration-user-metrics-row-average-week--warning'
                    : ''
                }`}
              >
                {convertValueToHoursAndMinutes(
                  metrics[employee.id]?.avgHoursPerWeek || 0
                )}
              </div>
            ),
            sortBy: metrics[employee.id]?.avgHoursPerWeek || 0,
            align: 'right',
          },
        ],
      };
    })
    .filter((item) => item); // Remove null entries

  return (
    <div className="timeregistration-user-metrics">
      <List
        hideListActions
        head={[
          { label: 'Medarbejder', sortable: false, flex: 20 },
          { label: 'Åbne uger', sortable: false, align: 'right' },
          { label: 'Arbejdstid', sortable: false, flex: 15, align: 'right' },
          { label: 'Sygdom', sortable: false, align: 'right' },
          { label: 'Fri', sortable: false, flex: 5, align: 'right' },
          { label: 'Ferie', sortable: false, flex: 5, align: 'right' },
          { label: 'U.f. normtid', sortable: false, align: 'right' },
          {
            label: 'Gns. Uge',
            sortable: false,
            align: 'right',
          },
        ]}
        items={items}
      />
    </div>
  );
}

export default TimeRegistrationUserMetrics;
