import { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  UserContext,
  UserInfoContext,
  EmployeesContext,
  SubscriptionContext,
} from '../../context';
import PageTop from '../../components/page-top/page-top';
import Tabs from '../../components/tabs/tabs';
import Button from '../../components/button/button';
import EmployeeFields from './employee-fields';
import EmployeeDocuments from './employee-documents';
import EmployeeAgreements from './employee-agreements';
import EmployeeEquipment from './employee-equipment';
import EmployeeSettings from './employee-settings';

export default function EmployeeProfileView() {
  const [activeTab, setActiveTab] = useState('Profil');
  const user = useContext(UserContext);
  const userInfo = useContext(UserInfoContext);
  const { subscription } = useContext(SubscriptionContext);
  const employees = useContext(EmployeesContext);
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    cprNumber: '',
    dateOfBirth: '',
    note: '',
    email: '',
    phone: '',
    privateEmail: '',
    privatePhone: '',
    address: '',
    zipcode: '',
    city: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    jobPosition: '',
    department: '',
    employmentType: '',
    status: '',
    startDate: '',
    endDate: '',
    salary: '',
    bankAccount: '',
    salaryNote: '',
  });

  const paidSubscription =
    subscription?.name === 'premium' || subscription?.name === 'premiumplus';

  // Update the active tab and the URL when the tab changes
  const updateActiveTab = (newTab) => {
    setActiveTab(newTab);

    // Update the URL to reflect the new active tab
    const basePath = `/medarbejdere/${employeeId}`;
    switch (newTab) {
      case 'Dokumenter':
        navigate(`${basePath}/dokumenter`, { replace: true });
        break;
      case 'Aftaler':
        navigate(`${basePath}/aftaler`, { replace: true });
        break;
      case 'Udstyr':
        navigate(`${basePath}/udstyr`, { replace: true });
        break;
      case 'Indstillinger':
        navigate(`${basePath}/indstillinger`, { replace: true });
        break;
      case 'Profil':
      default:
        navigate(`${basePath}/profil`, { replace: true });
    }
  };

  // Fetch employee data from context
  useEffect(() => {
    if (employees.length === 0) {
      return;
    }

    const employeeDataFromContext = employees.find(
      (employee) => employee.id === employeeId
    )?.data;

    setValues({
      ...employeeDataFromContext,
    });
  }, [employeeId, employees, navigate]);

  // Determine the active tab based on the URL path
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const tabSegment = pathSegments[pathSegments.length - 1]; // Assuming the last segment is the tab name
    switch (tabSegment) {
      case 'dokumenter':
        setActiveTab('Dokumenter');
        break;
      case 'aftaler':
        setActiveTab('Aftaler');
        break;
      case 'udstyr':
        setActiveTab('Udstyr');
        break;
      case 'indstillinger':
        setActiveTab('Indstillinger');
        break;
      case 'profil':
      default:
        setActiveTab('Profil');
    }
  }, [location]);

  // Redirect to employee list if employee is not in employees
  useEffect(() => {
    const subscriptionName = subscription?.name || '';
    const limit =
      subscriptionName === 'premium'
        ? 101
        : subscriptionName === 'premiumplus'
        ? 1000000
        : 11;

    // Sort first by date_created (in case we need to limit the number of employees), then by firstName
    const sortedEmployees = employees.sort((a, b) => {
      if (a.data.date_created > b.data.date_created) return 1;
      if (a.data.date_created < b.data.date_created) return -1;
      if (a.data.firstName < b.data.firstName) return -1;
      if (a.data.firstName > b.data.firstName) return 1;
      return 0;
    });

    // Limit the number of employees to 11 for freemium users (11 because we want to display 10 employees plus one more to tease the user to upgrade)
    const limitedEmployees = sortedEmployees.slice(0, limit);
    if (!limitedEmployees.find((employee) => employee.id === employeeId)) {
      navigate('/medarbejdere');
    }
  }, [employeeId, employees, navigate, subscription]);

  return (
    <div className="main-layout">
      {!values.firstName ? (
        <>
          <h1>Ingen medarbejder fundet</h1>
          <Button buttonStyle="text-link" href="/medarbejdere">
            Tilbage til medarbejdere
          </Button>
        </>
      ) : (
        <>
          <PageTop
            title={`${values.firstName} ${values.lastName}`}
            actions={
              <Button
                href={`/medarbejdere/${employeeId}/indstillinger`}
                buttonStyle="secondary"
              >
                Indstillinger
              </Button>
            }
          />
          <Tabs
            tabs={[
              {
                label: 'Profil',
              },
              {
                label: 'Dokumenter',
              },
              {
                label: 'Aftaler',
              },
              {
                label: 'Udstyr',
                hide: !paidSubscription,
              },
            ]}
            activeTab={activeTab}
            setActiveTab={updateActiveTab}
          />

          {activeTab === 'Profil' && (
            <EmployeeFields
              user={user}
              userInfo={userInfo}
              employeeId={employeeId}
              navigate={navigate}
              initialValues={values}
              setInitialValues={setValues}
            />
          )}
          {activeTab === 'Dokumenter' && (
            <EmployeeDocuments
              employeeData={values}
              userInfo={userInfo}
              employeeId={employeeId}
            />
          )}
          {activeTab === 'Aftaler' && (
            <EmployeeAgreements
              employeeData={values}
              userInfo={userInfo}
              employeeId={employeeId}
            />
          )}
          {paidSubscription && activeTab === 'Udstyr' && (
            <EmployeeEquipment userInfo={userInfo} employeeId={employeeId} />
          )}
          {activeTab === 'Indstillinger' && (
            <EmployeeSettings
              user={user}
              initialValues={values}
              setInitialValues={setValues}
              employeeId={employeeId}
            />
          )}
        </>
      )}
    </div>
  );
}
