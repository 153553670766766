import { useContext, useState } from 'react';
import './recruitment-view.css';
import { SubscriptionContext, UserInfoContext } from '../../context.js';
import { Panels, Panel } from '../../components/panel/panel';
import Button from '../../components/button/button';
import PageTop from '../../components/page-top/page-top.jsx';
import SubscriptionPlansModal from '../subscription/subscription-plans-modal';
import RecruitmentContentPanel from './recruitment-content-panel.jsx';
import RecruitmentContentServiceItem from './recruitment-content-service-item.jsx';
import RecruitmentContent from './recruitment-content.json';
import RecruitmentImage1 from './recruitment-1.webp';
import RecruitmentImage2 from './recruitment-2.webp';
import RecruitmentImage3 from './recruitment-3.webp';
import RecruitmentImage4 from './recruitment-4.webp';
import RecruitmentImage5 from './recruitment-5.webp';
import RecruitmentImage6 from './recruitment-6.webp';
import Icon from '../../components/icon/icon.jsx';

const contactLink =
  'mailto:kontakt@hrfamly.dk?subject=Henvendelse%20på%20rekruttering';
const recruitmentPortalLink = 'https://login.talentech.io/';

export default function RecruitmentView() {
  const userInfo = useContext(UserInfoContext);
  const { subscription } = useContext(SubscriptionContext);
  const [isPlansModalOpen, setIsPlansModalOpen] = useState(false);

  // Wait until we have the user data and the subscription data
  if (!userInfo) return null;
  const hrfamlyContact = userInfo?.companyData?.hrfamlyContact;
  const recruitmentPlatformActivated =
    userInfo?.companyData?.recruitmentPlatformActivated;
  const plan = subscription?.name || 'freemium';

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="main-layout">
      <PageTop title="Rekruttering" />
      {plan === 'freemium' ? (
        <Panels sameHeight>
          <Panel columns={50} noPadding>
            <RecruitmentContentPanel hasPadding imageBackground>
              <div className="recruitment-content-panel__image">
                <img src={RecruitmentImage1} width={608} height={303} alt="" />
              </div>
              <div className="recruitment-content-panel__content">
                <div className="recruitment-content-panel__content__inner">
                  <h3>Rekrutteringsydelser fra betterpeople</h3>
                  <p>
                    Gør brug af vores erfaring og ekspertise hvis I ikke selv
                    har tid, ressourcer eller kompetencerne til at varetage alle
                    led i rekrutteringsprocesserne i jeres virksomhed. Vi kan
                    hjælpe med den eller de dele af rekrutteringsprocessen, som
                    I finder nødvendigt.
                    <br />
                    <br />
                    Hos betterpeople er vi kendte for vores stærke search- og
                    headhunt team, som kan hjælpe hvis jeres udfordring, er at
                    tiltrække kompetente kandidater.
                  </p>
                </div>

                <div className="recruitment-content-panel__actions">
                  <Button href={contactLink} target="_blank" className="small">
                    Kontakt os
                  </Button>
                  <Button
                    onClick={() => scrollTo('ydelser')}
                    className="small"
                    buttonStyle="secondary"
                  >
                    Læs mere
                  </Button>
                </div>
              </div>
            </RecruitmentContentPanel>
          </Panel>
          <Panel columns={50} noPadding>
            <RecruitmentContentPanel hasPadding imageBackground>
              <div className="recruitment-content-panel__image">
                <img src={RecruitmentImage2} width={512} height={325} alt="" />
              </div>
              <div className="recruitment-content-panel__content">
                <div className="recruitment-content-panel__content__inner">
                  <h3>Rekrutteringsportal</h3>
                  <p>
                    Som Premium bruger får I adgang til en brugervenlig og
                    professionel portal, som letter rekrutteringsprocesserne
                    betydeligt og giver overblik. Vi opsætter jer i systemet og
                    hjælper jer godt i gang.
                  </p>
                  <p>
                    Herefter kan I enten selv køre processerne - eller vi kan
                    hjælpe jer.
                  </p>
                </div>

                <div className="recruitment-content-panel__actions">
                  <Button
                    onClick={() => setIsPlansModalOpen(true)}
                    className="small"
                  >
                    Opgrader til Premium
                  </Button>
                </div>
              </div>
            </RecruitmentContentPanel>
          </Panel>
        </Panels>
      ) : (
        <Panels sameHeight>
          <Panel columns={65} noPadding>
            <RecruitmentContentPanel
              hasPadding
              imageBackground
              contentAlign="center"
            >
              <div className="recruitment-content-panel__image">
                <img src={RecruitmentImage2} width={512} height={325} alt="" />
              </div>
              <div className="recruitment-content-panel__content">
                <div className="recruitment-content-panel__content__inner">
                  <span
                    className={`recruitment-content-panel__active-label recruitment-content-panel__active-label--${
                      recruitmentPlatformActivated ? 'activated' : 'inactive'
                    }`}
                  >
                    {recruitmentPlatformActivated
                      ? 'Aktiveret'
                      : 'Ikke aktiveret'}
                  </span>
                  <h3>Rekrutteringsportal</h3>
                  {!recruitmentPlatformActivated && (
                    <p>
                      Tag kontakt til jeres kontaktperson for at aktivere
                      rekrutteringsplatformen.
                    </p>
                  )}
                </div>

                <div className="recruitment-content-panel__actions recruitment-content-panel__actions--no-margin">
                  {recruitmentPlatformActivated && (
                    <Button
                      href={recruitmentPortalLink}
                      target="_blank"
                      className="small"
                    >
                      Gå til rekrutteringsportal
                    </Button>
                  )}
                </div>
              </div>
            </RecruitmentContentPanel>
          </Panel>
          <Panel columns={35} noPadding>
            <RecruitmentContentPanel hasPadding>
              <div className="recruitment-content-panel-contact">
                <div className="recruitment-content-panel-contact-image">
                  {hrfamlyContact && (
                    <div className="recruitment-content-panel-contact-image__wrapper">
                      <img
                        src={hrfamlyContact.imageUrl}
                        width={170}
                        height={170}
                        alt=""
                      />
                    </div>
                  )}
                  <svg
                    width="196"
                    height="37"
                    viewBox="0 0 196 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M54.6951 31.8676H52.4121V14.1415H54.6951V21.752C55.0122 21.1812 55.4878 20.7056 56.1854 20.325C56.8512 19.9445 57.6756 19.7543 58.5952 19.7543C60.3074 19.7543 61.6391 20.325 62.5904 21.4983C63.5416 22.6716 64.0172 24.1303 64.0172 25.9378C64.0172 27.7453 63.5416 29.2357 62.5587 30.4406C61.5757 31.6139 60.244 32.2164 58.5318 32.2164C56.7561 32.2164 55.4878 31.5505 54.7268 30.187V31.8676H54.6951ZM60.6879 28.982C61.3221 28.2209 61.6391 27.2062 61.6391 25.9061C61.6391 24.6377 61.3221 23.6229 60.6879 22.8936C60.0537 22.1642 59.2293 21.7837 58.1513 21.7837C57.1366 21.7837 56.2805 22.1642 55.6146 22.9253C54.9488 23.6863 54.6317 24.6694 54.6317 25.9378C54.6317 27.2062 54.9488 28.2209 55.6146 28.982C56.2805 29.743 57.1049 30.1553 58.1513 30.1553C59.1976 30.1553 60.0537 29.743 60.6879 28.982Z"
                      fill="#F5F7F3"
                    />
                    <path
                      d="M75.207 27.8406L77.2046 28.5383C76.8559 29.6164 76.2217 30.536 75.3022 31.2336C74.3826 31.9313 73.2411 32.2801 71.9094 32.2801C70.2606 32.2801 68.8654 31.7093 67.7239 30.5677C66.5825 29.4261 66.0117 27.904 66.0117 25.9697C66.0117 24.1622 66.5508 22.7036 67.6922 21.4986C68.802 20.3253 70.1338 19.7228 71.6557 19.7228C73.4314 19.7228 74.8265 20.2936 75.8095 21.4034C76.7924 22.5133 77.2998 24.0037 77.2998 25.9063C77.2998 26.1917 77.2681 26.4454 77.2363 26.6356H68.3581C68.3898 27.6821 68.7069 28.5383 69.4045 29.2042C70.0703 29.8701 70.9265 30.2189 71.9094 30.2189C73.5582 30.2189 74.668 29.4262 75.207 27.8406ZM68.4215 24.7647H74.8582C74.8265 23.8768 74.5412 23.1475 73.9704 22.6084C73.3997 22.0376 72.6387 21.7522 71.624 21.7522C70.7045 21.7522 69.9435 22.0376 69.3728 22.6401C68.7703 23.2426 68.4532 23.972 68.4215 24.7647Z"
                      fill="#F5F7F3"
                    />
                    <path
                      d="M83.2569 16.3923V20.1025H85.7935V22.1953H83.2569V28.3154C83.2569 28.8862 83.3837 29.2668 83.6374 29.5521C83.8911 29.8058 84.3033 29.9327 84.9057 29.9327C85.2545 29.9327 85.5399 29.901 85.8252 29.8375V31.8036C85.4447 31.9621 84.9057 32.0256 84.2716 32.0256C83.2569 32.0256 82.4642 31.7402 81.8617 31.1377C81.2593 30.5352 80.9739 29.7107 80.9739 28.6643V22.1953H78.7227V20.1025H79.3568C79.9593 20.1025 80.4032 19.9439 80.7203 19.5951C81.0373 19.2463 81.1642 18.834 81.1642 18.295V16.3923H83.2569Z"
                      fill="#F5F7F3"
                    />
                    <path
                      d="M91.9503 16.3923V20.1025H94.4869V22.1953H91.9503V28.3154C91.9503 28.8862 92.0771 29.2668 92.3308 29.5521C92.5844 29.8058 92.9966 29.9327 93.5991 29.9327C93.9479 29.9327 94.2332 29.901 94.5186 29.8375V31.8036C94.1381 31.9621 93.5991 32.0256 92.9649 32.0256C91.9503 32.0256 91.1576 31.7402 90.5551 31.1377C89.9527 30.5352 89.6673 29.7107 89.6673 28.6643V22.1953H87.416V20.1025H88.0502C88.6526 20.1025 89.0965 19.9439 89.4136 19.5951C89.7307 19.2463 89.8575 18.834 89.8575 18.295V16.3923H91.9503Z"
                      fill="#F5F7F3"
                    />
                    <path
                      d="M105.713 27.8404L107.71 28.538C107.362 29.6162 106.728 30.5358 105.808 31.2334C104.888 31.931 103.747 32.2798 102.415 32.2798C100.766 32.2798 99.3713 31.709 98.2298 30.5675C97.0883 29.4259 96.5176 27.9038 96.5176 25.9695C96.5176 24.162 97.0566 22.7033 98.1981 21.4983C99.3079 20.325 100.64 19.7225 102.162 19.7225C103.937 19.7225 105.332 20.2933 106.315 21.4032C107.298 22.513 107.806 24.0034 107.806 25.906C107.806 26.1914 107.774 26.4451 107.742 26.6354H98.864C98.8957 27.6818 99.2128 28.538 99.9103 29.2039C100.576 29.8698 101.432 30.2186 102.415 30.2186C104.096 30.2186 105.206 29.4259 105.713 27.8404ZM98.9591 24.7645H105.396C105.364 23.8766 105.079 23.1472 104.508 22.6082C103.937 22.0374 103.176 21.752 102.162 21.752C101.242 21.752 100.481 22.0374 99.9103 22.6399C99.3079 23.2424 98.9908 23.9717 98.9591 24.7645Z"
                      fill="#F5F7F3"
                    />
                    <path
                      d="M117.251 19.976V22.4177C116.902 22.3542 116.585 22.3542 116.236 22.3542C114.08 22.3542 113.002 23.5592 113.002 25.9692V31.8673H110.688V20.1028H112.939V22.164C113.668 20.6736 114.841 19.9125 116.458 19.9125C116.744 19.9125 116.997 19.9442 117.251 19.976Z"
                      fill="#F5F7F3"
                    />
                    <path
                      d="M122.741 36.529H119.475V19.8176H122.645V21.2763C122.931 20.8007 123.406 20.3884 124.072 20.0396C124.738 19.6908 125.499 19.5323 126.355 19.5323C128.067 19.5323 129.431 20.103 130.414 21.2763C131.397 22.4496 131.904 23.94 131.904 25.7792C131.904 27.6184 131.365 29.1405 130.319 30.3455C129.272 31.5505 127.909 32.1213 126.228 32.1213C124.643 32.1213 123.47 31.6456 122.741 30.6626V36.529ZM127.846 28.3477C128.416 27.7452 128.702 26.9208 128.702 25.8743C128.702 24.8279 128.416 24.0034 127.846 23.4009C127.275 22.7984 126.577 22.4813 125.689 22.4813C124.833 22.4813 124.104 22.7984 123.533 23.4009C122.962 24.0034 122.677 24.8279 122.677 25.8743C122.677 26.8891 122.962 27.7135 123.533 28.3477C124.104 28.9819 124.833 29.2673 125.689 29.2673C126.577 29.2673 127.275 28.9502 127.846 28.3477Z"
                      fill="#F5F7F3"
                    />
                    <path
                      d="M142.563 27.6187L145.29 28.4114C144.973 29.5213 144.307 30.4409 143.356 31.1385C142.405 31.8678 141.2 32.2167 139.805 32.2167C138.061 32.2167 136.602 31.6459 135.397 30.4726C134.192 29.2993 133.59 27.7455 133.59 25.7794C133.59 23.9085 134.192 22.3864 135.365 21.2132C136.539 20.0399 137.934 19.4374 139.519 19.4374C141.39 19.4374 142.849 20.0082 143.895 21.0863C144.941 22.1962 145.48 23.7183 145.48 25.6526C145.48 25.7794 145.48 25.938 145.48 26.0966C145.48 26.2551 145.48 26.3819 145.48 26.4771L145.449 26.6356H136.824C136.856 27.4284 137.173 28.0626 137.744 28.6017C138.346 29.109 139.044 29.3944 139.836 29.3944C141.2 29.4578 142.119 28.8554 142.563 27.6187ZM136.856 24.4793H142.246C142.214 23.8134 141.961 23.2426 141.517 22.7987C141.073 22.3547 140.407 22.101 139.551 22.101C138.758 22.101 138.124 22.3547 137.648 22.8304C137.141 23.3378 136.887 23.8768 136.856 24.4793Z"
                      fill="#F5F7F3"
                    />
                    <path
                      d="M148.901 21.2757C150.106 20.0707 151.596 19.4682 153.404 19.4682C155.211 19.4682 156.733 20.0707 157.906 21.2757C159.111 22.4807 159.714 24.0028 159.714 25.842C159.714 27.6812 159.111 29.2033 157.906 30.4083C156.701 31.6133 155.211 32.2158 153.404 32.2158C151.596 32.2158 150.074 31.6133 148.901 30.4083C147.696 29.2033 147.094 27.6812 147.094 25.842C147.094 24.0028 147.696 22.4807 148.901 21.2757ZM151.248 28.3789C151.85 28.9814 152.579 29.2985 153.404 29.2985C154.26 29.2985 154.989 28.9814 155.56 28.3789C156.162 27.7764 156.448 26.9202 156.448 25.842C156.448 24.7639 156.162 23.9394 155.56 23.3052C154.957 22.7027 154.228 22.3856 153.404 22.3856C152.548 22.3856 151.818 22.7027 151.248 23.3052C150.645 23.9077 150.36 24.7639 150.36 25.842C150.36 26.9202 150.645 27.7764 151.248 28.3789Z"
                      fill="#F5F7F3"
                    />
                    <path
                      d="M165.573 36.529H162.307V19.8176H165.477V21.2763C165.763 20.8007 166.238 20.3884 166.904 20.0396C167.57 19.6908 168.331 19.5323 169.187 19.5323C170.9 19.5323 172.263 20.103 173.246 21.2763C174.229 22.4496 174.736 23.94 174.736 25.7792C174.736 27.6184 174.197 29.1405 173.151 30.3455C172.104 31.5505 170.741 32.1213 169.06 32.1213C167.475 32.1213 166.302 31.6456 165.573 30.6626V36.529ZM170.678 28.3477C171.248 27.7452 171.534 26.9208 171.534 25.8743C171.534 24.8279 171.248 24.0034 170.678 23.4009C170.107 22.7984 169.409 22.4813 168.521 22.4813C167.665 22.4813 166.936 22.7984 166.365 23.4009C165.795 24.0034 165.509 24.8279 165.509 25.8743C165.509 26.8891 165.795 27.7135 166.365 28.3477C166.936 28.9819 167.665 29.2673 168.521 29.2673C169.378 29.2673 170.107 28.9502 170.678 28.3477Z"
                      fill="#F5F7F3"
                    />
                    <path
                      d="M180.557 31.8676H177.291V14.1415H180.557V31.8676Z"
                      fill="#F5F7F3"
                    />
                    <path
                      d="M192.124 27.6187L194.851 28.4114C194.534 29.5213 193.868 30.4409 192.916 31.1385C191.965 31.8678 190.76 32.2167 189.365 32.2167C187.621 32.2167 186.163 31.6459 184.958 30.4726C183.753 29.2993 183.15 27.7455 183.15 25.7794C183.15 23.9085 183.753 22.3864 184.926 21.2132C186.099 20.0399 187.494 19.4374 189.08 19.4374C190.951 19.4374 192.409 20.0082 193.455 21.0863C194.502 22.1962 195.041 23.7183 195.041 25.6526C195.041 25.7794 195.041 25.938 195.041 26.0966C195.041 26.2551 195.041 26.3819 195.041 26.4771L195.009 26.6356H186.385C186.416 27.4284 186.733 28.0626 187.304 28.6017C187.907 29.109 188.604 29.3944 189.397 29.3944C190.76 29.4578 191.68 28.8554 192.124 27.6187ZM186.416 24.4793H191.807C191.775 23.8134 191.521 23.2426 191.077 22.7987C190.633 22.3547 189.968 22.101 189.111 22.101C188.319 22.101 187.685 22.3547 187.209 22.8304C186.702 23.3378 186.448 23.8768 186.416 24.4793Z"
                      fill="#F5F7F3"
                    />
                    <path
                      d="M32.8822 32.4067C28.2845 32.4067 24.543 28.6649 24.543 24.0669V17.2809C24.543 16.4247 25.2405 15.7271 26.0967 15.7271H32.8822C37.4798 15.7271 41.2214 19.4689 41.2214 24.0669C41.1897 28.6649 37.4798 32.4067 32.8822 32.4067Z"
                      fill="#C0E5A1"
                    />
                    <path
                      d="M11.1929 -0.000976562C17.376 -0.000976562 22.3858 5.00926 22.3858 11.1928V20.2937C22.3858 21.4352 21.4663 22.3548 20.3248 22.3548H11.1929C5.00986 22.3548 0 17.3446 0 11.1611C0.031708 5.00926 5.00986 -0.000976562 11.1929 -0.000976562Z"
                      fill="#152E1F"
                    />
                  </svg>
                </div>

                {hrfamlyContact ? (
                  <div className="recruitment-content-panel-contact-content">
                    <span className="recruitment-content-panel-contact-content__label">
                      Jeres kontaktperson
                    </span>
                    <h4>{hrfamlyContact.name}</h4>
                    <span className="recruitment-content-panel-contact-content__title">
                      {hrfamlyContact.title}
                    </span>
                    <a href={`tel:${hrfamlyContact.phone}`}>
                      {hrfamlyContact.phone}
                    </a>
                    <a href={`mailto:${hrfamlyContact.email}`}>
                      {hrfamlyContact.email}
                    </a>
                  </div>
                ) : (
                  <div className="recruitment-content-panel-contact-content">
                    <h4>Vi tildeler en kontaktperson til dig snarest.</h4>
                  </div>
                )}
              </div>
            </RecruitmentContentPanel>
          </Panel>
        </Panels>
      )}
      <Panels>
        <Panel columns={100}>
          <RecruitmentContentPanel imageAlign="right">
            <div className="recruitment-content-panel__image">
              <img width={543} height={605} src={RecruitmentImage3} alt="" />
            </div>
            <div className="recruitment-content-panel__content">
              <div className="recruitment-content-panel__content__inner">
                <span className="recruitment-content-panel__content__small">
                  Rekruttering
                </span>
                <h1>Rekruttering fra betterpeople</h1>
                <p>
                  Vi kan klare hele processen fra A-Z for jer eller blot den
                  eller de dele af rekrutteringsprocessen, som I finder
                  nødvendigt.
                </p>
                <p>Når I får brug for flere hænder, er vi her for jer.</p>
                <div className="recruitment-content-panel__actions">
                  <Button href={contactLink} target="_blank" className="small">
                    Kontakt os
                  </Button>
                </div>
              </div>
            </div>
          </RecruitmentContentPanel>
          <RecruitmentContentPanel>
            <div className="recruitment-content-panel-columns">
              <div className="recruitment-content-panel-columns__item">
                <Icon name={'handshake'} />
                <h4>Tilpasset jeres behov</h4>
                <p>
                  Vælger du vores Premium abonnement, tilbyder vi adgang til en
                  rekrutteringsportal, hvor I enten selv kan køre processerne
                  eller hvor vi kan gøre arbejdet og I blot har adgang og kan
                  følge med på sidelinjen.
                </p>
              </div>
              <div className="recruitment-content-panel-columns__item">
                <Icon name={'contact_phone'} />
                <h4>Erfaring og personlig kontakt</h4>
                <p>
                  Vi har solid erfaring inden for rekruttering og headhunt. Vi
                  tildeler jer en kontaktperson, så I har let og hurtigt adgang
                  til en person, som kender jeres virksomhed og specifikke
                  behov.
                </p>
              </div>
              <div className="recruitment-content-panel-columns__item">
                <Icon name={'summarize'} />
                <h4>Fordelagtige priser</h4>
                <p>
                  Premium+ løsningen giver endvidere fordelagtige priser på alle
                  vores rekrutteringsydelser.
                </p>
              </div>
            </div>
          </RecruitmentContentPanel>
          {plan === 'freemium' && (
            <>
              <RecruitmentContentPanel imageAlign="left">
                <div className="recruitment-content-panel__image">
                  <img
                    width={544}
                    height={478}
                    src={RecruitmentImage4}
                    alt=""
                  />
                </div>
                <div className="recruitment-content-panel__content">
                  <div className="recruitment-content-panel__content__inner">
                    <h3>Jeres fordele</h3>
                    <p>
                      <strong>Vores erfaring og ekspertise</strong> Vi kan
                      hjælpe med at reducere ansættelsestiden og øge
                      sandsynligheden for at finde de mest kvalificerede
                      kandidater.
                    </p>
                    <p>
                      <strong>Spar tid</strong> - Lad os stå for rekrutteringen,
                      så I kan spare tid, da vi håndterer alt fra jobannoncering
                      og screening til interviews og referencekontrol.
                    </p>
                    <p>
                      <strong>Bredt netværk</strong> - Vi har et bredt netværk
                      af kvalificerede kandidater. Det giver jer adgang til en
                      større talentpulje, herunder passive jobansøgere, som
                      måske ikke er aktivt jobsøgende.
                    </p>
                    <p>
                      <strong>Præcise værktøjer</strong> - Lad os bruge vores
                      ressourcer og værktøjer til at fortage udførlige
                      screeningsprocesser, samt evaluering af faglige
                      kompetencer, personlighedstræk og kulturel tilpasning,
                      hvilket resulterer i mere præcise ansættelser.
                    </p>
                  </div>
                </div>
              </RecruitmentContentPanel>
              <RecruitmentContentPanel imageAlign="right">
                <div className="recruitment-content-panel__image">
                  <img
                    width={544}
                    height={544}
                    src={RecruitmentImage5}
                    alt=""
                  />
                </div>
                <div className="recruitment-content-panel__content">
                  <div className="recruitment-content-panel__content__inner">
                    <p>
                      <strong>Fortrolighed og diskretion</strong> - Har I brug
                      for at besætte en følsom position eller ønsker at undgå
                      intern konkurrence, kan vi gøre arbejdet for jer.
                    </p>
                    <p>
                      <strong>Kvalitetskandidater</strong> - Ved at have en
                      dedikeret partner kan I forbedre kvaliteten af jeres
                      ansættelser og mindske risikoen for fejlrekruttering, som
                      kan være forbundet med mange omkostninger.
                    </p>
                    <p>
                      <strong>Omkostningseffektivt</strong> - Det kan samlet set
                      være mere omkostningseffektivt at bruge vores ydelser end
                      at gøre alt internt. Tid er penge, og en hurtigere
                      ansættelsesproces kan resultere i økonomiske besparelser.
                    </p>
                    <p>
                      <strong>Gør det I er bedst til</strong> - Ved at lade os
                      varetage rekrutteringsprocessen, kan I frigøre interne
                      ressourcer og fokusere på jeres kernekompetencer og
                      forretningsmål.
                    </p>
                    <p>
                      <strong>Support og opfølgning</strong> - Efter ansættelsen
                      tilbyder vi løbende support og opfølgning, for at sikre en
                      glidende integration af den nye medarbejder hos jer.
                    </p>
                  </div>
                </div>
              </RecruitmentContentPanel>
              <RecruitmentContentPanel>
                <div className="recruitment-content-panel-cta">
                  <Icon name="handshake" />
                  <h3>Jeres behov bestemmer forløbet </h3>
                  <p>
                    Har I ikke brug for et komplet rekrutteringsforløb, men blot
                    assistance til enkelte dele, så kan vi skræddersy et forløb
                    til jer, som passer ind i det rekrutteringsbehov som I ser
                    ind i. Det kan være, at I selv afholder samtaler og test, så
                    sørger vi blot for at bringe kvalificerede kandidater til
                    bordet ved search og headhunt.
                  </p>
                  <Button href={contactLink} target="_blank">
                    Kontakt os
                  </Button>
                </div>
              </RecruitmentContentPanel>
            </>
          )}
          <RecruitmentContentPanel>
            <h3>Rekrutteringsprocessen</h3>
            <p>Vi er eksperter i hele rekrutteringsprocessen</p>
            <div className="recruitment-content-panel-process">
              <div className="recruitment-content-panel-process__item">
                <h4>Identificering af behov</h4>
                <ul>
                  <li>Jobprofilanalyse</li>
                  <li>Kultur, kompetencer og personlighed </li>
                  <li>Rekrutteringsstrategi</li>
                </ul>
              </div>
              <div className="recruitment-content-panel-process__item">
                <h4>Tiltrækning</h4>
                <ul>
                  <li>Stillingsbeskrivelse</li>
                  <li>Behandling af ansøgere</li>
                  <li>Search & headhunt</li>
                </ul>
              </div>
              <div className="recruitment-content-panel-process__item">
                <h4>Udvælgelse</h4>
                <ul>
                  <li>Indledende screening </li>
                  <li>Dybdeinterview</li>
                  <li>Personligheds- og kognitiv test</li>
                  <li>Struktureret telefonsamtale</li>
                  <li>Adfærdsbaseret samtale</li>
                </ul>
              </div>
              <div className="recruitment-content-panel-process__item">
                <h4>Præsentation</h4>
                <ul>
                  <li>Digital præsentation</li>
                  <li>Vurdering</li>
                </ul>
              </div>
              <div className="recruitment-content-panel-process__item">
                <h4>Verificering</h4>
                <ul>
                  <li>Kandidatsamtaler med nøglepersoner fra virksomhed</li>
                  <li>Referencetjek</li>
                  <li>Case test</li>
                </ul>
              </div>
              <div className="recruitment-content-panel-process__item">
                <h4>Ansættelse</h4>
                <ul>
                  <li>Kontraktunderskrift</li>
                  <li>Rådgivning om onboarding</li>
                  <li>Opfølgning efter endt rekrutteringsproces</li>
                </ul>
              </div>
            </div>
          </RecruitmentContentPanel>
          <RecruitmentContentPanel id="ydelser">
            <div className="recruitment-content-panel-services-header">
              <h3>Ydelser</h3>
              <span className="recruitment-content-panel-services-header__small">
                Pris i DKK pr. stilling
              </span>
            </div>
            <div className="recruitment-content-panel-services-list">
              {RecruitmentContent.services.map((service, index) => (
                <RecruitmentContentServiceItem
                  key={index}
                  service={service}
                  plan={plan}
                  contactLink={contactLink}
                />
              ))}
            </div>
            {plan === 'freemium' && (
              <p>
                Kontakt os her for at få en vejledende prisliste på
                rekrutteringsydelser.
              </p>
            )}
            {(plan === 'premium' || plan === 'premiumplus') && (
              <p>
                Priserne er vejledende og er baseret på generelle markedsvilkår.
                Priserne dækker funktionæransatte op til ledelsesniveau. For
                profiler ud over dette niveau tilbyder vi skræddersyede og
                konkurrencedygtige priser efter individuel vurdering.
              </p>
            )}
          </RecruitmentContentPanel>

          {plan !== 'freemium' && (
            <>
              <RecruitmentContentPanel>
                <div className="recruitment-content-panel-cta">
                  <Icon name="handshake" />
                  <h3>Jeres behov bestemmer forløbet </h3>
                  <p>
                    Har I ikke brug for et komplet rekrutteringsforløb, men blot
                    assistance til enkelte dele, så kan vi skræddersy et forløb
                    til jer, som passer ind i det rekrutteringsbehov som I ser
                    ind i. Det kan være, at I selv afholder samtaler og test, så
                    sørger vi blot for at bringe kvalificerede kandidater til
                    bordet ved search og headhunt.
                  </p>
                  <Button href={contactLink} target="_blank">
                    Kontakt os
                  </Button>
                </div>
              </RecruitmentContentPanel>
              <RecruitmentContentPanel imageAlign="left">
                <div className="recruitment-content-panel__image">
                  <img
                    width={544}
                    height={478}
                    src={RecruitmentImage4}
                    alt=""
                  />
                </div>
                <div className="recruitment-content-panel__content">
                  <div className="recruitment-content-panel__content__inner">
                    <h3>Jeres fordele</h3>
                    <p>
                      <strong>Vores erfaring og ekspertise</strong> Vi kan
                      hjælpe med at reducere ansættelsestiden og øge
                      sandsynligheden for at finde de mest kvalificerede
                      kandidater.
                    </p>
                    <p>
                      <strong>Spar tid</strong> - Lad os stå for rekrutteringen,
                      så I kan spare tid, da vi håndterer alt fra jobannoncering
                      og screening til interviews og referencekontrol.
                    </p>
                    <p>
                      <strong>Bredt netværk</strong> - Vi har et bredt netværk
                      af kvalificerede kandidater. Det giver jer adgang til en
                      større talentpulje, herunder passive jobansøgere, som
                      måske ikke er aktivt jobsøgende.
                    </p>
                    <p>
                      <strong>Præcise værktøjer</strong> - Lad os bruge vores
                      ressourcer og værktøjer til at fortage udførlige
                      screeningsprocesser, samt evaluering af faglige
                      kompetencer, personlighedstræk og kulturel tilpasning,
                      hvilket resulterer i mere præcise ansættelser.
                    </p>
                  </div>
                </div>
              </RecruitmentContentPanel>
              <RecruitmentContentPanel imageAlign="right">
                <div className="recruitment-content-panel__image">
                  <img
                    width={544}
                    height={544}
                    src={RecruitmentImage5}
                    alt=""
                  />
                </div>
                <div className="recruitment-content-panel__content">
                  <div className="recruitment-content-panel__content__inner">
                    <p>
                      <strong>Fortrolighed og diskretion</strong> - Har I brug
                      for at besætte en følsom position eller ønsker at undgå
                      intern konkurrence, kan vi gøre arbejdet for jer.
                    </p>
                    <p>
                      <strong>Kvalitetskandidater</strong> - Ved at have en
                      dedikeret partner kan I forbedre kvaliteten af jeres
                      ansættelser og mindske risikoen for fejlrekruttering, som
                      kan være forbundet med mange omkostninger.
                    </p>
                    <p>
                      <strong>Omkostningseffektivt</strong> - Det kan samlet set
                      være mere omkostningseffektivt at bruge vores ydelser end
                      at gøre alt internt. Tid er penge, og en hurtigere
                      ansættelsesproces kan resultere i økonomiske besparelser.
                    </p>
                    <p>
                      <strong>Gør det I er bedst til</strong> - Ved at lade os
                      varetage rekrutteringsprocessen, kan I frigøre interne
                      ressourcer og fokusere på jeres kernekompetencer og
                      forretningsmål.
                    </p>
                    <p>
                      <strong>Support og opfølgning</strong> - Efter ansættelsen
                      tilbyder vi løbende support og opfølgning, for at sikre en
                      glidende integration af den nye medarbejder hos jer.
                    </p>
                  </div>
                </div>
              </RecruitmentContentPanel>
            </>
          )}
          <RecruitmentContentPanel>
            <div className="recruitment-content-panel-cta">
              <Icon name="handshake" />
              <h3>Kontakt os</h3>
              <Button href={contactLink} target="_blank">
                Kontakt os
              </Button>
            </div>
          </RecruitmentContentPanel>
          {plan === 'freemium' && (
            <>
              <RecruitmentContentPanel imageAlign="left">
                <div className="recruitment-content-panel__image">
                  <img
                    width={544}
                    height={478}
                    src={RecruitmentImage6}
                    alt=""
                  />
                </div>
                <div className="recruitment-content-panel__content">
                  <div className="recruitment-content-panel__content__inner">
                    <span className="recruitment-content-panel__content__small">
                      Rekruttering
                    </span>
                    <h1>
                      Rekrutteringsportal er inkluderet i HRfamly Premium{' '}
                    </h1>
                    <p>
                      I får adgang til en brugervenlig og professionel portal,
                      som letter rekrutteringsprocesserne betydeligt og giver
                      overblik. Alt data behandles sikkert, diskret og i henhold
                      til GDPR-lovgivningen.
                    </p>
                    <p>
                      I kan enten selv køre processerne – eller vi kan hjælpe
                      jer.
                    </p>
                  </div>
                  <div className="recruitment-content-panel__actions">
                    <Button
                      onClick={() => setIsPlansModalOpen(true)}
                      className="small"
                    >
                      Opgrader til Premium
                    </Button>
                  </div>
                </div>
              </RecruitmentContentPanel>
              <RecruitmentContentPanel imageAlign="right">
                <div className="recruitment-content-panel__image">
                  <img
                    width={544}
                    height={478}
                    src={RecruitmentImage5}
                    alt=""
                  />
                </div>
                <div className="recruitment-content-panel__content">
                  <div className="recruitment-content-panel__content__inner">
                    <span className="recruitment-content-panel__content__small">
                      Kom godt i gang
                    </span>
                    <h2>Sådan kommer I i gang med HRfamly Premium </h2>
                    <p>
                      <strong>Kontaktperson </strong> - I får tildelt en
                      kontaktperson fra betterpeople, som kommer til at kendes
                      jeres virksomhed.
                    </p>
                    <p>
                      <strong>Hjælp og onboarding</strong> - Vi opsætter jeres
                      rekrutteringsportal og hjælper jer godt i gang.
                    </p>
                    <p>
                      <strong>Gør det selv, eller få vores hjælp </strong> - Vi
                      kan hjælpe jer til at være selvkørende, eller stå for hele
                      eller dele af rekrutteringsprocessen.
                    </p>
                    <p>
                      <strong>Gratis intromøde</strong> - Vi tilbyder gratis
                      intromøde for at læse virksomheden og jeres behov bedre at
                      kende.
                    </p>
                  </div>
                </div>
              </RecruitmentContentPanel>
            </>
          )}
        </Panel>
      </Panels>
      <SubscriptionPlansModal
        isModalOpen={isPlansModalOpen}
        setIsModalOpen={setIsPlansModalOpen}
        companyId={userInfo?.companyId}
        userId={userInfo.id}
      />
    </div>
  );
}
