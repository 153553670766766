import './timeregistration-work-bar.css';
import { convertValueToHoursAndMinutes } from '../../../utils/convertValueToHoursAndMinutes';

function TimeRegistrationWorkBar({ hours, total }) {
  const width = (hours / total) * 100;
  return (
    <div className="timeregistration-work-bar">
      {convertValueToHoursAndMinutes(hours) === '-'
        ? '0t'
        : convertValueToHoursAndMinutes(hours)}

      <div className={`timeregistration-work-bar__graphic`}>
        <div
          className={`timeregistration-work-bar__graphic timeregistration-work-bar__graphic--filled ${
            // If the hours is more than the total, the bar should be red
            hours > total ? 'timeregistration-work-bar__graphic--over' : ''
          }`}
          style={{ width: `${width > 100 ? 100 : width < 0 ? 0 : width}%` }}
        />
      </div>
    </div>
  );
}

export default TimeRegistrationWorkBar;
