import './timeregistration-introduction-modal.css';
import Modal from '../../modal/modal';
import { Link } from 'react-router-dom';

export default function TimeRegistrationIntroductionModal({
  isModalOpen,
  setIsModalOpen,
}) {
  const steps = [
    {
      title: 'Opret din første medarbejder',
      content: (
        <span>
          Start med at oprette dine medarbejdere. Dette gør du
          <Link to={'/medarbejdere/'}> her</Link>.
        </span>
      ),
    },
    {
      title: 'Aktivér tidsregistrering',
      content:
        'Herefter klikker du på Aktivér tidsregistrering og klikker de medarbejdere af, som skal tidsregistrere.',
    },
    {
      title: 'Mail til medarbejder',
      content: (
        <>
          Herefter bliver der sendt en mail til dine medarbejdere med instrukser
          til at oprette kodeord.{' '}
          <strong>Bemærk linket i mailen er kun gyldigt i 24 timer.</strong>
        </>
      ),
    },
    {
      title: 'Hent app',
      content:
        'Efter medarbejderen har oprette kodeord, kan tidsregisteringsappen hentes fra App Store eller Google Play. Der linkes til appen på siden, efter medarbejderen har oprettet et kodeord.',
    },
    {
      title: 'Start tidsregistrering',
      content:
        'Din medarbejder logger nu ind i mobilappen, med mail og kodeord, og er klar til at tidsregistrere.',
    },
  ];

  return (
    <Modal
      customWidth={600}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      header={`Sådan bliver medarbejderne introduceret`}
      content={
        <div className="time-registration-introduction-modal">
          {steps.map((step, index) => (
            <div
              key={index}
              className="time-registration-introduction-modal-item"
            >
              <h5>{step.title}</h5>
              <p>{step.content}</p>
            </div>
          ))}
        </div>
      }
    />
  );
}
