import Button from '../../components/button/button';
import { useEffect, useState } from 'react';
import Logo from '../../components/logo/logo';
import { logout } from '../../utils/logout-function';

export default function MobileAppView() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );
  }, []);

  return (
    <div className="centered-layout">
      <Logo />
      <div className="centered-layout__content">
        <div className="centered-layout__main" style={{ textAlign: 'center' }}>
          <h1>Velkommen til HRfamly</h1>
          <p>
            Du er der næsten📱. For at kunne bruge HRfamly-appen, skal du
            downloade den fra App Store eller Google Play.
          </p>
          <p>
            App'en kan downloades herunder. Du kan også finde HRfamly app'en i
            App Store eller Google Play.
          </p>
          {isMobile ? (
            <Button href="https://link.starti.app/hrfamly">Hent app'en</Button>
          ) : (
            <img
              style={{ width: '100%' }}
              src="https://link.starti.app/hrfamly.svg"
              alt="QR code"
            />
          )}
        </div>
      </div>
      <Button
        buttonStyle="text-link"
        style={{ marginTop: '30px' }}
        onClick={logout}
      >
        Log ud
      </Button>
    </div>
  );
}
