import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import { auth } from '../../firebase-config';
import { useForm } from '../../hooks/useForm';
import Logo from '../../components/logo/logo';
import Button from '../../components/button/button';
import InputField from '../../components/input-field/input-field';
import TagManager from 'react-gtm-module';

const validateSignupForm = (values) => {
  let errors = {};

  if (!values.registerEmail.trim()) errors.registerEmail = 'Email mangler';
  if (!values.registerPassword.trim())
    errors.registerPassword = 'Password mangler';
  if (!values.registerRepeatPassword.trim())
    errors.registerRepeatPassword = 'Gentag password';
  if (!values.acceptTerms) errors.acceptTerms = 'Skal accepteres';

  return errors;
};

export default function SignupView() {
  const navigate = useNavigate();
  const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] =
    useState('');

  const [user, setUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState('');

  // Effect to check user authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setIsLoggedIn(!!currentUser); // Update isLoggedIn state based on currentUser
      setIsLoading(false);
      setCurrentUser(currentUser);
    });

    // Unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  // Function to handle user registration
  const onSubmit = useCallback(
    async (values) => {
      const { registerEmail, registerPassword, registerRepeatPassword } =
        values;

      // Confirm that the passwords match
      if (registerPassword !== registerRepeatPassword) {
        setRepeatPasswordErrorMessage('Passwords skal være ens');
        return;
      }

      try {
        await createUserWithEmailAndPassword(
          auth,
          registerEmail,
          registerPassword
        );

        // Send verification email to the user
        sendEmailVerification(auth.currentUser);

        // Trigger data layer event for user registration
        TagManager.dataLayer({
          dataLayer: {
            event: 'Opret bruger',
            label: 'Udfyldt formular og oprettet bruger',
          },
        });

        // Redirect the user to the verify email view
        navigate('/verify-email');
      } catch (error) {
        console.log(error);
      }
    },
    [navigate] // Add necessary dependencies
  );

  const { values, handleChange, handleSubmit, errors } = useForm(
    {
      registerEmail: '',
      registerPassword: '',
      registerRepeatPassword: '',
      acceptTerms: false,
    },
    validateSignupForm,
    onSubmit
  );

  return (
    <div className="centered-layout">
      <Logo />
      <div className="centered-layout__content">
        <form className="centered-layout__form" onSubmit={handleSubmit}>
          <h3>Opret ny bruger</h3>
          <InputField
            name="registerEmail"
            label="Email"
            required
            type="email"
            placeholder="Skriv din email"
            onChange={handleChange}
            value={values.registerEmail}
            error={errors.registerEmail}
          />

          <InputField
            name="registerPassword"
            label="Kodeord"
            required
            type="password"
            placeholder="Skriv dit kodeord"
            onChange={handleChange}
            value={values.registerPassword}
            error={errors.registerPassword}
          />

          <InputField
            name="registerRepeatPassword"
            label="Gentag kodeord"
            required
            type="password"
            placeholder="Gentag dit kodeord"
            onChange={handleChange}
            value={values.registerRepeatPassword}
            error={errors.registerRepeatPassword || repeatPasswordErrorMessage}
          />

          <InputField
            name="acceptTerms"
            type="checkbox"
            label={`Jeg accepterer <a href="https://www.hrfamly.dk/sikkerhed/betingelser/" target="_blank">betingelser</a> og <a href="https://www.hrfamly.dk/sikkerhed/databehandleraftale/" target="_blank">databehandleraftale</a>`}
            required
            onChange={handleChange}
            value={values.acceptTerms}
            error={errors.acceptTerms}
          />
          <Button type="submit">Opret bruger</Button>
        </form>

        <div className="centered-layout__bottom">
          <h4>Har du allerede en bruger?</h4>
          <Button buttonStyle="text-link" href="/">
            Log ind
          </Button>
        </div>
      </div>
    </div>
  );
}
