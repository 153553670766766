import { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SubscriptionContext, UserInfoContext } from '../../context';
import Icon from '../icon/icon';
import { logout } from '../../utils/logout-function';
import './topbar.css';
import Avatar from '../avatar/avatar';
import InfoBox from '../infobox/infobox';

function displayUserMenu() {
  const userMenu = document.querySelector('.user');
  userMenu.classList.toggle('user--active');
}

export default function TopBar() {
  const userInfo = useContext(UserInfoContext);
  const { subscription, onPaySubscription } = useContext(SubscriptionContext);
  const location = useLocation();

  // Determine if the sidebar should be displayed based on the current route
  const showTopBar = !(
    location.pathname.includes('/complete-signup') ||
    location.pathname.includes('/thank-you-upgrade') ||
    location.pathname.includes('/thank-you-downgrade') ||
    location.pathname.includes('/verify-email') ||
    location.pathname.includes('/forgot-password') ||
    location.pathname.includes('/email-action-handler')
  );

  // Close the user menu when the user clicks outside of it or presses the ESC key
  useEffect(() => {
    const closeUserMenu = (e) => {
      const userMenu = document.querySelector('.user');
      if (!userMenu) return;

      if (userMenu.classList.contains('user--active')) {
        if (
          !userMenu.contains(e.target) &&
          e.target.className !== 'user-trigger' &&
          e.target.className !== 'user-trigger-name'
        ) {
          userMenu.classList.remove('user--active');
        }
      }
    };

    window.addEventListener('click', closeUserMenu);
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        const userMenu = document.querySelector('.user');
        userMenu.classList.remove('user--active');
      }
    });

    return () => {
      window.removeEventListener('click', closeUserMenu);
      window.removeEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          const userMenu = document.querySelector('.user');
          userMenu.classList.remove('user--active');
        }
      });
    };
  });

  // Close the user menu when the route changes
  useEffect(() => {
    const userMenu = document.querySelector('.user');
    if (userMenu) {
      userMenu.classList.remove('user--active');
    }
  }, [location]);

  if (!showTopBar) {
    return null;
  }

  const expiryMonth = onPaySubscription?.onPayData?.data?.expiry_month;
  const expiryYear = onPaySubscription?.onPayData?.data?.expiry_year;

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const expiryDate = new Date(expiryYear, expiryMonth - 1);
  expiryDate.setHours(0, 0, 0, 0);

  // Check if the credit card is about to expire in the next 30 days
  const thirtyDaysFromNow = new Date();
  thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
  thirtyDaysFromNow.setHours(0, 0, 0, 0);

  return (
    <div className="topbar">
      <div className="topbar-left">
        {subscription?.lastPaymentFailed && (
          <InfoBox size="small" type="danger" link="/subscription">
            <strong>Seneste betaling mislykkedes.</strong> Tjek venligst dit
            betalingskort er gyldigt. Vi forsøger at trække beløbet igen inden
            for de næste 24 timer.
          </InfoBox>
        )}
        {expiryDate <= currentDate ? (
          <InfoBox size="small" type="danger" link="/subscription">
            <strong>Dit betalingskort er udløbet.</strong> Opdater venligst dit
            betalingskort for at undgå afbrydelse af HRfamly.
          </InfoBox>
        ) : expiryDate <= thirtyDaysFromNow ? (
          <InfoBox size="small" type="warning" link="/subscription">
            <strong>Dit betalingskort udløber snart.</strong> Opdater venligst
            dit betalingskort for at undgå afbrydelse af HRfamly.
          </InfoBox>
        ) : null}
      </div>
      <div className="topbar-right">
        <div className="actions"></div>
        <div className="user">
          <div className="user-trigger" onClick={displayUserMenu}>
            <Avatar
              firstName={userInfo?.firstName}
              lastName={userInfo?.lastName}
              profilePhotoUrl={userInfo?.profilePhotoUrl}
            />
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L4.29289 4.29289C4.68342 4.68342 5.31658 4.68342 5.70711 4.29289L9 1"
                stroke="#767676"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <div className="user-menu">
            <Link to="/company" className="user-menu-item">
              <Icon name="business" />
              Virksomhed
            </Link>
            <Link to="/subscription" className="user-menu-item">
              <Icon name="credit_card" />
              Abonnement
            </Link>
            <Link to="/profile" className="user-menu-item">
              <Icon name="person" />
              Profil
            </Link>
            <div className="user-menu-item" onClick={logout}>
              <Icon name="logout" />
              Logud
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
