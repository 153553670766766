import React, { useEffect, useRef } from 'react';
import { components } from 'react-select';

export default function ScrollToSelectedMenuList(props) {
  const { children, selectProps } = props;
  const { menuIsOpen, value } = selectProps;
  const ref = useRef(null);
  const hasMounted = useRef(false); // Flag to track if it's the first mount

  useEffect(() => {
    if (menuIsOpen && ref.current && !hasMounted.current) {
      const selectedOption = Array.isArray(value) ? value[0] : value;
      const selectedIndex = React.Children.toArray(children).findIndex(
        (child) => child.props.data.value === selectedOption?.value
      );

      if (selectedIndex >= 0) {
        const optionElement = ref.current.children[selectedIndex];
        if (optionElement) {
          optionElement.scrollIntoView({ behavior: 'auto', block: 'nearest' });
        }
      }

      hasMounted.current = true; // Set flag to true after the first scroll
    }
  }, [menuIsOpen]); // Only dependent on menuIsOpen

  if (!value) {
    return null;
  }

  return (
    <components.MenuList {...props} innerRef={ref}>
      {children}
    </components.MenuList>
  );
}
