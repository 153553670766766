import './avatar.css';

export default function Avatar({ firstName, lastName, profilePhotoUrl, size }) {
  return (
    <div
      className={`avatar${size ? ` avatar--${size}` : ''}`}
      style={{ backgroundImage: `url(${profilePhotoUrl})` }}
    >
      {profilePhotoUrl ? null : `${firstName?.charAt(0)}${lastName?.charAt(0)}`}
    </div>
  );
}
