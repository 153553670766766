import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import DatePicker from '../date-picker/date-picker';
import Popover from '../popover/popover';
import './input-field.css';
import { useEffect, useState } from 'react';
import Icon from '../icon/icon';
import ScrollToSelectedMenuList from './input-field-menu-list';

const timeOptions = [
  { value: '00:00', label: '00:00' },
  { value: '00:15', label: '00:15' },
  { value: '00:30', label: '00:30' },
  { value: '00:45', label: '00:45' },
  { value: '01:00', label: '01:00' },
  { value: '01:15', label: '01:15' },
  { value: '01:30', label: '01:30' },
  { value: '01:45', label: '01:45' },
  { value: '02:00', label: '02:00' },
  { value: '02:15', label: '02:15' },
  { value: '02:30', label: '02:30' },
  { value: '02:45', label: '02:45' },
  { value: '03:00', label: '03:00' },
  { value: '03:15', label: '03:15' },
  { value: '03:30', label: '03:30' },
  { value: '03:45', label: '03:45' },
  { value: '04:00', label: '04:00' },
  { value: '04:15', label: '04:15' },
  { value: '04:30', label: '04:30' },
  { value: '04:45', label: '04:45' },
  { value: '05:00', label: '05:00' },
  { value: '05:15', label: '05:15' },
  { value: '05:30', label: '05:30' },
  { value: '05:45', label: '05:45' },
  { value: '06:00', label: '06:00' },
  { value: '06:15', label: '06:15' },
  { value: '06:30', label: '06:30' },
  { value: '06:45', label: '06:45' },
  { value: '07:00', label: '07:00' },
  { value: '07:15', label: '07:15' },
  { value: '07:30', label: '07:30' },
  { value: '07:45', label: '07:45' },
  { value: '08:00', label: '08:00' },
  { value: '08:15', label: '08:15' },
  { value: '08:30', label: '08:30' },
  { value: '08:45', label: '08:45' },
  { value: '09:00', label: '09:00' },
  { value: '09:15', label: '09:15' },
  { value: '09:30', label: '09:30' },
  { value: '09:45', label: '09:45' },
  { value: '10:00', label: '10:00' },
  { value: '10:15', label: '10:15' },
  { value: '10:30', label: '10:30' },
  { value: '10:45', label: '10:45' },
  { value: '11:00', label: '11:00' },
  { value: '11:15', label: '11:15' },
  { value: '11:30', label: '11:30' },
  { value: '11:45', label: '11:45' },
  { value: '12:00', label: '12:00' },
  { value: '12:15', label: '12:15' },
  { value: '12:30', label: '12:30' },
  { value: '12:45', label: '12:45' },
  { value: '13:00', label: '13:00' },
  { value: '13:15', label: '13:15' },
  { value: '13:30', label: '13:30' },
  { value: '13:45', label: '13:45' },
  { value: '14:00', label: '14:00' },
  { value: '14:15', label: '14:15' },
  { value: '14:30', label: '14:30' },
  { value: '14:45', label: '14:45' },
  { value: '15:00', label: '15:00' },
  { value: '15:15', label: '15:15' },
  { value: '15:30', label: '15:30' },
  { value: '15:45', label: '15:45' },
  { value: '16:00', label: '16:00' },
  { value: '16:15', label: '16:15' },
  { value: '16:30', label: '16:30' },
  { value: '16:45', label: '16:45' },
  { value: '17:00', label: '17:00' },
  { value: '17:15', label: '17:15' },
  { value: '17:30', label: '17:30' },
  { value: '17:45', label: '17:45' },
  { value: '18:00', label: '18:00' },
  { value: '18:15', label: '18:15' },
  { value: '18:30', label: '18:30' },
  { value: '18:45', label: '18:45' },
  { value: '19:00', label: '19:00' },
  { value: '19:15', label: '19:15' },
  { value: '19:30', label: '19:30' },
  { value: '19:45', label: '19:45' },
  { value: '20:00', label: '20:00' },
  { value: '20:15', label: '20:15' },
  { value: '20:30', label: '20:30' },
  { value: '20:45', label: '20:45' },
  { value: '21:00', label: '21:00' },
  { value: '21:15', label: '21:15' },
  { value: '21:30', label: '21:30' },
  { value: '21:45', label: '21:45' },
  { value: '22:00', label: '22:00' },
  { value: '22:15', label: '22:15' },
  { value: '22:30', label: '22:30' },
  { value: '22:45', label: '22:45' },
  { value: '23:00', label: '23:00' },
  { value: '23:15', label: '23:15' },
  { value: '23:30', label: '23:30' },
  { value: '23:45', label: '23:45' },
];

export default function InputField({
  className,
  required = false,
  label,
  inline,
  showLabel,
  value,
  onChange,
  onCreateOption,
  placeholder,
  type = 'text',
  help,
  error,
  disabled,
  forwardRef,
  datePickerPosition,
  datePickerFixed,
  ...props
}) {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); // To store the selected date
  const [selectedTime, setSelectedTime] = useState(null); // To store the selected time

  // Helper to merge date and time into a single Date object
  const mergeDateAndTime = (date, time) => {
    const [hours, minutes] = time.value
      ? time.value.split(':')
      : time.split(':');
    const mergedDate = new Date(date);
    mergedDate.setHours(hours);
    mergedDate.setMinutes(minutes);
    return mergedDate;
  };

  // Handle date input
  const handleDateChange = (selectedDate) => {
    setSelectedDate(selectedDate);

    if (selectedTime) {
      const combinedDateTime = mergeDateAndTime(selectedDate, selectedTime);
      onChange({
        target: { name: props.name, value: combinedDateTime.toISOString() },
      });
    } else {
      onChange({ target: { name: props.name, value: selectedDate } });
    }

    setIsDatePickerOpen(false); // Close the date picker after selection
  };

  // Handle time selection from dropdown for both datetime and time types
  const handleTimeChange = (selectedOption) => {
    setSelectedTime(selectedOption);

    if (type === 'datetime' && selectedDate) {
      // If datetime, merge date and time
      const combinedDateTime = mergeDateAndTime(
        selectedDate,
        selectedOption.value
      );
      onChange({
        target: {
          name: props.name,
          value: combinedDateTime.toISOString(),
          type: 'datetime',
        },
      });
    } else if (type === 'time') {
      // If time only, create a Date object with the current date
      const currentTime = new Date();
      const [hours, minutes] = selectedOption.value.split(':');
      currentTime.setHours(hours);
      currentTime.setMinutes(minutes);
      currentTime.setSeconds(0);
      currentTime.setMilliseconds(0);

      // Call onChange with a Date object
      onChange({
        target: {
          name: props.name,
          value: currentTime.toISOString(),
          type: 'time',
        },
      });
    }
  };

  // Extract time from value if it's already provided
  useEffect(() => {
    if ((type === 'datetime' || type === 'time') && value) {
      const dateObj = new Date(value);
      const hours = dateObj.getHours().toString().padStart(2, '0');
      const minutes = dateObj.getMinutes().toString().padStart(2, '0');
      const time = `${hours}:${minutes}`;

      // Set date only if it's datetime type
      if (type === 'datetime') {
        setSelectedDate(dateObj);
      }

      // Find matching time option and set it
      const matchingOption = timeOptions.find(
        (option) => option.value === time
      );
      setSelectedTime(matchingOption);
    }
  }, [value, type]);

  if (type === 'checkbox') {
    return (
      <div
        className={`input-field input-field--checkbox ${
          error && 'input-field--error'
        } ${props.checked && 'input-field--checked'} ${className ?? ''}
        `}
      >
        <label>
          <input
            required={required}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            ref={forwardRef}
            {...props}
          />
          <span dangerouslySetInnerHTML={{ __html: label }} />
        </label>
        {help && <span className="help">{help}</span>}
        {error && <span className="error">{error}</span>}
      </div>
    );
  }

  if (type === 'select2') {
    return (
      <div
        className={`input-field input-field--select2 ${
          error && 'input-field--error'
        } ${className ?? ''}
        ${inline ? 'input-field--inline' : ''}
        `}
      >
        <Select
          placeholder="- Vælg -"
          className="react-select-container"
          classNamePrefix={'react-select'}
          required={required}
          value={value}
          onChange={onChange}
          noOptionsMessage={() => 'Ingen resultater'}
          options={
            props.options.map((option) => ({
              value: option.value,
              label: option.label,
            })) || []
          }
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          components={{
            DropdownIndicator: () => (
              <svg
                className="react-select__dropdown-indicator"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z"
                  fill="rgb(61, 133, 69)"
                />
              </svg>
            ),
          }}
          {...props}
        />
        <label dangerouslySetInnerHTML={{ __html: label }} />
        {help && <span className="help">{help}</span>}
        {error && <span className="error">{error}</span>}
      </div>
    );
  }

  if (type === 'creatableSelect2') {
    return (
      <div
        className={`input-field input-field--select2 ${
          error && 'input-field--error'
        } ${className ?? ''}
        ${inline ? 'input-field--inline' : ''}
        `}
      >
        <CreatableSelect
          placeholder="- Vælg -"
          className="react-select-container"
          classNamePrefix={'react-select'}
          required={required}
          value={value}
          onChange={onChange}
          onCreateOption={onCreateOption}
          noOptionsMessage={() => 'Tilføj ved at skrive og tryk enter'}
          formatCreateLabel={(inputValue) => `Opret "${inputValue}"`}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          options={
            props.options.map((option) => ({
              value: option.value,
              label: option.label,
            })) || []
          }
          components={{
            DropdownIndicator: () => (
              <svg
                className="react-select__dropdown-indicator"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z"
                  fill="rgb(61, 133, 69)"
                />
              </svg>
            ),
          }}
          {...props}
        />
        <label dangerouslySetInnerHTML={{ __html: label }} />
        {help && <span className="help">{help}</span>}
        {error && <span className="error">{error}</span>}
      </div>
    );
  }

  if (type === 'select') {
    return (
      <div
        className={`input-field input-field--select ${
          error && 'input-field--error'
        } ${className ?? ''}
        ${inline ? 'input-field--inline' : ''}
        `}
      >
        <select
          required={required}
          value={value}
          onChange={onChange}
          ref={forwardRef}
          autoComplete="off"
          {...props}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {props.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <label dangerouslySetInnerHTML={{ __html: label }} />
        {help && <span className="help">{help}</span>}
        {error && <span className="error">{error}</span>}
      </div>
    );
  }

  if (type === 'textarea') {
    return (
      <div
        className={`input-field input-field--textarea ${
          error && 'input-field--error'
        } ${className ?? ''}
        `}
      >
        <textarea
          required={required}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          ref={forwardRef}
          {...props}
        />
        <label dangerouslySetInnerHTML={{ __html: label }} />
        {help && <span className="help">{help}</span>}
        {error && <span className="error">{error}</span>}
      </div>
    );
  }

  if (type === 'switch') {
    return (
      <div className={`input-field input-field--switch ${className ?? ''}`}>
        {showLabel && label && <span>{label}</span>}
        <div>
          <input
            name={label}
            id={label}
            type="checkbox"
            value={value}
            defaultChecked={value}
            onChange={onChange}
            ref={forwardRef}
            disabled={disabled}
            {...props}
          />
          <label htmlFor={label}></label>
        </div>
        {help && <span className="help">{help}</span>}
      </div>
    );
  }

  if (type === 'date') {
    return (
      <div
        className={`input-field input-field--date ${
          error && 'input-field--error'
        } ${className ?? ''}
        ${inline ? 'input-field--inline' : ''}
        `}
      >
        <input
          id={label}
          required={required}
          type="text"
          value={value ? new Date(value).toLocaleDateString('da-DK') : ''}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={() => setIsDatePickerOpen(true)} // Open date picker on focus
          ref={forwardRef}
          {...props}
        />
        <label dangerouslySetInnerHTML={{ __html: label }} />
        <Icon name="calendar today" />
        {help && <span className="help">{help}</span>}
        {error && <span className="error">{error}</span>}
        {isDatePickerOpen && (
          <Popover
            onClose={() => setIsDatePickerOpen(false)}
            position={datePickerPosition}
            fixed={datePickerFixed}
          >
            <DatePicker
              onDateSelect={handleDateChange}
              selected={value || null}
            />
          </Popover>
        )}
      </div>
    );
  }

  if (type === 'datetime') {
    return (
      <div className="input-field">
        <div className="input-field-group">
          <div
            className={`input-field input-field--date ${
              error && 'input-field--error'
            } ${className ?? ''}
            ${inline ? 'input-field--inline' : ''}
            `}
          >
            <input
              id={label}
              required={required}
              type="text"
              value={value ? new Date(value).toLocaleDateString('da-DK') : ''}
              disabled={disabled}
              placeholder={placeholder}
              onChange={onChange}
              onFocus={() => setIsDatePickerOpen(true)} // Open date picker on focus
              ref={forwardRef}
              {...props}
            />
            <label dangerouslySetInnerHTML={{ __html: label }} />
            <Icon name="calendar today" />
          </div>
          <div
            className={`input-field input-field--select2 ${
              error && 'input-field--error'
            } ${className ?? ''}`}
          >
            <Select
              placeholder=""
              className="react-select-container"
              classNamePrefix={'react-select'}
              required={required}
              isSearchable={false}
              value={selectedTime}
              onChange={handleTimeChange}
              options={timeOptions}
              noOptionsMessage={() => 'Ugyldig'}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              components={{
                MenuList: ScrollToSelectedMenuList,
                DropdownIndicator: () => (
                  <svg
                    className="react-select__dropdown-indicator"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z"
                      fill="rgb(61, 133, 69)"
                    />
                  </svg>
                ),
              }}
              {...props}
            />
            <label dangerouslySetInnerHTML={{ __html: 'Kl.' }} />
          </div>
        </div>
        {help && <span className="help">{help}</span>}
        {error && <span className="error">{error}</span>}
        {isDatePickerOpen && (
          <Popover
            onClose={() => setIsDatePickerOpen(false)}
            position={datePickerPosition}
            fixed={datePickerFixed}
          >
            <DatePicker
              onDateSelect={handleDateChange}
              selected={value || null}
            />
          </Popover>
        )}
      </div>
    );
  }

  if (type === 'time') {
    return (
      <div
        className={`input-field input-field--select2 ${
          error && 'input-field--error'
        } ${className ?? ''}
        ${inline ? 'input-field--inline' : ''}
        `}
      >
        <Select
          placeholder="- Vælg -"
          className="react-select-container"
          classNamePrefix={'react-select'}
          required={required}
          value={selectedTime}
          onChange={handleTimeChange}
          menuShouldScrollIntoView={true}
          options={timeOptions}
          noOptionsMessage={() => 'Ugyldig'}
          menuPortalTarget={document.body}
          isSearchable={false}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          components={{
            MenuList: ScrollToSelectedMenuList,
            DropdownIndicator: () => (
              <svg
                className="react-select__dropdown-indicator"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z"
                  fill="rgb(61, 133, 69)"
                />
              </svg>
            ),
          }}
          {...props}
        />
        <label dangerouslySetInnerHTML={{ __html: label }} />
        {help && <span className="help">{help}</span>}
        {error && <span className="error">{error}</span>}
      </div>
    );
  }

  return (
    <div
      className={`input-field ${error && 'input-field--error'} ${
        className ?? ''
      }`}
    >
      <input
        required={required}
        type={type}
        // If disabled, use defaultValue instead of value
        {...(disabled ? { defaultValue: value } : { value })}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        ref={forwardRef}
        {...props}
      />
      <label dangerouslySetInnerHTML={{ __html: label }} />
      {help && <span className="help">{help}</span>}
      {error && <span className="error">{error}</span>}
    </div>
  );
}
