import { useContext, useState } from 'react';
import { EmployeesContext } from '../../context';
import Icon from '../../components/icon/icon';
import Button from '../../components/button/button';
import EmployeeCreateModal from '../employees/employee-create-modal';
import { Panel } from '../../components/panel/panel';

export default function DashboardFirstEmployee() {
  const employees = useContext(EmployeesContext);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  if (employees.length > 1) return null;

  return (
    <Panel>
      <div className="dashboard-first-employee">
        <div className="dashboard-first-employee-icon">
          <Icon name="group" />
        </div>
        <div className="dashboard-first-employee-content">
          <h5>Opret din første medarbejder</h5>
          <p>Kom godt igang ved at oprette din første medarbejder.</p>
        </div>
        <Button
          buttonStyle="text-link"
          className="small"
          onClick={() => setIsCreateModalOpen(true)}
        >
          Opret medarbejder
        </Button>
      </div>
      <EmployeeCreateModal
        isModalOpen={isCreateModalOpen}
        setIsModalOpen={setIsCreateModalOpen}
      />
    </Panel>
  );
}
