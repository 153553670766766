import { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import './date-picker.css';

export default function DatePicker({ onDateSelect, selected }) {
  const [datePickerSelected, setDatePickSelected] = useState(selected || null);

  const handleSelect = (date) => {
    setDatePickSelected(date);
    onDateSelect(date); // Call the onDateSelect prop passed from parent
  };

  return (
    <DayPicker
      mode="single"
      selected={datePickerSelected}
      onSelect={handleSelect}
    />
  );
}
