import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  UserInfoContext,
  EmployeesContext,
  SubscriptionContext,
} from '../../context';
import PageTop from '../../components/page-top/page-top';
import PreLoader from '../../components/preloader/preloader';
import Tabs from '../../components/tabs/tabs';
import Button from '../../components/button/button';
import EmptyStateImage from './emptystate.webp';
import TimeRegistrationSettings from './timeregistration-settings';
import TimeRegistrationOverview from './timeregistration-overview';
import TimeRegistrationRegistrations from './timeregistration-registrations';
import TimeRegistrationExceedings from './timeregistration-exceedings';
import { useLogs } from '../../logsContext';
import { convertToLocalTime } from '../../utils/formatDates';
import EmptyState from '../../components/empty-state/empty-state';

const initialStartDate = new Date(
  Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 0)
);
const initialEndDate = new Date(
  Date.UTC(
    initialStartDate.getFullYear(),
    initialStartDate.getMonth() + 1,
    1,
    23,
    59,
    59,
    999
  )
);

export default function TimeRegistrationView() {
  const { subscription } = useContext(SubscriptionContext);
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);
  const { logs, getFilteredLogs } = useLogs();
  const [activeTab, setActiveTab] = useState('Overblik');
  const [dateRange, setDateRange] = useState({
    startDate: initialStartDate.toISOString().split('T')[0],
    endDate: initialEndDate.toISOString().split('T')[0],
  });
  const [valueToDisplay, setValueToDisplay] = useState(
    convertToLocalTime(initialStartDate, {
      month: 'long',
      year: 'numeric',
    })
  );
  const [employeeToDisplayInFilter, setEmployeeToDisplayInFilter] =
    useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [employeesLoading, setEmployeesLoading] = useState(true);
  const [values, setValues] = useState({
    timeLogSettings: userInfo?.companyData?.timeLogSettings,
  });

  const [employeesToDisplay, setEmployeesToDisplay] = useState(employees);

  const subscriptionActive = subscription && subscription.status === 'active';

  const navigate = useNavigate();
  const location = useLocation();

  const updateActiveTab = (newTab) => {
    setActiveTab(newTab);
    const basePath = `/tidsregistrering`;
    switch (newTab) {
      case 'Registreringer':
        navigate(`${basePath}/registreringer`, { replace: true });
        break;
      case 'Overskridelser':
        navigate(`${basePath}/overskridelser`, { replace: true });
        break;
      case 'Indstillinger':
        navigate(`${basePath}/indstillinger`, { replace: true });
        break;
      case 'Overblik':
      default:
        navigate(`${basePath}`, { replace: true });
    }
  };

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const tabSegment = pathSegments[pathSegments.length - 1];
    switch (tabSegment) {
      case 'registreringer':
        setActiveTab('Registreringer');
        break;
      case 'overskridelser':
        setActiveTab('Overskridelser');
        break;
      case 'indstillinger':
        setActiveTab('Indstillinger');
        break;
      case 'overblik':
      default:
        setActiveTab('Overblik');
    }
  }, [location]);

  useEffect(() => {
    if (employees.length > 0) {
      setEmployeesToDisplay(employees);
      setEmployeesLoading(false);
    }
  }, [employees]);

  useEffect(() => {
    if (userInfo.companyData) {
      setValues({
        timeLogSettings: userInfo.companyData.timeLogSettings,
      });
    }
  }, [userInfo.companyData]);

  useEffect(() => {
    const fetchAllLogs = async () => {
      setIsLoading(true);
      await Promise.all(
        employees
          .filter((employee) => employee?.data?.settings?.mobileApp?.access)
          .map((employee) =>
            getFilteredLogs({ userId: employee.id, ...dateRange })
          )
      );
      setIsLoading(false);
    };

    fetchAllLogs();

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Never add fetchAllLogs to dependencies, it will cause an infinite loop
  }, [dateRange, employees]);

  const filteredEmployees = employees
    .filter((employee) => employee?.data?.settings?.mobileApp?.access)
    .filter((employee) => !employee?.data?.settings?.timeRegistration?.exempt);

  const filteredEmployeesToDisplay = filteredEmployees.filter((employee) =>
    employeesToDisplay.some((emp) => emp.id === employee.id)
  );

  if (isLoading || employeesLoading) {
    return <PreLoader />;
  }

  return (
    <div className="main-layout">
      <PageTop
        title={
          activeTab === 'Indstillinger'
            ? `Indstillinger til tidsregistrering`
            : 'Tidsregistrering'
        }
        actions={
          subscriptionActive && (
            <Button
              buttonStyle="secondary"
              href="/tidsregistrering/indstillinger"
            >
              Indstillinger
            </Button>
          )
        }
      />
      {subscriptionActive ? (
        <>
          {filteredEmployees.length !== 0 && (
            <Tabs
              tabs={[
                { label: 'Overblik' },
                { label: 'Registreringer' },
                { label: 'Overskridelser' },
              ]}
              activeTab={activeTab}
              setActiveTab={updateActiveTab}
            />
          )}
          {activeTab !== 'Indstillinger' && filteredEmployees.length === 0 ? (
            <EmptyState
              title="Kom igang med tidsregistering"
              description="Under medarbejderens indstillinger, kan du tilføje 'Mobil app'. Herefter bliver medarbejderen inviteret på mail, til at downloade tidsregistreringsappen.

Når der bliver registreret tid, får du et overblik her på siden og kan dykke ned i registreringer, samt håndtere overskridelser af 11-timers og 48-timers reglen."
              image={EmptyStateImage}
            >
              <Button
                buttonStyle="primary"
                href="/tidsregistrering/indstillinger"
              >
                Kom godt igang
              </Button>
            </EmptyState>
          ) : (
            <>
              {activeTab === 'Overblik' && (
                <TimeRegistrationOverview
                  logs={logs}
                  employees={filteredEmployees}
                  employeesToDisplay={filteredEmployeesToDisplay}
                  setEmployeesToDisplay={setEmployeesToDisplay}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  companyData={userInfo.companyData}
                  valueToDisplay={valueToDisplay}
                  setValueToDisplay={setValueToDisplay}
                  employeeToDisplayInFilter={employeeToDisplayInFilter}
                  setEmployeeToDisplayInFilter={setEmployeeToDisplayInFilter}
                />
              )}
              {activeTab === 'Registreringer' && (
                <TimeRegistrationRegistrations
                  employees={filteredEmployees}
                  employeesToDisplay={filteredEmployeesToDisplay}
                  setEmployeesToDisplay={setEmployeesToDisplay}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  companyData={userInfo.companyData}
                  valueToDisplay={valueToDisplay}
                  setValueToDisplay={setValueToDisplay}
                  employeeToDisplayInFilter={employeeToDisplayInFilter}
                  setEmployeeToDisplayInFilter={setEmployeeToDisplayInFilter}
                />
              )}
              {activeTab === 'Overskridelser' && (
                <TimeRegistrationExceedings employees={filteredEmployees} />
              )}
            </>
          )}
          {activeTab === 'Indstillinger' && (
            <TimeRegistrationSettings
              userInfo={userInfo}
              initialValues={values}
              setInitialValues={setValues}
            />
          )}
        </>
      ) : (
        <EmptyState
          description="For at kunne bruge tidsregistrering, Kræver det et tilknyttet betalingskort."
          image={EmptyStateImage}
        >
          <Button buttonStyle="primary" href="/subscription">
            Se mere
          </Button>
        </EmptyState>
      )}
    </div>
  );
}
