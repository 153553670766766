import { useState } from 'react';
import { Timestamp } from 'firebase/firestore';

const convertTimestampInInitialValues = (initialValues) => {
  const newValues = { ...initialValues };
  Object.keys(newValues).forEach((key) => {
    if (newValues[key] instanceof Timestamp) {
      newValues[key] = newValues[key].toDate().toISOString().slice(0, 10);
    }
  });

  return newValues;
};

export const useForm = (initialValues, validate, onSubmit) => {
  const [values, setValues] = useState(
    convertTimestampInInitialValues(initialValues)
  );
  const [errors, setErrors] = useState({});

  const handleChange = (event, inputName = null) => {
    let name, value;

    if (Array.isArray(event)) {
      if (event.length > 0) {
        // Handle non-empty array
        name = event[0].target.name; // Name from the first element
        value = event.map((e) => e.target.value);
      } else if (inputName) {
        // Handle empty array with an explicit input name provided
        name = inputName;
        value = []; // Set an empty array as the value
      }
    } else {
      if (event) {
        ({ name, value } = event.target);
        const { type, checked } = event.target;
        // Use 'checked' for checkboxes, and 'value' for other input types
        value = type === 'checkbox' ? checked : value;
      } else {
        name = inputName.name;
        value = null;
      }
    }

    if (name) {
      setValues({ ...values, [name]: value });

      if (errors[name]) {
        setErrors({ ...errors, [name]: '' });
      }
    } else {
      console.error('handleChange was called without a field name.');
    }
  };

  // Function to update form values directly
  const setValuesDirectly = (newValues) => {
    setValues((prevValues) => ({ ...prevValues, ...newValues }));
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();

    const validationErrors = validate(values);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      onSubmit(values); // Call onSubmit if no validation errors
    }
  };

  const resetForm = (newValues = initialValues) => {
    setValues(newValues);
    setErrors({});
  };

  return {
    values,
    handleChange,
    handleSubmit,
    errors,
    setValues: setValuesDirectly,
    resetForm,
  };
};
