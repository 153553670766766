import { useContext, useState } from 'react';
import './timeregistration-access-modal.css';
import Button from '../../button/button';
import {
  EmployeesContext,
  SubscriptionContext,
  UserContext,
} from '../../../context';
import Modal from '../../modal/modal';
import Avatar from '../../avatar/avatar';
import InputField from '../../input-field/input-field';
import { db, firebaseConfig } from '../../../firebase-config';
import { doc, setDoc } from 'firebase/firestore';
import {
  getAuth,
  signInWithCustomToken,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { initializeApp, deleteApp } from 'firebase/app';
import InfoBox from '../../infobox/infobox';
import { Link } from 'react-router-dom';

export default function TimeRegistrationAccessModal({
  isModalOpen,
  setIsModalOpen,
}) {
  const employees = useContext(EmployeesContext);
  const { subscription, onPaySubscription } = useContext(SubscriptionContext);
  const user = useContext(UserContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Deep clone employees to avoid mutating the original array so we can use it for submitting
  const employeesClone = JSON.parse(JSON.stringify(employees));

  const handleChange = (e) => {
    const { name, checked } = e.target;
    const employeeId = name.split('-')[2];

    const employeeIndex = employeesClone.findIndex(
      (employee) => employee.id === employeeId
    );

    // Update the employee's mobile app access. Make sure to handle if the employee does not have settings or mobileApp object
    if (!employeesClone[employeeIndex].data.settings) {
      employeesClone[employeeIndex].data.settings = {
        mobileApp: {
          access: checked,
        },
      };
    } else {
      employeesClone[employeeIndex].data.settings.mobileApp.access = checked;
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    // Determine which employees have had their mobile access changed by comparing the original employees array with the cloned array
    const employeesWithChangedMobileAccess = employeesClone.filter(
      (employee, index) =>
        employee.data.settings?.mobileApp?.access !==
        employees[index].data.settings?.mobileApp?.access
    );

    // If no employees have had their mobile access changed, close the modal
    if (employeesWithChangedMobileAccess.length === 0) {
      setIsSubmitting(false);
      setIsModalOpen(false);
      return;
    }

    // Loop through the employees with changed mobile access and update their settings
    for (const employee of employeesWithChangedMobileAccess) {
      const employeeDocRef = doc(db, 'users', employee.id);

      const mobileAccess = employee.data.settings.mobileApp.access;

      try {
        // Update the employee's settings in Firestore
        await setDoc(
          employeeDocRef,
          {
            settings: {
              mobileApp: {
                access: mobileAccess,
              },
            },
          },
          { merge: true }
        );

        // Set the custom claim for the employee
        const mobileaccessResponse = await fetch('/api/user/mobileaccess', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-user-uuid': user.uid,
          },
          body: JSON.stringify({
            employeeId: employee.id,
            mobileAccess,
          }),
        });

        const result = await mobileaccessResponse.json();

        if (result.error) {
          throw new Error('Error updating mobile access');
        }

        if (result.token) {
          try {
            const tempApp = initializeApp(firebaseConfig, 'tempApp');
            const tempAuth = getAuth(tempApp);
            await signInWithCustomToken(tempAuth, result.token).then(
              async (userCredential) => {
                if (!userCredential.user.emailVerified) {
                  await sendPasswordResetEmail(
                    tempAuth,
                    userCredential.user.email
                  );
                }
                await tempAuth.signOut();
              }
            );
            deleteApp(tempApp);
          } catch (error) {
            console.error('Error sending email verification:', error);
          }
        }
        setIsSubmitting(false);
        setIsModalOpen(false);
      } catch (error) {
        console.error('Error updating:', error);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Modal
      customWidth={600}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      header={`Tidsregistrering i mobil app`}
      bottom={
        (onPaySubscription?.onPayData || subscription?.trial) && (
          <div className="time-registration-access-modal-bottom">
            <p>
              Tidsregistrering er et tilkøb på 10 kr. om måneden pr. medarbejder
            </p>
            <Button disabled={isSubmitting} onClick={handleSubmit}>
              {isSubmitting ? 'Gemmer...' : 'Gem'}
            </Button>
          </div>
        )
      }
      content={
        <div className="time-registration-access-modal-content">
          <p>
            Nedenfor ses et overblik over de medarbejdere, som du har oprettet.
            Klik de medarbejdere af, som skal tidsregistrere i HRfamly appen. Du
            kan altid ændre medarbejderadgang i indstillinger, deres normtid mm.
          </p>
          {!subscription?.trial && !onPaySubscription?.onPayData ? (
            <InfoBox type="danger">
              <p>
                <strong>Du har ikke opsat et betalingskort.</strong>
                <br />
                For at kunne bruge tidsregistrering, skal du først opsætte et
                betalingskort under{' '}
                <Link to="/subscription?add_payment=true">Abonnement</Link>.
              </p>
            </InfoBox>
          ) : (
            employeesClone.map((employee) => (
              <div
                key={employee.id}
                className="time-registration-access-modal-content-item"
              >
                <Avatar
                  firstName={employee.data.firstName}
                  lastName={employee.data.lastName}
                  profilePhotoUrl={employee.data.profilePhotoUrl}
                />
                <div className="time-registration-access-modal-content-item__inner">
                  <span className="time-registration-access-modal-content-item__name">
                    {employee.data.firstName} {employee.data.lastName}
                  </span>
                  <span className="time-registration-access-modal-content-item__position">
                    {employee.data.jobPosition}
                  </span>
                </div>
                <InputField
                  label={`mobile-access-${employee.id}`}
                  type="switch"
                  defaultChecked={employee?.data?.settings?.mobileApp?.access}
                  className={`time-registration-access-modal-content-item__switch`}
                  onChange={handleChange}
                />
              </div>
            ))
          )}
        </div>
      }
    />
  );
}
