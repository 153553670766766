import { useContext, useState } from 'react';
import './subscription-plans-modal.css';
import Button from '../../components/button/button';
import Modal from '../../components/modal/modal';
import PLANS from '../../constants/payments/plans';
import Icon from '../../components/icon/icon';
import { SubscriptionContext } from '../../context';

export default function SubscriptionPlansModal({
  isModalOpen,
  setIsModalOpen,
  companyId,
  userId,
}) {
  const { subscription, onPaySubscription } = useContext(SubscriptionContext);
  const currentPlanName = subscription?.name || 'freemium';
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [newlySelectedPlan, setNewlySelectedPlan] = useState('');
  const [newlySelectedPlanAction, setNewlySelectedPlanAction] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Handle form submission
  async function handleSubscriptionChange({ name, action }) {
    // Update submitting state
    setSubmitting(true);

    try {
      let endpoint = 'add';
      if (onPaySubscription?.onPayData?.data) {
        endpoint = 'update';
      }

      const response = await fetch(`/api/payment/subscriptions/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-uuid': userId,
        },
        body: JSON.stringify({
          name,
          companyId,
          action,
        }),
      });

      // If the response is not ok, redirect the user to the /subscription page with the error message
      if (!response.ok) {
        console.error('Error:', response.statusText);
        window.location = '/subscription?onpay_errorcode=1000';
        return;
      }

      // If the response is ok, determine if the user should be redirected to the payment page or reload the page
      const data = await response.json();
      if (data.action === 'add') {
        window.location = data.paymentLink;
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error:', error);
      return;
    }

    // Reset submitting state
    setSubmitting(false);
  }

  // Handle onClick
  function handleSubscriptionChangeClick({ name, action }) {
    if (!companyId) return;

    // If the user is already on the selected plan, return early
    if (name === currentPlanName || action === 'nochange') {
      return;
    }

    // If the user is downgrading or cancelling, show a confirmation modal
    if (action === 'cancel' || action === 'downgrade') {
      setConfirmationMessage(
        `Du er ved at nedgradere til ${PLANS[name].label}. Er du sikker?`
      );
      setNewlySelectedPlan(name);
      setNewlySelectedPlanAction(action);
      setShowConfirmationModal(true);
      return;
    }

    // If the user is upgrading, call the handleSubscriptionChange function
    handleSubscriptionChange({ name, action });
  }

  return (
    <>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        className="modal--fullscreen subscription-plans-modal"
        content={
          <div className="subscription-plans-modal-list">
            <div className="subscription-plans-modal-list-column">
              <h2>Freemium</h2>
              <p>
                Op til 10 medarbejdere.
                <br />
                Admins inkluderet: 1.
                <br />
                Ingen binding eller skjulte gebyrer.
              </p>
              <span className="subscription-plans-modal-list-column-price">
                {PLANS.FREEMIUM.price / 100},-
              </span>
              <Button
                disabled={submitting}
                onClick={() =>
                  handleSubscriptionChangeClick({
                    name: 'FREEMIUM',
                    action: 'cancel',
                  })
                }
                buttonStyle={
                  currentPlanName === 'freemium' || currentPlanName === null
                    ? 'primary'
                    : 'secondary'
                }
              >
                {currentPlanName === 'freemium' || currentPlanName === null
                  ? 'Nuværende'
                  : 'Nedgradér'}
              </Button>
              <div className="subscription-plans-modal-list-column-features">
                <div>
                  <Icon name="check" />
                  <span>Opret medarbejdere</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Opbevaring af medarbejderdokumenter</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Onboarding-forløb</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Offboarding-forløb</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Advokatgodkendte kontrakter</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>HR-hjælp</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Individuelt dashboard</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Alle aftaler samlet ét sted</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Samlet mødeoverblik med noter</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Tilgå HR-guides</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Adgang til HR-skabeloner</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Sikker opbevaring af data iht. GDPR</span>
                </div>
              </div>
            </div>
            <div className="subscription-plans-modal-list-column">
              <h2>Premium</h2>
              <p>
                Op til 100 medarbejdere.
                <br />
                Admins inkluderet: 2.
                <br />
                Ingen binding eller skjulte gebyrer.
              </p>
              <span className="subscription-plans-modal-list-column-price">
                {PLANS.PREMIUM.price / 100},-
              </span>
              <Button
                disabled={submitting}
                onClick={() =>
                  handleSubscriptionChangeClick({
                    name: 'PREMIUM',
                    action:
                      currentPlanName === 'freemium'
                        ? 'upgrade'
                        : currentPlanName === 'premiumplus'
                        ? 'downgrade'
                        : 'nochange',
                  })
                }
                buttonStyle={
                  currentPlanName === 'premium' ? 'primary' : 'secondary'
                }
              >
                {currentPlanName === 'premium'
                  ? 'Nuværende'
                  : currentPlanName === 'premiumplus'
                  ? 'Nedgradér'
                  : 'Opgrader'}
              </Button>
              <div className="subscription-plans-modal-list-column-features">
                <div className="subscription-plans-modal-list-column-features-header">
                  Alt i Freemium og:
                </div>
                <div>
                  <Icon name="check" />
                  <span>Rekruttering basic</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Rekrutteringsplatform</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Personlig kontaktperson</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Komplet udstyrsliste</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Juridisk rådgivning</span>
                </div>
              </div>
            </div>
            <div className="subscription-plans-modal-list-column">
              <h2>Premium+</h2>
              <p>
                Ubegrænset antal medarbejdere.
                <br />
                Admins inkluderet: 3.
                <br />
                Ingen binding eller skjulte gebyrer.
              </p>
              <span className="subscription-plans-modal-list-column-price">
                {PLANS.PREMIUMPLUS.price / 100},-
              </span>
              <Button
                disabled={submitting}
                onClick={() =>
                  handleSubscriptionChangeClick({
                    name: 'PREMIUMPLUS',
                    action:
                      currentPlanName === 'freemium' ||
                      currentPlanName === 'premium'
                        ? 'upgrade'
                        : 'nochange',
                  })
                }
                buttonStyle={
                  currentPlanName === 'premiumplus' ? 'primary' : 'secondary'
                }
              >
                {currentPlanName === 'premiumplus' ? 'Nuværende' : 'Opgrader'}
              </Button>
              <div className="subscription-plans-modal-list-column-features">
                <div className="subscription-plans-modal-list-column-features-header">
                  Alt i Premium og:
                </div>
                <div>
                  <Icon name="check" />
                  <span>Personprofil analyse</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Team analyse</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Kognitiv analyse</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>Medarbejder- og lederudviklingsforløb</span>
                </div>
                <div>
                  <Icon name="check" />
                  <span>
                    20% besparelse på alle rekrutteringsydelser (ved min. 6 mdr.
                    som Premium+)
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <Modal
        isModalOpen={showConfirmationModal}
        setIsModalOpen={setShowConfirmationModal}
        header={<>Bekræftelse</>}
        content={confirmationMessage}
        bottom={
          <div className="subscription-plans-modal-confirmation-buttons">
            <Button
              disabled={submitting}
              onClick={() => setShowConfirmationModal(false)}
              buttonStyle="secondary"
            >
              Fortryd
            </Button>
            <Button
              disabled={submitting}
              onClick={() =>
                handleSubscriptionChange({
                  name: newlySelectedPlan,
                  action: newlySelectedPlanAction,
                })
              }
              buttonStyle="primary"
            >
              Bekræft
            </Button>
          </div>
        }
      />
    </>
  );
}
