import { useState, useEffect, useRef, useContext } from 'react';
import { collection, getDocs, where, query, orderBy } from 'firebase/firestore';
import { uploadBytes, ref, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { db, storage } from '../../firebase-config';
import { UserInfoContext, EmployeesContext } from '../../context';
import Avatar from '../../components/avatar/avatar';
import ImportantList from '../../components/important-list/important-list';
import AgreementsCreateModal from '../../views/agreements/agreements-create-modal';
import FileUpload from '../../components/file-upload/file-upload';
import { convertToUTC } from '../../utils/formatDates';

const uploadFiles = async (files, companyId, employeeId, onUploadComplete) => {
  // Check for early return conditions
  if (!files || !companyId || !employeeId) return false;

  // Normalize files to an array (handles both single file and FileList objects)
  const filesArray = files instanceof File ? [files] : Array.from(files);

  if (filesArray.length === 0) return false;

  const fileDataArray = await Promise.all(
    filesArray.map(async (file) => {
      const filePath = `${companyId}/users/${employeeId}/documents/${file.name}`;
      const fileRef = ref(storage, filePath);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);

      // Check if we have employee data and a document ID
      const employeeDocRef = doc(db, 'users', employeeId);

      try {
        // Update the document in Firestore
        await setDoc(
          employeeDocRef,
          {
            employmentContract: {
              url: fileUrl,
              name: file.name,
              uploaded: Timestamp.fromDate(convertToUTC(new Date())),
            },
          },
          { merge: true }
        );
      } catch (error) {
        console.error(error);
      }
    })
  );

  if (onUploadComplete && typeof onUploadComplete === 'function') {
    onUploadComplete(fileDataArray);
  }
  return true;
};

export default function DashboardTasks() {
  const isInitialLoad = useRef(true);
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);
  const [tasks, setTasks] = useState([]);
  const [isCreateAgreementModalOpen, setIsCreateAgreementModalOpen] =
    useState(false);
  const [selectedAgreementType, setSelectedAgreementType] = useState(null);
  const [selectedRegardingId, setSelectedRegardingId] = useState(null);

  const handleCreateAgreementClick = (type, employeeId) => {
    setSelectedAgreementType(type);
    setSelectedRegardingId(employeeId);
    setIsCreateAgreementModalOpen(true);
  };

  // Handler to reload dashboard after files have been uploaded
  const handleFilesUploaded = () => {
    window.location.reload();
  };

  // Fetch agreements to determine if there are any upcoming tasks
  useEffect(() => {
    const fetchTasks = async () => {
      if (employees.length !== 0 && userInfo?.companyId) {
        const agreementsRef = collection(db, 'agreements');
        const q = query(
          agreementsRef,
          where('companyId', '==', userInfo.companyId),
          where('isClosed', '==', false),
          orderBy('date', 'asc')
        );
        const querySnapshot = await getDocs(q);
        const tasksData = [];

        // Run through all employees and check if there are scheduled agreements for type 1to1, EPR and salary
        employees.forEach((employee) => {
          const employeeAgreements = querySnapshot.docs.filter(
            (agreement) => agreement.data().regarding.id === employee.id
          );

          const employeeAgreementsData = employeeAgreements.map((agreement) =>
            agreement.data()
          );

          // If there is no 1to1 agreement, add it to the tasks list to schedule one
          // Skip if the employee is exempted from 1to1 agreements
          if (
            !employee?.data?.settings?.agreements?.exemptFrom1to1 &&
            !employeeAgreementsData.find(
              (agreement) => agreement.agreementType === '1 til 1'
            )
          ) {
            tasksData.push({
              title: (
                <div className="dashboard-tasks-item">
                  1 til 1
                  <span className="dashboard-tasks-item__label">med</span>
                  <Avatar
                    firstName={employee?.data.firstName}
                    lastName={employee?.data.lastName}
                    profilePhotoUrl={employee?.data.profilePhotoUrl}
                    size={'small'}
                  />
                  {employee.data.firstName} {employee.data.lastName}
                </div>
              ),
              text: 'Ikke planlagt',
              missing: true,
              actions: (
                <div
                  onClick={() =>
                    handleCreateAgreementClick('1 til 1', employee.id)
                  }
                >
                  Opret
                </div>
              ),
            });
          }

          // If there is no EPR agreement, add it to the tasks list to schedule one
          if (
            !employee?.data?.settings?.agreements?.exemptFromEPR &&
            !employeeAgreementsData.find(
              (agreement) => agreement.agreementType === 'Udviklingssamtale'
            )
          ) {
            tasksData.push({
              title: (
                <div className="dashboard-tasks-item">
                  Udviklingssamtale{' '}
                  <span className="dashboard-tasks-item__label">med</span>
                  <Avatar
                    firstName={employee?.data.firstName}
                    lastName={employee?.data.lastName}
                    profilePhotoUrl={employee?.data.profilePhotoUrl}
                    size={'small'}
                  />
                  {employee.data.firstName} {employee.data.lastName}
                </div>
              ),
              text: 'Ikke planlagt',
              missing: true,
              actions: (
                <div
                  onClick={() =>
                    handleCreateAgreementClick('Udviklingssamtale', employee.id)
                  }
                >
                  Opret
                </div>
              ),
            });
          }

          // If there is no salary agreement, add it to the tasks list to schedule one
          if (
            !employee?.data?.settings?.agreements?.exemptFromSalary &&
            !employeeAgreementsData.find(
              (agreement) => agreement.agreementType === 'Lønsamtale'
            )
          ) {
            tasksData.push({
              title: (
                <div className="dashboard-tasks-item">
                  Lønsamtale
                  <span className="dashboard-tasks-item__label">med</span>
                  <Avatar
                    firstName={employee?.data.firstName}
                    lastName={employee?.data.lastName}
                    profilePhotoUrl={employee?.data.profilePhotoUrl}
                    size={'small'}
                  />
                  {employee.data.firstName} {employee.data.lastName}
                </div>
              ),
              text: 'Ikke planlagt',
              missing: true,
              actions: (
                <div
                  onClick={() =>
                    handleCreateAgreementClick('Lønsamtale', employee.id)
                  }
                >
                  Opret
                </div>
              ),
            });
          }

          if (
            !employee?.data?.settings?.documents
              ?.exemptFromEmploymentContract &&
            !employee.data.employmentContract
          ) {
            tasksData.push({
              title: (
                <div className="dashboard-tasks-item">
                  <span className="dashboard-tasks-item__label">Upload</span>
                  ansættelseskontrakt
                  <span className="dashboard-tasks-item__label">for</span>
                  <Avatar
                    firstName={employee?.data.firstName}
                    lastName={employee?.data.lastName}
                    profilePhotoUrl={employee?.data.profilePhotoUrl}
                    size={'small'}
                  />
                  {employee.data.firstName} {employee.data.lastName}
                </div>
              ),
              text: 'Mangler',
              missing: true,
              actions: (
                <FileUpload
                  singleFile
                  label="Upload"
                  dragAndDrop={false}
                  handleUpload={(files) =>
                    uploadFiles(
                      files,
                      userInfo.companyId,
                      employee.id,
                      handleFilesUploaded
                    )
                  }
                />
              ),
            });
          }
        });

        setTasks(tasksData);

        isInitialLoad.current = false;
      }
    };

    fetchTasks();
  }, [userInfo, employees]);

  return tasks.length === 0 && !isInitialLoad.current ? (
    <div className="dashboard-panel-empty">
      <span className="dashboard-panel-empty__icon">🧘</span>
      Dit HR arbejde står snorlige, du er på toppen af det hele.
    </div>
  ) : (
    <>
      <ImportantList content={tasks.slice(0, 5)} />
      <AgreementsCreateModal
        isModalOpen={isCreateAgreementModalOpen}
        setIsModalOpen={setIsCreateAgreementModalOpen}
        selectedAgreementType={selectedAgreementType}
        selectedRegardingId={selectedRegardingId}
      />
    </>
  );
}
