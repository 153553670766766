import { useContext, useState } from 'react';
import './admin-access-modal.css';
import Button from '../../button/button';
import {
  EmployeesContext,
  UserContext,
  UserInfoContext,
} from '../../../context';
import Modal from '../../modal/modal';
import Avatar from '../../avatar/avatar';
import InputField from '../../input-field/input-field';
import { db } from '../../../firebase-config';
import { doc, setDoc } from 'firebase/firestore';

export default function AdminAccessModal({ isModalOpen, setIsModalOpen }) {
  const employees = useContext(EmployeesContext);
  const user = useContext(UserContext);
  const userInfo = useContext(UserInfoContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Deep clone employees to avoid mutating the original array so we can use it for submitting
  const employeesClone = JSON.parse(JSON.stringify(employees))
    .filter((employee) => employee.data.employmentStatus !== 'inactive')
    .sort((a, b) => {
      if (a.data.settings?.role === 'admin') return -1;
      if (b.data.settings?.role === 'admin') return 1;
      return 0;
    })
    .sort((a, b) => {
      if (a.id === userInfo?.companyData?.ownerUserId) return -1;
      if (b.id === userInfo?.companyData?.ownerUserId) return 1;
      return 0;
    });

  const handleChange = (e) => {
    const { name, checked } = e.target;
    const employeeId = name.split('-')[2];

    const employeeIndex = employeesClone.findIndex(
      (employee) => employee.id === employeeId
    );

    if (!employeesClone[employeeIndex].data.settings) {
      employeesClone[employeeIndex].data.settings = {
        role: 'admin',
      };
    } else {
      employeesClone[employeeIndex].data.settings.role = checked ? 'admin' : '';
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    confirm(
      'Er du sikker på at du vil ændre admin rettigheder og give adgang til at redigere og se informationer i hele HRfamly systemet?'
    );

    const employeesWithChangedRole = employeesClone.filter((employee) => {
      // Find the original employee from the unmodified employees array by ID
      const originalEmployee = employees.find(
        (original) => original.id === employee.id
      );

      const originalRole = originalEmployee?.data?.settings?.role || ''; // Defaults to empty string if undefined
      const updatedRole = employee?.data?.settings?.role || ''; // Defaults to empty string if undefined

      // Only include employees where the role has been modified
      return originalRole !== updatedRole;
    });

    // If no employees have had their role access changed, close the modal
    if (employeesWithChangedRole.length === 0) {
      setIsSubmitting(false);
      setIsModalOpen(false);
      return;
    }

    // Loop through the employees with changed role and update their settings
    for (const employee of employeesWithChangedRole) {
      const employeeDocRef = doc(db, 'users', employee.id);
      const roleChanged = employee?.data?.settings?.role === 'admin';

      try {
        // Set the custom claim for the employee
        const setClaimResponse = await fetch('/api/user/setclaim', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-user-uuid': user.uid,
          },
          body: JSON.stringify({
            userId: employee.id,
            companyId: userInfo.companyId,
            role: roleChanged ? 'admin' : 'employee',
          }),
        });

        const result = await setClaimResponse.json();

        if (result.error) {
          throw new Error('Error updating custom claim');
        }

        // Update the employee's settings in Firestore
        await setDoc(
          employeeDocRef,
          {
            settings: {
              role: roleChanged ? 'admin' : '',
            },
          },
          { merge: true }
        );
      } catch (error) {
        console.error('Error updating:', error);
        continue;
      } finally {
        // Close the modal when all employees have been processed
        setIsSubmitting(false);
        setIsModalOpen(false);
      }
    }
  };

  return (
    <Modal
      customWidth={600}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      header={`Tildel admin adgange`}
      bottom={
        <div className="admin-access-modal-bottom">
          <p>
            Medarbejdere med admin adgange er et tilkøb på 25 kr. om måneden pr.
            admin.
          </p>
          <Button disabled={isSubmitting} onClick={handleSubmit}>
            {isSubmitting ? 'Gemmer...' : 'Gem'}
          </Button>
        </div>
      }
      content={
        <div className="admin-access-modal-content">
          <p>
            Nedenfor ses et overblik over de medarbejdere der har admin adgang.
            Det betyder de kan redigere og se informationer i hele HRfamly
            systemet, også potentielt følsomme informationer.
          </p>
          {employeesClone.map((employee) => {
            const isAdmin = employee.data.settings?.role === 'admin';
            const isCompanyOwner =
              employee.id === userInfo?.companyData?.ownerUserId;

            return (
              <div
                key={employee.id}
                className="admin-access-modal-content-item"
              >
                <Avatar
                  firstName={employee.data.firstName}
                  lastName={employee.data.lastName}
                  profilePhotoUrl={employee.data.profilePhotoUrl}
                />
                <div className="admin-access-modal-content-item__inner">
                  <span className="admin-access-modal-content-item__name">
                    {employee.data.firstName} {employee.data.lastName}
                  </span>
                  <span className="admin-access-modal-content-item__position">
                    {employee.data.jobPosition}
                  </span>
                </div>
                {isCompanyOwner ? (
                  <InputField
                    label={`mobile-access-${employee.id}`}
                    key={`mobile-access-${employee.id}`}
                    type="switch"
                    defaultChecked={true}
                    className={`admin-access-modal-content-item__switch`}
                    disabled
                  />
                ) : (
                  <InputField
                    label={`mobile-access-${employee.id}`}
                    key={`mobile-access-${employee.id}`}
                    type="switch"
                    defaultChecked={isAdmin}
                    className={`admin-access-modal-content-item__switch`}
                    onChange={handleChange}
                  />
                )}
              </div>
            );
          })}
        </div>
      }
    />
  );
}
