import './previewbar.css';
import { useContext, useState } from 'react';
import { UserInfoContext } from '../../context';
import { auth } from '../../firebase-config';
import { signInWithCustomToken } from 'firebase/auth';

export default function PreviewBar() {
  const userInfo = useContext(UserInfoContext);
  const [loading, setLoading] = useState(false);

  const superAdminUUID = localStorage.getItem('superAdminUUID');

  if (!superAdminUUID) {
    return;
  }

  async function signInAsUser(customToken) {
    try {
      await signInWithCustomToken(auth, customToken);

      // Remove the super admin UUID from local storage
      localStorage.removeItem('superAdminUUID');
    } catch (error) {
      console.error('Error signing in with custom token:', error);
    } finally {
      setLoading(false);
    }
  }

  const revertToSuperAdmin = async () => {
    setLoading(true);
    try {
      // API call to generate a new custom token for the super admin
      const response = await fetch('/api/admin/revertsuperadmin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-uuid': superAdminUUID, // the original super admin's UID
        },
      });

      const data = await response.json();

      if (response.ok && data.customToken) {
        // Use the new custom token to log back in as super admin
        await signInAsUser(data.customToken);
      } else {
        console.error('Error:', data);
      }
    } catch (error) {
      console.error('Error reverting back to super admin:', error);
    }
  };

  return (
    <div
      className={`preview-bar ${loading ? 'preview-bar--loading' : ''}`}
      onClick={revertToSuperAdmin}
    >
      Du er logget ind som {userInfo.email}. Tryk her for at skifte tilbage til
      super admin.
    </div>
  );
}
