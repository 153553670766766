import './icon.css';

export default function Icon({ name, light, className = '' }) {
  if (/^\d+$/.test(name)) {
    return <span className={`icon icon--number`}>{name}.</span>;
  }
  return (
    <span
      className={`icon material-icons ${className} ${light ? 'light' : ''}`}
    >
      {name}
    </span>
  );
}
