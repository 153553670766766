import { useContext, useState } from 'react';
import './admin-list.css';
import Icon from '../../icon/icon';
import Button from '../../button/button';
import { EmployeesContext, UserInfoContext } from '../../../context';
import AdminAccessModal from '../admin-access-modal/admin-access-modal';

export default function AdminList() {
  const employees = useContext(EmployeesContext);
  const userInfo = useContext(UserInfoContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const companyOwnerId = userInfo?.companyData?.ownerUserId;

  const employeesWithAdminOrCompanyOwner = employees
    .filter(
      (employee) =>
        employee?.data?.settings?.role === 'admin' ||
        employee.id === companyOwnerId
    )
    .filter((employee) => employee.data.employmentStatus !== 'inactive')
    .sort((a, b) => {
      if (a.id === companyOwnerId) return -1;
      if (b.id === companyOwnerId) return 1;
      return 0;
    });

  return (
    <div className="admin-list">
      <div className="admin-list-top">
        <div className="admin-list-top-icon">
          <Icon name="pending_actions" />
        </div>
        <div className="admin-list-top-content">
          <h5>Brugere der er admin</h5>
          <p>{employeesWithAdminOrCompanyOwner.length} admins.</p>
        </div>
        <Button buttonStyle="secondary" onClick={() => setIsModalOpen(true)}>
          Rediger
        </Button>
      </div>
      {employeesWithAdminOrCompanyOwner.length > 0 && (
        <div className="admin-list-list">
          {employeesWithAdminOrCompanyOwner.map((employee) => (
            <div key={employee.id} className="admin-list-list-item">
              <span>
                {employee.data.firstName} {employee.data.lastName}
              </span>
              <span>Admin</span>
            </div>
          ))}
        </div>
      )}
      <AdminAccessModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
}
