export default function RecruitmentContentPanel({
  imageAlign = 'top',
  imageBackground = false,
  contentAlign = 'left',
  children,
  hasPadding = false,
  hasBackground = false,
  ...props
}) {
  return (
    <div
      className={`recruitment-content-panel recruitment-content-panel--image-align-${imageAlign}
        ${hasPadding ? 'recruitment-content-panel--has-padding' : ''}
        recruitment-content-panel--content-align-${contentAlign}
        ${hasBackground ? 'recruitment-content-panel--has-background' : ''}
        ${imageBackground ? 'recruitment-content-panel--image-background' : ''}
      `}
      {...props}
    >
      {children}
    </div>
  );
}
