import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import { auth } from '../../firebase-config';
import Logo from '../../components/logo/logo';
import Button from '../../components/button/button';
import './verify-email-view.css';
import TagManager from 'react-gtm-module';

export default function VerifyEmailView() {
  const navigate = useNavigate();
  const [resendEmailNotification, setResendEmailNotification] = useState(false);

  // Effect to listen for user authentication state changes
  // If the user gets verified, redirect to complete signup view
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        // Trigger data layer event for user registration
        TagManager.dataLayer({
          dataLayer: {
            event: 'Mail verificeret',
            label: 'Klikket på link i mail',
          },
        });

        navigate('/complete-signup');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Function to handle resending of email verification
  const handleResendEmail = (e) => {
    e.preventDefault();
    sendEmailVerification(auth.currentUser);

    // Make sure user does not send multiple requests in 3 minutes
    setResendEmailNotification(true);

    setTimeout(() => {
      setResendEmailNotification(false);
    }, 180000);
  };

  return (
    <div className="centered-layout">
      <Logo />
      <div className="centered-layout__content">
        <div className="centered-layout__main">
          <h3>Bekræft din email</h3>
          <p>
            Vi har sendt en email til dig med et link til at bekræfte din email.
            Når du har bekræftet din email, kan du gøre din profil færdig.
          </p>

          <h4>Har du ikke modtaget en email?</h4>
          <p>
            Husk at tjekke dit spamfilter. Hvis du ikke kan finde din email
            eller den er udløbet kan du trykke{' '}
            <a
              className="centered-layout__resend-email-link"
              href="#"
              disabled={resendEmailNotification}
              onClick={handleResendEmail}
            >
              her
            </a>
            , og vi sender dig en ny email med et bekræftelseslink.
          </p>
          <p>Du kan nu lukke dette vindue.</p>
          {resendEmailNotification && (
            <span className="centered-layout__resend-email-notification">
              Vi har sendt dig en ny email. Husk at tjekke dit spamfilter. Du
              kan først sende en ny email om 3 minutter.
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
