import { useState, useContext, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { EmployeesContext } from '../../context';
import Avatar from '../../components/avatar/avatar';
import Icon from '../../components/icon/icon';
import Button from '../button/button';
import Modal from '../modal/modal';
import ProgressIndicator from '../../components/progress-indicator/progress-indicator';
import ChecklistAddItem from './checklist-add-item';
import './checklist-item.css';
import { Timestamp } from 'firebase/firestore';
import InputField from '../input-field/input-field';

const ChecklistItemComponent = ({
  id,
  task,
  checked,
  setChecked,
  handleCheck,
  addTask,
  updateTask,
  deleteTask,
  groupId,
  taskId,
  subTaskId = undefined,
  subTasksCompleted,
  setIsModalOpen,
  insideModal = false,
  subTask = false,
  dragHandle = null,
}) => {
  const employees = useContext(EmployeesContext);
  const [editMode, setEditMode] = useState(false);
  const { isTemplate } = useParams();
  const inputRef = useRef(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [addSubTaskMode, setAddSubTaskMode] = useState(false);
  const isTemplateMode = isTemplate === 'template';

  const [taskValues, setTaskValues] = useState({
    id: null,
    title: '',
    description: '',
    dueDate: '',
    assignedTo: '',
    subTasks: [],
  });

  const handleInputChange = (e) => {
    const { name } = e.target;

    let value = e.target.value;

    // If the input is a textarea, set the height of the textarea to fit the content
    if (e.target.tagName === 'TEXTAREA') {
      e.target.style.height = 'auto';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }

    setTaskValues({ ...taskValues, [name]: value });
  };

  const handleStartEditMode = () => {
    if (editMode) return;

    setEditMode(true);
    // focus on input after 100ms to avoid double click on input
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  };

  const assignedTo =
    employees.find((emp) => emp.id === task.assignedTo?.id) || null;

  const onClickHandler = () => {
    if (subTask) {
      handleCheck(groupId, taskId, subTaskId, !checked);
    } else {
      setChecked(!checked);
      handleCheck(groupId, taskId, subTaskId, !checked);
    }
  };

  const handleUpdateTask = () => {
    updateTask(groupId, taskId, subTaskId, taskValues);

    // Update the task values to the new values
    setTaskValues({
      ...taskValues,
      title: taskValues.title,
      description: taskValues.description,
      dueDate: taskValues.dueDate,
      assignedTo: taskValues.assignedTo,
    });
    setEditMode(false);
  };

  const handleDeleteTask = () => {
    if (confirmDelete) {
      deleteTask(groupId, taskId, subTaskId);
    } else {
      setConfirmDelete(true);
    }
  };

  // Function to help reorder the subtasks after drag ends
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // Handle drag end
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedSubTasks = reorder(
      taskValues.subTasks,
      result.source.index,
      result.destination.index
    );

    setTaskValues({ ...taskValues, subTasks: reorderedSubTasks });

    updateTask(groupId, taskId, subTaskId, {
      ...task,
      subTasks: reorderedSubTasks,
    });
  };

  useEffect(() => {
    setTaskValues({
      id: task?.id || null,
      title: task?.title || '',
      description: task?.description || '',
      // Check if dueDate is a Firebase Timestamp before using toDate()
      dueDate:
        task?.dueDate && task.dueDate instanceof Timestamp
          ? task.dueDate.toDate()
          : '' || '',
      assignedTo: task?.assignedTo?.id || '',
      subTasks: task?.subTasks || [],
    });
  }, [task]);

  return (
    <div
      key={id}
      className={`checklist-item 
      ${insideModal ? 'checklist-item--inside-modal' : ''}
      ${subTask ? 'checklist-item--subtask' : ''}
      ${checked ? 'checklist-item--checked' : ''}`}
      {...dragHandle}
    >
      <div className="checklist-item__checkbox" onClick={onClickHandler}>
        {checked && <Icon name="check" />}
      </div>

      <div
        className="checklist-item__content"
        {...(!subTask &&
          !insideModal && { onClick: () => setIsModalOpen(true) })}
      >
        {(!checked && subTask) || (!checked && insideModal) ? (
          <>
            <div
              className={`checklist-item__content-wrapper ${
                editMode && 'checklist-item__content-wrapper--edit'
              }`}
              onClick={handleStartEditMode}
            >
              <textarea
                className={`checklist-item__content__title checklist-item__content__title--edit ${
                  editMode && 'checklist-item__content__title--edit--active'
                }`}
                ref={inputRef}
                type="text"
                name="title"
                placeholder="Titel..."
                value={taskValues.title || ''}
                onChange={handleInputChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleUpdateTask();
                  }
                }}
              />

              <span
                className={`checklist-item__content__title ${
                  editMode && 'checklist-item__content__title--hide'
                }`}
              >
                {task.title}
              </span>
              {editMode ? (
                <textarea
                  className={`checklist-item__content__description checklist-item__content__description--edit ${
                    editMode &&
                    'checklist-item__content__description--edit--active'
                  }`}
                  type="text"
                  name="description"
                  placeholder="Beskrivelse..."
                  value={taskValues.description || ''}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUpdateTask();
                    }
                  }}
                />
              ) : (
                task.description && (
                  <span className="checklist-item__content__description">
                    {task.description}
                  </span>
                )
              )}

              {!editMode ? (
                (taskValues.dueDate || assignedTo) && (
                  <div className="checklist-item__content__meta">
                    {!isTemplateMode && taskValues.dueDate && (
                      <div className="checklist-item__content__meta__due-date">
                        <span className="checklist-item__content__meta__due-date__label">
                          Dato
                        </span>{' '}
                        {new Date(taskValues.dueDate).toLocaleDateString(
                          'da-DK',
                          {
                            dateStyle: 'short',
                          }
                        )}
                      </div>
                    )}
                    {assignedTo && (
                      <div className="checklist-item__content__meta__assigned-to">
                        <span>Tildelt til</span>
                        <Avatar
                          key={assignedTo.id}
                          firstName={assignedTo.data.firstName}
                          lastName={assignedTo.data.lastName}
                          profilePhotoUrl={assignedTo.data.profilePhotoUrl}
                          size="small"
                        />
                        {assignedTo.data.firstName} {assignedTo.data.lastName}
                      </div>
                    )}
                  </div>
                )
              ) : (
                <div className="checklist-item__content__meta">
                  {!isTemplateMode && (
                    <div className="checklist-item__content__meta__due-date">
                      <span className="checklist-item__content__meta__due-date__label">
                        Dato
                      </span>
                      {` `}
                      <InputField
                        type="date"
                        name="dueDate"
                        inline
                        datePickerFixed={true}
                        value={taskValues.dueDate}
                        onChange={handleInputChange}
                        max="9999-12-31"
                      />
                    </div>
                  )}
                  <div className="checklist-item__content__meta__assigned-to">
                    <span>Tildelt til</span>
                    <select
                      name="assignedTo"
                      defaultValue={(assignedTo && assignedTo.id) || ''}
                      onChange={handleInputChange}
                    >
                      <option value="none">Vælg medarbejder</option>
                      {employees.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {employee.data.firstName} {employee.data.lastName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
            <div
              className={`checklist-item__content__edit ${
                editMode && 'checklist-item__content__edit--active'
              }`}
            >
              <div className="checklist-item__content__edit__delete__wrapper">
                <Button
                  buttonStyle="secondary"
                  className={`small checklist-item__content__edit__delete ${
                    confirmDelete &&
                    'checklist-item__content__edit__delete--confirm'
                  }`}
                  onClick={handleDeleteTask}
                >
                  {confirmDelete ? 'Bekræft sletning' : 'Slet'}
                </Button>
                {confirmDelete && (
                  <Button
                    buttonStyle="text-link"
                    className="small"
                    onClick={() => setConfirmDelete(false)}
                  >
                    Annuller
                  </Button>
                )}
              </div>
              <Button
                buttonStyle="text-link"
                className="small"
                onClick={() => setEditMode(false)}
              >
                Luk
              </Button>
              <Button className="small" onClick={handleUpdateTask}>
                Gem
              </Button>
            </div>
          </>
        ) : (
          <>
            <span className="checklist-item__content__title">{task.title}</span>
            {task.description && (
              <span className="checklist-item__content__description">
                {task.description}
              </span>
            )}
            {(taskValues.dueDate || assignedTo) && (
              <div className="checklist-item__content__meta">
                {!isTemplateMode && taskValues.dueDate && (
                  <div className="checklist-item__content__meta__due-date">
                    <span className="checklist-item__content__meta__due-date__label">
                      Dato
                    </span>{' '}
                    {new Date(taskValues.dueDate).toLocaleDateString('da-DK', {
                      dateStyle: 'short',
                    })}
                  </div>
                )}
                {assignedTo && (
                  <div className="checklist-item__content__meta__assigned-to">
                    <span>Tildelt til</span>
                    <Avatar
                      key={assignedTo.id}
                      firstName={assignedTo.data.firstName}
                      lastName={assignedTo.data.lastName}
                      profilePhotoUrl={assignedTo.data.profilePhotoUrl}
                      size="small"
                    />
                    {assignedTo.data.firstName} {assignedTo.data.lastName}
                  </div>
                )}
              </div>
            )}
          </>
        )}

        {task.checked && taskValues.subTasks.length === 0 ? (
          <></>
        ) : (
          insideModal && (
            <div className="checklist-item-subtasks">
              <div className="checklist-item-subtasks-header">
                <h5>Underopgaver</h5>
                {task?.subTasks?.length > 0 && (
                  <ProgressIndicator
                    current={subTasksCompleted.length}
                    total={task?.subTasks?.length}
                    completed={checked}
                    small
                  />
                )}
              </div>
              <div className="checklist-item-subtasks-list">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId={`subtasks-${task.id}`}
                    renderClone={(provided, snapshot, rubric) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{
                          userSelect: 'none',
                          backgroundColor: snapshot.isDragging
                            ? '#f5f7f3'
                            : '#fff',
                          ...provided.draggableProps.style,
                        }}
                      >
                        <ChecklistItemComponent
                          task={task.subTasks[rubric.source.index]}
                          subTask
                          checked={task.subTasks[rubric.source.index].checked}
                        />
                      </div>
                    )}
                  >
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {taskValues.subTasks.map((subTask, subTaskIndex) => (
                          <Draggable
                            key={subTask.id}
                            draggableId={subTask.id}
                            index={subTaskIndex}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ChecklistItemComponent
                                  id={subTask.id}
                                  key={subTask.id}
                                  task={subTask}
                                  handleCheck={handleCheck}
                                  groupId={groupId}
                                  taskId={taskId}
                                  subTaskId={subTask.id}
                                  updateTask={updateTask}
                                  deleteTask={deleteTask}
                                  subTask
                                  checked={subTask.checked}
                                  setChecked={(value) => {
                                    handleCheck(
                                      groupId,
                                      taskId,
                                      subTaskIndex,
                                      value
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              {!task.checked && !addSubTaskMode && (
                <div className="checklist-item-subtasks-add">
                  <Button
                    buttonStyle="text-link"
                    onClick={() => setAddSubTaskMode(true)}
                  >
                    <Icon name={'add'} /> Tilføj underopgave
                  </Button>
                </div>
              )}
              {!task.checked && addSubTaskMode && (
                <ChecklistAddItem
                  key={task.id}
                  addTask={addTask}
                  groupId={groupId}
                  taskId={taskId}
                  handleClose={() => setAddSubTaskMode(false)}
                />
              )}
            </div>
          )
        )}
      </div>

      {!insideModal && task.subTasks && task.subTasks.length > 0 && (
        <div
          className="checklist-item__progress"
          onClick={() => setIsModalOpen(true)}
        >
          <ProgressIndicator
            current={subTasksCompleted.length}
            total={task?.subTasks?.length}
            completed={checked}
          />
        </div>
      )}
    </div>
  );
};

export default function ChecklistItem({
  task,
  handleCheck,
  groupTitle,
  groupId,
  taskId,
  addTask,
  updateTask,
  deleteTask,
  dragHandle = null,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setChecked] = useState(task.checked);

  const subTasksCompleted =
    task?.subTasks?.filter((subtask) => subtask.checked) || [];

  return (
    <>
      <ChecklistItemComponent
        id={task.id}
        task={task}
        handleCheck={handleCheck}
        groupId={groupId}
        taskId={taskId}
        subTasksCompleted={subTasksCompleted}
        setIsModalOpen={setIsModalOpen}
        checked={checked}
        setChecked={setChecked}
        dragHandle={dragHandle}
      />
      <Modal
        key={task.id}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        header={groupTitle}
        customWidth={600}
        className="checklist-item-modal"
        content={
          <div className="checklist-item-modal-content">
            <ChecklistItemComponent
              id={task.id}
              task={task}
              handleCheck={handleCheck}
              addTask={addTask}
              updateTask={updateTask}
              deleteTask={deleteTask}
              groupId={groupId}
              taskId={taskId}
              subTasksCompleted={subTasksCompleted}
              checked={checked}
              setChecked={setChecked}
              insideModal
            />
          </div>
        }
      />
    </>
  );
}
