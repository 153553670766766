import PageTop from '../../components/page-top/page-top';
import { Panels, Panel } from '../../components/panel/panel';
import {
  usePrismicDocumentByUID,
  useAllPrismicDocumentsByType,
} from '@prismicio/react';
import Button from '../../components/button/button';

export default function ContractsView() {
  const [currentPage] = usePrismicDocumentByUID('page', 'kontrakter');
  const [documents] = useAllPrismicDocumentsByType('kontrakt');

  if (!currentPage) {
    return <div>Loading...</div>;
  }

  const pageTitle = currentPage.data.title || 'Default Title';
  const pageDescription = currentPage.data.description || 'Default Description';

  // Sort by number using the sort_order field in the document
  const sortedDocuments = documents?.sort((a, b) => {
    // If sort_order is not defined, set it to last in the list, so it will be sorted last in the list
    const aSortOrder = a.data.sort_order || 9999;
    const bSortOrder = b.data.sort_order || 9999;
    return aSortOrder - bSortOrder;
  });

  return (
    <div className="main-layout">
      <PageTop title={pageTitle} description={pageDescription} />
      <Panels>
        {sortedDocuments &&
          sortedDocuments.map((document) => (
            <Panel
              key={document.id}
              columns="50"
              header={<h4>{document?.data?.title}</h4>}
              inlineHeader
              bottom={
                <Button
                  buttonStyle="text-link"
                  href={document?.data?.kontrakt_file?.url}
                >
                  Download
                </Button>
              }
            >
              <p>{document?.data?.description}</p>
            </Panel>
          ))}
      </Panels>
    </div>
  );
}
