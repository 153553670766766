import { Panel } from '../../components/panel/panel';
import InputField from '../../components/input-field/input-field';
import TimeRegistrationTop from '../../components/timeregistration/top/timeregistration-top';
import TimeRegistrationUserExceedings from '../../components/timeregistration/user-exceedings/timeregistration-user-exceedings';
import { useState } from 'react';

export default function TimeRegistrationExceedings({ employees }) {
  const [seenFilter, setSeenFilter] = useState(false);

  const handleSeenFilterChange = (e) => {
    setSeenFilter(e.target.checked);
  };

  const filteredEmployees = employees.filter((employee) => {
    return employee?.data?.timeLogWarnings?.some(
      (warning) => warning.read === seenFilter
    );
  });

  return (
    <Panel
      noPadding
      inlineHeader
      header={
        <TimeRegistrationTop
          title="Overskridelser"
          filters={
            <>
              <InputField
                label="Vis sete"
                type="checkbox"
                name="seen"
                onChange={handleSeenFilterChange}
              />
            </>
          }
        />
      }
    >
      <TimeRegistrationUserExceedings employees={filteredEmployees} />
    </Panel>
  );
}
