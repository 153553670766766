import { useContext, useRef } from 'react';
import { EmployeesContext } from '../../context';
import Icon from '../../components/icon/icon';
import Button from '../../components/button/button';
import TeaserImage from './dashboard-timeregistration-teaser.webp';
import { Panel } from '../../components/panel/panel';
import { Link } from 'react-router-dom';

export default function DashboardTimeRegistrationTeaser() {
  const teaserRef = useRef(null);
  const employees = useContext(EmployeesContext);
  const cookieTeaserClosed = document.cookie.includes(
    'timeregistration_teaser_closed=true'
  );

  const employeesWithMobileAccess = employees.filter(
    (employee) => employee?.data?.settings?.mobileApp?.access
  );

  const handleClose = () => {
    // Close the teaser and set a cookie to remember the user's choice for 30 days
    document.cookie =
      'timeregistration_teaser_closed=true; max-age=2592000; path=/';

    // Remove the teaser from the DOM
    teaserRef.current.remove();
  };

  if (employeesWithMobileAccess.length !== 0) return null;

  // Check if the user has already closed the teaser via a cookie
  if (cookieTeaserClosed) return null;

  return (
    <div ref={teaserRef}>
      <Panel noPadding>
        <div className="dashboard-timeregistration-teaser">
          <div className="dashboard-timeregistration-teaser-image">
            <Button
              buttonStyle="square"
              className="dashboard-timeregistration-teaser__close"
              onClick={handleClose}
            >
              <Icon name="close" light />
            </Button>
            <img src={TeaserImage} alt="Prøv tidsregistrering" />
          </div>
          <div className="dashboard-timeregistration-teaser-content">
            <h3>Prøv tidsregistrering</h3>
            <p>
              Aktivér din første medarbejder. Klik på{' '}
              <Link to="/tidsregistrering/indstillinger">
                Gå til indstillinger
              </Link>{' '}
              og klik herefter på &quot;Aktivér tidsregistrering&quot;.
            </p>
            <Button href="/tidsregistrering/indstillinger" className="small">
              Gå til indstillinger
            </Button>
          </div>
        </div>
      </Panel>
    </div>
  );
}
