import { useState, useCallback, useContext } from 'react';
import { Panels, Panel } from '../../components/panel/panel';
import Button from '../../components/button/button';
import TimeLogSettings from '../../components/timeregistration/settings/timeregistration-settings';
import TimeRegistrationAccess from '../../components/timeregistration/settings/timeregistration-access';
import UsersTimeLogSettings from '../../components/timeregistration/settings/timeregistration-user-settings';
import { db } from '../../firebase-config';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { getDefaultWeeklySchedule } from './timeregistration-utils';
import { convertToUTC } from '../../utils/formatDates';
import { Link } from 'react-router-dom';
import { EmployeesContext } from '../../context';
import TimeRegistrationIntroductionModal from '../../components/timeregistration/introduction-modal/timeregistration-introduction-modal';

export default function TimeRegistrationSettings({
  userInfo,
  initialValues,
  setInitialValues,
}) {
  const employees = useContext(EmployeesContext);
  const [submitting, setSubmitting] = useState(false);
  const [formFeedback, setFormFeedback] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [companyTimeLogSettings, setCompanyTimeLogSettings] = useState(
    // Last index of the array is the latest timeLogSettings
    (initialValues?.timeLogSettings?.length > 0 &&
      initialValues?.timeLogSettings[
        initialValues?.timeLogSettings.length - 1
      ]) || {
      // If there is no timeLogSettings, set default values
      breakDuration: 30,
      createdDate: Timestamp.fromDate(convertToUTC(new Date())),
      paidBreak: false,
      isPaidHourly: false,
      weeklySchedule: getDefaultWeeklySchedule(),
    }
  );

  const employeesWithMobileAccess = employees.filter(
    (employee) => employee?.data?.settings?.mobileApp?.access
  );

  const handleSubmit = useCallback(async () => {
    // Prevent multiple submissions
    if (submitting) return;

    // Set submitting to true
    setSubmitting(true);

    // Set form feedback to submitting
    setFormFeedback('Gemmer...');

    // Save createdDate at midnight UTC
    const createdDateAtMidnight = new Date();
    createdDateAtMidnight.setUTCHours(0, 0, 0, 0);
    const createdDate = Timestamp.fromDate(createdDateAtMidnight);

    const valuesToSave = {
      timeLogSettings: [
        ...(initialValues?.timeLogSettings || []),
        // Always update createdDate to the current time
        {
          ...companyTimeLogSettings,
          createdDate,
        },
      ],
    };

    // Check if we have company data and a document ID
    const companyDocRef = doc(db, 'companies', userInfo.companyData.id);

    try {
      // Update the document in Firestore
      await setDoc(companyDocRef, valuesToSave, { merge: true });

      // Update the initial values
      setInitialValues((prev) => ({
        ...prev,
        timeLogSettings: valuesToSave?.timeLogSettings,
      }));

      // Handle successful update
      setFormFeedback('Indstillingerne er gemt.');

      // Set submitting to false
      setSubmitting(false);
    } catch (error) {
      // Handle any errors
      setFormFeedback('Der skete en fejl. Prøv igen senere.');
      console.error('Error updating profile:', error);

      // Set submitting to false
    }
  }, [
    userInfo,
    companyTimeLogSettings,
    initialValues,
    setInitialValues,
    submitting,
  ]);

  return (
    <Panels>
      <Panel
        header={
          <>
            <h4>Aktiver tidsregistrering</h4>
            <p>
              Aktiver tidsregistrering for enkelte medarbejder. Dette kan også
              gøres under indstillinger på den enkelte medarbejder i{' '}
              <Link to={`/medarbejdere`}>medarbejderoversigten</Link>.
            </p>
            <Button
              buttonStyle="text-link"
              onClick={() => setIsModalOpen(true)}
              style={{ marginTop: '2rem' }}
              className="small"
            >
              Sådan bliver medarbejdere introduceret
            </Button>
          </>
        }
      >
        <TimeRegistrationAccess />
        <TimeRegistrationIntroductionModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </Panel>
      {employeesWithMobileAccess.length > 0 && (
        <>
          <Panel
            header={
              <>
                <h4>Standard normtid og pause</h4>
                <p>
                  Medarbejdere har denne normtid som standard. Du kan overskrive
                  den, ved at tilpasse for den enkelte medarbejder.
                </p>
              </>
            }
          >
            <TimeLogSettings
              settings={companyTimeLogSettings}
              setSettings={setCompanyTimeLogSettings}
            />
          </Panel>
          <Panel header={<></>} noBox>
            <div className="employee-settings-actions">
              <Button disabled={submitting} onClick={handleSubmit}>
                Gem indstillinger
              </Button>
              {formFeedback && (
                <span className="form-actions__feedback">{formFeedback}</span>
              )}
            </div>
          </Panel>
          <UsersTimeLogSettings />
        </>
      )}
    </Panels>
  );
}
