import { useState, useCallback, useEffect } from 'react';
import ProfilePhotoUpload from '../../components/profile-photo-upload/profile-photo-upload';
import { Panels, Panel } from '../../components/panel/panel';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase-config';
import Button from '../../components/button/button';
import InputField from '../../components/input-field/input-field';
import Modal from '../../components/modal/modal';
import Icon from '../../components/icon/icon';
import EMPLOYMENT_TYPE from '../../constants/fields/employmentType';
import EMPLOYMENT_STATUS from '../../constants/fields/employmentStatus';
import { useForm } from '../../hooks/useForm';
import './employee-fields.css';

const convertTimestampsToDateStrings = (values) => {
  const newValues = { ...values };
  Object.keys(newValues).forEach((key) => {
    if (newValues[key] instanceof Timestamp) {
      newValues[key] = newValues[key].toDate();
    }
  });

  return newValues;
};

export default function EmployeeFields({
  user,
  userInfo,
  employeeId,
  navigate,
  initialValues,
}) {
  const [formFeedback, setFormFeedback] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletedButtonDisabled, setDeleteButtonDisabled] = useState(true);

  // Handle opening and closing the delete modal
  function toggleModal() {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }

  // Form validation
  const validateForm = (values) => {
    let errors = {};

    if (!values.firstName.trim()) errors.firstName = 'Fornavn mangler';
    if (!values.lastName.trim()) errors.lastName = 'Efternavn mangler';
    if (!values.jobPosition.trim()) errors.jobPosition = 'Arbejdstitel mangler';

    // if the employmentStatus is 'inactive' the end date is required and should not be empty
    if (
      values.employmentStatus === 'inactive' &&
      (!values.endDate || values.endDate === '')
    )
      errors.endDate = 'Slutdato mangler';

    return errors;
  };

  // Handle form submission
  const onSubmit = useCallback(
    async (values) => {
      const valuesToSave = {
        ...values,
        // Clean up the values
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        // Convert dates to Firestore Timestamps before saving
        dateOfBirth: values.dateOfBirth
          ? Timestamp.fromDate(values.dateOfBirth)
          : null,
        startDate: values.startDate
          ? Timestamp.fromDate(values.startDate)
          : null,
        endDate: values.endDate ? Timestamp.fromDate(values.endDate) : null,
      };

      // Remove empty values from valuesToSave
      Object.keys(valuesToSave).forEach(
        (key) => valuesToSave[key] === '' && delete valuesToSave[key]
      );

      // Make sure not to include the profile photo URL in the data to save because it's handled separately
      delete valuesToSave.profilePhotoUrl;

      // Check if we have employee data and a document ID
      const employeeDocRef = doc(db, 'users', employeeId);

      try {
        // Update the document in Firestore
        await setDoc(employeeDocRef, valuesToSave, { merge: true });

        // Handle successful update
        setFormFeedback('Ændringerne er gemt.');
      } catch (error) {
        // Handle any errors
        setFormFeedback('Der skete en fejl. Prøv igen senere.');
        console.error('Error updating profile:', error);
      }
    },
    [employeeId]
  );

  // Custom handleChange for CPR input to insert hyphen after 6 digits for better readability
  const cprHandleChange = (event) => {
    const { name, value } = event.target;
    // Do not allow more than 10 digits
    if (value.length > 11) return;

    const newValue = value.replace(/[^0-9]/g, ''); // Remove any non-numeric characters

    // Insert hyphen after 6 digits
    const formattedValue = newValue.replace(/(\d{6})(\d{0,4})/, '$1-$2');
    setValues({ ...values, [name]: formattedValue });
  };

  // Custom hook for handling form state
  const { values, handleChange, handleSubmit, errors, setValues } = useForm(
    initialValues,
    validateForm,
    onSubmit
  );

  useEffect(() => {
    if (initialValues) {
      setValues(convertTimestampsToDateStrings(initialValues));
    }
  }, [initialValues]);

  return (
    <Panels>
      <Panel header={<h4>Personfelter</h4>}>
        <ProfilePhotoUpload
          firstName={values.firstName}
          lastName={values.lastName}
          userId={employeeId}
          profilePhotoUrl={values.profilePhotoUrl}
        />
        <InputField
          required
          label="Fornavn"
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          placeholder="Fornavn"
          error={errors.firstName}
        />
        <InputField
          required
          label="Efternavn"
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          placeholder="Efternavn"
          error={errors.lastName}
        />
        <InputField
          label="CPR-nummer"
          name="cprNumber"
          value={values?.cprNumber || ''}
          onChange={cprHandleChange}
          placeholder="DDMMYY-XXXX"
          pattern="[0-9]{6}-[0-9]{4}"
          help="CPR-nummeret skal være i formatet DDMMYY-XXXX"
        />
        <InputField
          label="Fødselsdato"
          name="dateOfBirth"
          type="date"
          value={values.dateOfBirth}
          onChange={handleChange}
          placeholder="DDMMÅÅ"
          error={errors.dateOfBirth}
          max="2999-12-31"
        />
        <InputField
          label="Note"
          name="note"
          type="textarea"
          value={values.note}
          onChange={handleChange}
          placeholder="Note"
          help="Her kan du skrive en note om medarbejderen, f.eks. allergier eller andre vigtige oplysninger."
        />
        <div className="form-actions">
          <Button onClick={handleSubmit} type="submit">
            Gem ændringer
          </Button>
          {formFeedback && (
            <span className="form-actions__feedback">{formFeedback}</span>
          )}
        </div>
      </Panel>
      <Panel header={<h4>Kontaktoplysninger</h4>}>
        <h5>Arbejde</h5>
        <InputField
          label="Arbejdsemail"
          name="email"
          value={values.email}
          disabled={true}
          readOnly={true}
          help="Arbejdsemail kan ikke ændres af sikkerhedsmæssige årsager. Kontakt HRfamly hvis du har behov for at ændre den."
          placeholder="Arbejdsemail"
        />
        <InputField
          label="Arbejdstelefonnummer"
          name="phone"
          type="tel"
          placeholder="Arbejdstelefonnummer"
          pattern="[0-9]{8}"
          value={values.phone}
          onChange={handleChange}
        />
        <h5>Privat</h5>
        <InputField
          label="Privatemail"
          name="privateEmail"
          value={values.privateEmail}
          onChange={handleChange}
          placeholder="Privatemail"
        />
        <InputField
          label="Privattelefonnummer"
          name="privatePhone"
          type="tel"
          placeholder="Privattelefonnummer"
          pattern="[0-9]{8}"
          value={values.privatePhone}
          onChange={handleChange}
        />
        <InputField
          label="Adresse"
          name="address"
          placeholder="Adresse"
          value={values.address}
          onChange={handleChange}
        />
        <div className="employee-address-fields">
          <InputField
            className="zipcode"
            label="Postnr."
            name="zipcode"
            placeholder="Postnr."
            value={values.zipcode}
            onChange={handleChange}
          />

          <InputField
            className="city"
            label="By"
            name="city"
            placeholder="By"
            value={values.city}
            onChange={handleChange}
          />
        </div>
        <h5>Nødkontakt</h5>
        <InputField
          label="Navn"
          name="emergencyContactName"
          value={values.emergencyContactName}
          placeholder="Navn"
          onChange={handleChange}
        />
        <InputField
          label="Telefonnummer"
          name="emergencyContactPhone"
          type="tel"
          value={values.emergencyContactPhone}
          placeholder="Telefonnummer"
          onChange={handleChange}
        />
        <div className="form-actions">
          <Button onClick={handleSubmit} type="submit">
            Gem ændringer
          </Button>
          {formFeedback && (
            <span className="form-actions__feedback">{formFeedback}</span>
          )}
        </div>
      </Panel>
      <Panel header={<h4>Ansættelsesforhold</h4>}>
        <InputField
          required
          label="Arbejdstitel"
          name="jobPosition"
          value={values.jobPosition}
          placeholder="Arbejdstitel"
          onChange={handleChange}
          error={errors.jobPosition}
        />
        <InputField
          label="Afdeling"
          name="department"
          value={values.department}
          placeholder="Afdeling"
          onChange={handleChange}
        />
        <InputField
          label="Ansættelsestype"
          type="select"
          name="employmentType"
          options={EMPLOYMENT_TYPE}
          value={values.employmentType}
          placeholder="- Vælg -"
          onChange={handleChange}
        />
        <InputField
          label="Status"
          type="select"
          name="employmentStatus"
          options={EMPLOYMENT_STATUS}
          value={values.employmentStatus}
          placeholder="- Vælg -"
          onChange={handleChange}
        />
        <div className="employee-dates-fields">
          <InputField
            className="startDate"
            label="Ansættelsesdato"
            name="startDate"
            type="date"
            value={values.startDate}
            onChange={handleChange}
            max="2999-12-31"
          />
          {values.employmentStatus === 'inactive' && (
            <InputField
              className="endDate"
              label="Slutdato"
              name="endDate"
              type="date"
              value={values.endDate}
              onChange={handleChange}
              error={errors.endDate}
              max="2999-12-31"
            />
          )}
        </div>
        <div className="employee-salary-fields">
          <InputField
            label="Løn"
            name="salary"
            type="number"
            className="salary"
            value={values.salary}
            placeholder="Løn"
            onChange={handleChange}
            min="0"
            max="1000000"
          />
          <InputField
            label="Reg-nr. og kontonr."
            name="bankAccount"
            className="bankAccount"
            value={values.bankAccount}
            placeholder="Reg-nr. og kontonr."
            onChange={handleChange}
          />
        </div>
        <InputField
          label="Lønrelateret note"
          name="salaryNote"
          type="textarea"
          value={values.salaryNote}
          onChange={handleChange}
          placeholder="Note"
          help="Her kan du skrive en note om medarbejderens aflønning, angivelse af pension, bonus, arbejdstid m.v."
        />
        <div className="form-actions">
          <Button onClick={handleSubmit} type="submit">
            Gem ændringer
          </Button>
          {formFeedback && (
            <span className="form-actions__feedback">{formFeedback}</span>
          )}
        </div>
      </Panel>

      {employeeId !== user.uid && (
        <Panel noPadding header={<h4>Administration</h4>}>
          <div className="action-list">
            <div className="action-list-item">
              <div className="action-list-item__description">
                <h5>Slet denne medarbejder</h5>
                <p>
                  Når medarbejderen er slettet, kan det ikke laves om. Vær
                  sikker i din beslutning.
                </p>
              </div>
              <div className="action-list-item__action">
                <Button
                  type="button"
                  buttonStyle="danger"
                  onClick={toggleModal}
                >
                  Slet medarbejder
                </Button>
                <Modal
                  isModalOpen={isDeleteModalOpen}
                  setIsModalOpen={setIsDeleteModalOpen}
                  header={`Slet medarbejder: ${values.firstName} ${values.lastName}`}
                  bottom={
                    <>
                      <InputField
                        label="Bekræft ved at skrive navnet i feltet"
                        placeholder="Bekræft ved at skrive navnet i feltet"
                        name="confirmName"
                        onChange={(e) => {
                          if (
                            e.target.value.toLowerCase() ===
                            `${values.firstName} ${values.lastName}`.toLowerCase()
                          ) {
                            setDeleteButtonDisabled(false);
                          }

                          if (
                            e.target.value.toLowerCase() !==
                            `${values.firstName} ${values.lastName}`.toLowerCase()
                          ) {
                            setDeleteButtonDisabled(true);
                          }
                        }}
                      />
                      <Button
                        buttonStyle="danger"
                        disabled={deletedButtonDisabled}
                        onClick={() => {
                          // Call serverless function to delete the employee
                          fetch('/api/user/delete', {
                            method: 'DELETE',
                            headers: {
                              'Content-Type': 'application/json',
                              'x-user-uuid': user.uid,
                            },
                            body: JSON.stringify({
                              employeeId,
                              companyId: userInfo.companyId,
                            }),
                          })
                            .then((response) => {
                              if (response.ok) {
                                // Redirect to the employees list
                                navigate('/medarbejdere');
                              } else {
                                throw new Error('Error deleting employee');
                              }
                            })
                            .catch((error) => {
                              console.error('Error deleting employee:', error);
                            });
                        }}
                      >
                        Slet medarbejder
                      </Button>
                    </>
                  }
                  content={
                    <div className="delete-modal-content">
                      <Icon name="warning" />
                      <p>
                        Er du sikker på at du vil slette medarbejderen{' '}
                        <strong>
                          {values.firstName} {values.lastName}?
                        </strong>
                      </p>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </Panel>
      )}
    </Panels>
  );
}
