import { useEffect, useContext, useState, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Panel } from '../../components/panel/panel';
import {
  doc,
  getDoc,
  updateDoc,
  Timestamp,
  deleteDoc,
} from 'firebase/firestore';
import { db } from '../../firebase-config';
import { EmployeesContext } from '../../context';
import PageTop from '../../components/page-top/page-top';
import Button from '../../components/button/button';
import Icon from '../../components/icon/icon';
import Checklist from '../../components/checklist/checklist';
import Modal from '../../components/modal/modal';
import InputField from '../../components/input-field/input-field';
import Skeleton from 'react-loading-skeleton';
import './onboarding-offboarding-detail-view.css';
import 'react-loading-skeleton/dist/skeleton.css';

export default function OnboardingOffboardingDetailView() {
  const isInitialLoad = useRef(true);
  const { pageId, isTemplate } = useParams();
  const employees = useContext(EmployeesContext);
  const [data, setData] = useState({});
  const [regarding, setRegarding] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const navigate = useNavigate();

  const collectionName =
    isTemplate === 'template'
      ? 'onboardingOffboardingTemplates'
      : 'onboardingOffboarding';

  // Handle change
  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle edit
  const handleEdit = async () => {
    const newData = { ...data };

    updateChecklist(newData);
    setIsEditModalOpen(false);
  };

  // Handle delete
  const handleDelete = async () => {
    deleteChecklist();
    setIsDeleteModalOpen(false);

    // Redirect to the onboarding/offboarding list
    navigate('/onboarding-offboarding');
  };

  // Update checklist
  const updateChecklist = async (newData) => {
    const docRef = doc(db, collectionName, pageId);

    // Convert regarding to Firestore reference
    if (newData.regarding) {
      // Hack to handle the case where the regarding is a string not a document reference
      if (typeof newData.regarding === 'string') {
        newData.regarding = doc(db, 'users', newData.regarding);
      } else {
        newData.regarding = doc(db, 'users', newData.regarding.id);
      }
    }

    try {
      await updateDoc(docRef, {
        ...newData,
        updatedAt: Timestamp.fromDate(new Date()),
      });
      setData(newData);

      // Update the regarding state
      if (newData?.regarding?.id) {
        setRegarding({
          id: newData.regarding.id,
          data: employees.find((emp) => emp.id === newData.regarding.id).data,
        });
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  // Delete checklist
  const deleteChecklist = async () => {
    const docRef = doc(db, collectionName, pageId);

    try {
      await deleteDoc(docRef);
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const updateData = useCallback(
    async (newData) => {
      const docRef = doc(db, collectionName, pageId);

      // Check if all tasks are completed, if so, set completed to true
      const totalTasksCount = newData.groups.reduce(
        (acc, group) => acc + group.tasks.length,
        0
      );
      const currentCompletedTasksCount = newData.groups.reduce(
        (acc, group) => acc + group.tasks.filter((task) => task.checked).length,
        0
      );

      newData.completed = totalTasksCount === currentCompletedTasksCount;

      try {
        await updateDoc(docRef, {
          groups: newData.groups,
          updatedAt: Timestamp.fromDate(new Date()),
          completed: newData.completed,
        });

        // Update the state
        setData(newData);
      } catch (error) {
        console.error('Error updating document:', error);
      }
    },
    [pageId, collectionName]
  );

  // Add new group
  const addGroup = async (group) => {
    const newData = { ...data };
    newData.groups.push(group);

    updateData(newData);
  };

  // Update group
  const updateGroup = async (groupId, group) => {
    const newData = { ...data };

    // Find the index of the group to update based on the id
    const groupIndex = newData.groups.findIndex(
      (group) => group.id === groupId
    );
    newData.groups[groupIndex] = group;

    updateData(newData);
  };

  // Delete group
  const deleteGroup = async (groupId) => {
    const newData = { ...data };
    // Find the index of the group to delete based on the id
    const groupIndex = newData.groups.findIndex(
      (group) => group.id === groupId
    );
    newData.groups.splice(groupIndex, 1);

    updateData(newData);
  };

  // Add task or subtask based on the index
  const addTask = async (groupId, taskId, task) => {
    const newData = { ...data };
    const taskDataToSave = { ...task };

    // Find the index of the group to add the task to based on the id
    const groupIndex = newData.groups.findIndex(
      (group) => group.id === groupId
    );

    // Find the index of the task to add the subtask to based on the id
    const itemIndex = newData.groups[groupIndex].tasks.findIndex(
      (task) => task.id === taskId
    );

    // Convert dueDate to Firestore Timestamp
    if (task.dueDate) {
      taskDataToSave.dueDate = Timestamp.fromDate(new Date(task.dueDate));
    }

    // Convert assignedto to Firestore reference
    if (taskDataToSave.assignedTo) {
      if (taskDataToSave.assignedTo === 'none') {
        taskDataToSave.assignedTo = null;
      } else {
        taskDataToSave.assignedTo = doc(db, 'users', taskDataToSave.assignedTo);
      }
    }

    // Determine if it's a subtask or not
    if (itemIndex !== -1) {
      newData.groups[groupIndex].tasks[itemIndex].subTasks.push(taskDataToSave);
    } else {
      // If it's a new task, add it to the tasks array
      newData.groups[groupIndex].tasks.push(taskDataToSave);
    }

    updateData(newData);
  };

  // Check/Uncheck task
  const handleCheck = useCallback(
    async (groupId, taskId, subTaskId, checked) => {
      const newData = { ...data };

      // Find the index of the group to update based on the id
      const groupIndex = newData.groups.findIndex(
        (group) => group.id === groupId
      );

      // Find the index of the task to update based on the id
      const itemIndex = newData.groups[groupIndex].tasks.findIndex(
        (task) => task.id === taskId
      );

      // Find the index of the subtask to update based on the id
      const subTaskIndex = subTaskId
        ? newData.groups[groupIndex].tasks[itemIndex].subTasks.findIndex(
            (subTask) => subTask.id === subTaskId
          )
        : undefined;

      if (subTaskIndex !== undefined) {
        newData.groups[groupIndex].tasks[itemIndex].subTasks[
          subTaskIndex
        ].checked = checked;
        newData.groups[groupIndex].tasks[itemIndex].subTasks[
          subTaskIndex
        ].completedAt = checked ? Timestamp.now() : null;
      } else {
        newData.groups[groupIndex].tasks[itemIndex].checked = checked;
        newData.groups[groupIndex].tasks[itemIndex].completedAt = checked
          ? Timestamp.now()
          : null;
      }

      updateData(newData);
    },
    [data, updateData]
  );

  // Update task
  const updateTask = async (groupid, taskId, subTaskid, task) => {
    const newData = { ...data };
    const taskDataToSave = { ...task };

    // Find the index of the group to update based on the id
    const groupIndex = newData.groups.findIndex(
      (group) => group.id === groupid
    );

    // Find the index of the task to update based on the id
    const itemIndex = newData.groups[groupIndex].tasks.findIndex(
      (task) => task.id === taskId
    );

    // Find the index of the subtask to update based on the id
    const subTaskIndex = subTaskid
      ? newData.groups[groupIndex].tasks[itemIndex].subTasks.findIndex(
          (subTask) => subTask.id === subTaskid
        )
      : undefined;

    // Convert assignedto to Firestore reference
    if (taskDataToSave.assignedTo) {
      if (taskDataToSave.assignedTo === 'none') {
        taskDataToSave.assignedTo = null;
      } else {
        taskDataToSave.assignedTo = doc(db, 'users', taskDataToSave.assignedTo);
      }
    }

    // Convert dueDate to Firestore Timestamp
    if (taskDataToSave.dueDate) {
      taskDataToSave.dueDate = Timestamp.fromDate(
        new Date(taskDataToSave.dueDate)
      );
    }

    if (subTaskIndex !== undefined) {
      newData.groups[groupIndex].tasks[itemIndex].subTasks[subTaskIndex] =
        taskDataToSave;
    } else {
      newData.groups[groupIndex].tasks[itemIndex] = taskDataToSave;
    }

    updateData(newData);
  };

  // Delete task
  const deleteTask = async (groupId, taskId, subTaskId) => {
    const newData = { ...data };

    // Find the index of the group to update based on the id
    const groupIndex = newData.groups.findIndex(
      (group) => group.id === groupId
    );

    // Find the index of the task to update based on the id
    const itemIndex = newData.groups[groupIndex].tasks.findIndex(
      (task) => task.id === taskId
    );

    // Find the index of the subtask to update based on the id
    const subTaskIndex = subTaskId
      ? newData.groups[groupIndex].tasks[itemIndex].subTasks.findIndex(
          (subTask) => subTask.id === subTaskId
        )
      : undefined;

    if (subTaskIndex !== undefined) {
      newData.groups[groupIndex].tasks[itemIndex].subTasks.splice(
        subTaskIndex,
        1
      );
    } else {
      newData.groups[groupIndex].tasks.splice(itemIndex, 1);
    }

    updateData(newData);
  };

  // Fetch onboarding/offboarding data
  useEffect(() => {
    const fetchData = async () => {
      if (isInitialLoad.current) {
        const docRef = doc(db, collectionName, pageId);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            let data = docSnap.data();

            // Find the details of the person this is regarding from the employees context
            const regarding =
              employees.find((emp) => emp.id === data?.regarding?.id) || {};

            if (regarding) {
              setRegarding({
                id: regarding.id,
                data: regarding.data,
              });
            }

            setData(data);

            isInitialLoad.current = false; // Update the ref after setting values
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching company data:', error);
        } finally {
          // Set loading to false
          isInitialLoad.current = false;
        }
        isInitialLoad.current = false;
      }
    };

    fetchData();
  }, [pageId, employees, collectionName]);

  return (
    <div className="main-layout">
      <PageTop
        columns={80}
        title={
          isInitialLoad.current ? (
            <Skeleton width={700} />
          ) : (
            <div className="onboarding-offboarding-details-title">
              <span className="onboarding-offboarding-details-title__headline">
                {data.title}
                {isTemplate === 'template' ? (
                  <span className="onboarding-offboarding-details-title__headline-byline">
                    (Skabelon)
                  </span>
                ) : (
                  ''
                )}
              </span>
              {regarding && regarding.data && (
                <h4>
                  <span className="onboarding-offboarding-details-title__regarding">
                    Vedrørende:
                  </span>
                  {regarding.data.firstName} {regarding.data.lastName}
                </h4>
              )}
            </div>
          )
        }
        actions={
          <div className="onboarding-offboarding-details-actions">
            <Button
              buttonStyle="text-link"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              Slet
            </Button>
            <Button
              buttonStyle="text-link"
              onClick={() => setIsEditModalOpen(true)}
            >
              Ret detaljer
            </Button>
          </div>
        }
      />
      {isInitialLoad.current ? (
        <>
          <Skeleton
            width={400}
            style={{
              marginBottom: '20px',
            }}
          />
          <Panel>
            <div className="onboarding-offboarding-details">
              <Skeleton />
            </div>
          </Panel>
        </>
      ) : (
        <>
          <Panel noPadding columns={80}>
            <Checklist
              data={data}
              handleCheck={handleCheck}
              updateChecklist={updateChecklist}
              addGroup={addGroup}
              updateGroup={updateGroup}
              deleteGroup={deleteGroup}
              addTask={addTask}
              updateTask={updateTask}
              deleteTask={deleteTask}
            />
          </Panel>
          <Modal
            isModalOpen={isDeleteModalOpen}
            setIsModalOpen={setIsDeleteModalOpen}
            header="Slet liste"
            content={
              <div className="delete-modal-content">
                <Icon name="warning" />
                <p>Er du sikker på at du vil slette listen?</p>
              </div>
            }
            bottom={
              <Button buttonStyle="danger" onClick={handleDelete}>
                Slet
              </Button>
            }
          />
          <Modal
            isModalOpen={isEditModalOpen}
            setIsModalOpen={setIsEditModalOpen}
            header="Ret liste"
            className={`${
              isTemplate !== 'template'
                ? 'onboarding-offboarding-details-edit-modal'
                : ''
            }`}
            content={
              <>
                <InputField
                  label="Titel"
                  type="text"
                  name="title"
                  value={data.title}
                  required
                  onChange={handleChange}
                />
                {isTemplate !== 'template' && (
                  <InputField
                    label="Vedrørende"
                    name="regarding"
                    type="select2"
                    defaultValue={
                      regarding.id
                        ? {
                            value: regarding.id,
                            label: `${regarding.data.firstName} ${regarding.data.lastName}`,
                          }
                        : null
                    }
                    onChange={handleChange}
                    options={
                      employees.map((employee) => ({
                        value: employee.id,
                        label: `${employee.data.firstName} ${employee.data.lastName}`,
                        target: {
                          value: employee.id,
                          name: 'regarding',
                        },
                      })) || []
                    }
                  />
                )}
              </>
            }
            bottom={
              <Button
                buttonStyle="primary"
                disabled={data.title.trim() === ''}
                onClick={handleEdit}
              >
                Gem
              </Button>
            }
          />
        </>
      )}
    </div>
  );
}
