import { useContext, useState } from 'react';
import './timeregistration-access.css';
import Icon from '../../icon/icon';
import Button from '../../button/button';
import { EmployeesContext } from '../../../context';
import TimeRegistrationAccessModal from './timeregistration-access-modal';

export default function TimeRegistrationAccess() {
  const employees = useContext(EmployeesContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const employeesWithMobileAccess = employees.filter(
    (employee) => employee?.data?.settings?.mobileApp?.access
  );

  return (
    <>
      <div className="time-registration-access-top">
        <div className="time-registration-access-top-icon">
          <Icon name="pending_actions" />
        </div>
        <div className="time-registration-access-top-content">
          <h5>Aktivér tidsregistrering</h5>
          <p>
            {employeesWithMobileAccess.length} medarbejdere har
            tidsregistrering.
          </p>
        </div>
        <Button buttonStyle="secondary" onClick={() => setIsModalOpen(true)}>
          {employeesWithMobileAccess.length === 0
            ? 'Aktivér tidsregistrering'
            : 'Rediger'}
        </Button>
      </div>
      {employeesWithMobileAccess.length > 0 && (
        <div className="time-registration-access-list">
          {employeesWithMobileAccess.map((employee) => (
            <div
              key={employee.id}
              className="time-registration-access-list-item"
            >
              <span>
                {employee.data.firstName} {employee.data.lastName}
              </span>
              <span>Aktiveret</span>
            </div>
          ))}
        </div>
      )}
      <TimeRegistrationAccessModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
}
